import React, { useMemo, useCallback, useEffect } from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'
import { awsAmisSelector } from '../capi/selectors'
import { useSelector } from 'react-redux'
import { propOr, concat, unnest } from 'ramda'
import { capitalizeString } from 'utils/misc'
import useListAction from 'core/hooks/useListAction'
import { listAwsAmis } from '../capi/actions'
import { Ami } from '../capi/model'
import Text from 'core/elements/Text'

export const cloudInit = {
  insecureSkipSecretsManager: true,
}

const renderLabel = (os: string, ami: Ami) => (
  <>
    <Text variant="body1">{capitalizeString(`${os} (${ami?.id})`)}</Text>
    <Text variant="caption2">
      {ami?.secretsManager ? 'Use AWS Secrets Manager: True' : 'Use AWS Secrets Manager : False'}
    </Text>
  </>
)

const custom = [{ label: 'Custom', value: 'custom' }]
interface Props {
  region: string
  namespace: string
}
// If the value passed to this component is not a valid option,
// set the value to 'custom'. This is how we know if the
// ami id passed to this component is a custom one or not
export default function AwsAmiPicklist({
  region,
  value,
  onChange,
  namespace,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  // @ts-ignore
  const { loading } = useListAction(listAwsAmis, { params: { namespace } })

  const amis = useSelector(awsAmisSelector)
  const regionAmis = propOr({}, region, amis)

  const regionAmiItems = useMemo(
    () =>
      unnest(
        Object.entries(regionAmis).map(([os, value]) => {
          return value.map((val) => ({
            label: renderLabel(os, val),
            value: val.id,
            ...val,
          }))
        }),
      ),
    [regionAmis],
  )
  const items = useMemo(() => concat(regionAmiItems, custom), [regionAmiItems])

  const handleChange = useCallback(
    (value) => {
      const selectedValue = regionAmiItems.find((i) => i.value === value)
      selectedValue ? onChange(selectedValue) : onChange('custom')
    },
    [regionAmiItems, onChange],
  )

  useEffect(() => {
    if (loading || !value || typeof value !== 'string') return // the selectedValue can be object sometimes
    const isValidValue = !!items.find((i) => i.value === value)
    if (!isValidValue) {
      onChange('custom')
    }
  }, [items, onChange])

  return (
    <AsyncDropdown
      loading={loading}
      items={items}
      value={value}
      onChange={handleChange}
      {...rest}
    />
  )
}
