import React from 'react'
import { GridCellProps } from 'core/elements/grid/hooks/useGridRows'
import useReactRouter from 'use-react-router'
import SimpleLink from 'core/components/SimpleLink'
import { routes } from 'core/utils/routes'
import { NodeGroupSelector } from '../capi/details/node-groups/CapiClustersNodeGroupsOverview'

export default function NodeGroupNameCell({ item: { name } }: GridCellProps<NodeGroupSelector>) {
  const { match } = useReactRouter()
  const clusterId = match.params.id
  const tab = match.params.tab
  return (
    <SimpleLink src={routes.cluster.capi.nodeGroups.path({ id: clusterId, tab, name })}>
      {name}
    </SimpleLink>
  )
}
