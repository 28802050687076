import { GridCellProps } from 'core/elements/grid/hooks/useGridRows'
import ClusterStatusSpan from 'app/plugins/infrastructure/components/clusters/ClusterStatusSpan'
import { connectionStatusFieldsTable } from 'app/plugins/infrastructure/components/clusters/ClusterStatusUtils'
import React from 'react'
import { INodesSelector } from 'app/plugins/infrastructure/components/nodes/model'
import { routes } from 'core/utils/routes'
import SimpleLink from 'core/components/SimpleLink'

const renderConverging = (clusterUuid) => (
  <ClusterStatusSpan status="loading" iconStatus>
    {clusterUuid ? (
      <SimpleLink src={routes.cluster.legacy.detail.path({ tab: 'node-health', id: clusterUuid })}>
        Converging
      </SimpleLink>
    ) : (
      'Converging'
    )}
  </ClusterStatusSpan>
)

export default function NodeStatusCell({
  item: { clusterUuid, status, combined, isAuthorized },
}: GridCellProps<INodesSelector>) {
  if (!isAuthorized) {
    return (
      <ClusterStatusSpan status={'fail'} iconStatus={true}>
        Unauthorized
      </ClusterStatusSpan>
    )
  }
  if (status === 'converging') {
    return renderConverging(clusterUuid)
  }

  const connectionStatus = status === 'disconnected' ? 'disconnected' : 'connected'
  const showLastResponse = status === 'disconnected' && combined && combined.lastResponse

  const fields = connectionStatusFieldsTable[connectionStatus]
  return (
    <ClusterStatusSpan status={fields.clusterStatus}>
      {fields.label}
      <br />
      {showLastResponse && `since ${combined.lastResponse}`}
    </ClusterStatusSpan>
  )
}
