import React from 'react'
import { GridCellProps } from 'core/elements/grid/hooks/useGridRows'
import { INodesSelector } from 'app/plugins/infrastructure/components/nodes/model'
import ExternalLink from 'core/components/ExternalLink'
import Tooltip from 'core/elements/tooltip'

export default function LogsCell({ value: url }: GridCellProps<INodesSelector, string>) {
  return (
    <Tooltip message="This is the log file generated by the PMK installer and PMK node status check components (pf9-kube)">
      <div>
        <ExternalLink url={url}>View</ExternalLink>
      </div>
    </Tooltip>
  )
}
