import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Theme from 'core/themes/model'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import WizardStep from 'core/components/wizard/WizardStep'
import CapiClusterSpecReview from './capi-review/CapiClusterSpecReview'
import CapiVpcConfigReview from './capi-review/CapiVpcConfigReview'
import CapiAdvancedLoadBalancerConfigReview from './capi-review/CapiAdvancedLoadBalancerConfigReview'
import CapiNetworkingReview from './capi-review/CapiNetworkingReview'
import CapiPropertiesReview from './capi-review/CapiPropertiesReview'
import CapiConfigurationReview from './capi-review/CapiConfigurationReview'
import CapiWorkerNodeGroupReview from './capi-review/CapiWorkerNodeGroupReview'
import CapiAddonsReview from './capi-review/CapiAddonsReview'
import Text from 'core/elements/Text'
import SimpleLink from 'core/components/SimpleLink'

export default function CapiFinalReviewWizardStep({ wizardContext }) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const ViewOptions = () => (
    <div className={classes.viewOptions}>
      <Text component="span" variant="body2">
        View Options :
      </Text>
      <SimpleLink textDecoration="none" onClick={() => setOpen(true)}>
        <Text variant="caption1"> Expand All </Text>
      </SimpleLink>
      <Text component="span" variant="body2">
        |
      </Text>
      <SimpleLink textDecoration="none" onClick={() => setOpen(false)}>
        <Text variant="caption1"> Collapse All</Text>
      </SimpleLink>
    </div>
  )

  return (
    <WizardStep
      stepId="finalReview"
      label="Finalize & Review"
      showAllYamls
      collapseYamls
      // TODO:CAPI : tracking functions
      // onNext={awsClusterTracking.wZStepTwo(trackingFields)}
    >
      <ValidatedForm
        classes={{ root: classes.validatedFormContainer }}
        fullWidth
        initialValues={wizardContext}
        elevated={false}
      >
        {({ values, setFieldValue }) => (
          <>
            <ViewOptions />
            <CapiClusterSpecReview
              open={open}
              wizardContext={wizardContext}
            ></CapiClusterSpecReview>
            <CapiVpcConfigReview open={open} wizardContext={wizardContext}></CapiVpcConfigReview>
            <CapiAdvancedLoadBalancerConfigReview
              open={open}
              wizardContext={wizardContext}
            ></CapiAdvancedLoadBalancerConfigReview>
            <CapiPropertiesReview open={open} wizardContext={wizardContext}></CapiPropertiesReview>
            <CapiNetworkingReview open={open} wizardContext={wizardContext}></CapiNetworkingReview>
            <CapiConfigurationReview
              open={open}
              wizardContext={wizardContext}
            ></CapiConfigurationReview>
            <CapiWorkerNodeGroupReview
              open={open}
              wizardContext={wizardContext}
            ></CapiWorkerNodeGroupReview>
            <CapiAddonsReview open={open} wizardContext={wizardContext}></CapiAddonsReview>
            <ViewOptions />
          </>
        )}
      </ValidatedForm>
    </WizardStep>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  validatedFormContainer: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
  nonConfigurableAddonsSection: {
    margin: theme.spacing(0, 0, 0, 6),
  },
  viewOptions: {
    textAlign: 'end',
  },
}))
