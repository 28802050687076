import ModalForm from 'core/elements/modal/ModalForm'
import TextField from 'core/components/validatedForm/TextField'
import React, { useMemo, useEffect } from 'react'
import AwsRegionFlavorPicklist from '../../../aws/AwsRegionFlavorPicklist'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import WorkerNodeInstanceTypeField from '../../../form-components/WorkerNodeInstanceTypeField'
import AwsAmiField from '../../../form-components/AwsAmiField'
import AwsAmiPicklist, { cloudInit } from '../../../aws/AwsAmiPicklist'
import useParams from 'core/hooks/useParams'
import KeyValuesField from 'core/components/validatedForm/KeyValuesField'
import TaintEffectsPicklist from '../../../form-components/TaintEffectsPicklist'
import { isNilOrEmpty, keyValueArrToObj, objToKeyValueArr } from 'utils/fp'
import machinePool from '../../../aws/capi/schema/default/machine-pool.json'
import nodeletConfig from '../../../aws/capi/schema/default/nodelet-config.json'
import awsMachinePool from '../../../aws/capi/schema/default/aws-machine-pool.json'
import eksConfig from '../../../aws/capi/schema/eks/eks-config.json'
import { YamlTemplate } from 'core/components/wizard/YamlTemplates'
import YamlTemplateParser from 'utils/YamlTemplateParser'
import Divider from 'core/elements/Divider'
import { makeStyles } from '@material-ui/core/styles'
import Theme from 'core/themes/model'
import useCapiResourceUpdate from '../../../aws/capi/useCapiResourceUpdate'
import CapiAwsAvailabilityZoneField from '../../../aws/capi/capi-vpc/CapiAwsAvailiabilityZoneField'
import MixedInstancesPolicyFields from '../../../aws/capi/MixedInstancesPolicyFields'
import CapiMachinePoolNodeUpdateStrategies from '../../../aws/capi/CapiMachinePoolNodeUpdateStrategies'
import { ClusterCloudPlatforms } from 'app/constants'
import KubernetesVersionField from '../../../form-components/KubernetesVersionField'
import { ConfigTypes, NodeGroupTypes } from '../../model'
import { CapiOperation } from 'app/plugins/infrastructure/components/clusters/aws/capi/model'
import CapiEditModalErrors from '../CapiEditModalErrors'

const yamlTemplates: YamlTemplate[] = [
  {
    title: NodeGroupTypes.MachinePool,
    schema: machinePool,
  },
  {
    title: NodeGroupTypes.AwsMachinePool,
    schema: awsMachinePool,
  },
  {
    title: ConfigTypes.NodeletConfig,
    schema: nodeletConfig,
    hide: (values) => values.isEksCluster,
  },
  {
    title: ConfigTypes.EksConfig,
    schema: eksConfig,
    hide: (values) => !values.isEksCluster,
  },
]

const parseYamls = (values) => {
  return yamlTemplates
    .map((yamlTemplate) => {
      const { schema, hide } = yamlTemplate
      if (hide && hide(values)) return null
      const templateParser = new YamlTemplateParser(schema)
      templateParser.setValues(values)
      return templateParser.getJson()
    })
    .filter(Boolean)
}

const defaultParams = {
  instanceType: 't2.medium',
} as any

const capiResourceUpdateOptions = {
  operation: CapiOperation.PartialUpdate,
}

export default function EditMachinePoolModal({ nodeGroup, onClose, cluster, refreshData = null }) {
  const classes = useStyles()
  const { params, updateParams, getParamsUpdater } = useParams(defaultParams)
  const { execute, errors: allErrors, updating } = useCapiResourceUpdate(capiResourceUpdateOptions)
  const isEksCluster = !!(
    cluster?.infrastructureType && cluster.infrastructureType === ClusterCloudPlatforms.EKS
  )
  const initialValues = useMemo(() => {
    if (isNilOrEmpty(nodeGroup)) return {}
    const config = nodeGroup.resources.find((r) => r.kind === 'NodeletConfig')
    return {
      name: nodeGroup.name,
      namespace: nodeGroup.namespace,
      clusterName: nodeGroup.clusterName,
      wgVersion: nodeGroup.k8sVersion,
      availabilityZones: nodeGroup.awsMachinePool?.availabilityZones,
      nodeCount: nodeGroup.awsMachinePool?.maxSize,
      instanceType: nodeGroup.awsMachinePool?.awsLaunchTemplate?.instanceType,
      ami: nodeGroup.awsMachinePool?.amiId,
      amiId: nodeGroup.awsMachinePool?.amiId,
      mixedInstances: nodeGroup.awsMachinePool?.mixedInstances,
      mixedInstancesPolicy: nodeGroup.awsMachinePool?.mixedInstancesPolicy || null,
      refreshPreferences: {
        minHealthyPercentage:
          nodeGroup.awsMachinePool?.spec?.refreshPreferences?.minHealthyPercentage || 90,
        strategy: 'Rolling',
      },
      labels: objToKeyValueArr(config?.spec?.labels),
      taints: config?.spec?.taints,
      region: cluster?.region,
      cloudProviderId: cluster?.cloudProviderId,
      isEksCluster: isEksCluster,
      // Even though these are not in the wizard, if left out the
      // parser will return them as "" and ruin the yaml
      // perhaps the schema should have them as pathnotnull?
      sshKey: nodeGroup.awsMachinePool?.sshKeyName,
      config: nodeGroup.spec?.template?.spec?.bootstrap?.configRef?.kind || nodeGroup.config?.kind,
      machinePoolType: nodeGroup.infrastructureRef?.kind,
    }
  }, [nodeGroup, cluster, isEksCluster])

  useEffect(() => {
    updateParams(initialValues)
  }, [initialValues])

  const handleSubmit = async (formValues) => {
    const finalParams = {
      ...params,
      ...formValues,
      amiId: params.customAmiId || params.ami,
      labels: Array.isArray(params.labels) ? keyValueArrToObj(params.labels) : params.labels,
    }
    const resources = parseYamls(finalParams)
    const { errors } = await execute(resources)
    if (isNilOrEmpty(errors)) {
      if (refreshData) {
        refreshData(nodeGroup.kind)
      }
      onClose()
    }
  }

  const errorComponent = useMemo(() => <CapiEditModalErrors errors={allErrors} />, [allErrors])

  return (
    <ModalForm
      open
      title="Edit Node Group"
      onClose={onClose}
      onSubmit={handleSubmit}
      submitting={updating}
      initialValues={initialValues}
      customErrorComponent={errorComponent}
    >
      <FormFieldSection title="">
        <CapiAwsAvailabilityZoneField
          id="availabilityZones"
          wizardContext={params}
          isEksCluster={isEksCluster}
          value={params.availabilityZones}
          onChange={getParamsUpdater('availabilityZones')}
        />
        <TextField
          id="nodeCount"
          label="Node Count"
          value={params.nodeCount}
          type="number"
          onChange={getParamsUpdater('nodeCount')}
          min="0"
          required
        />
        <WorkerNodeInstanceTypeField
          id="instanceType"
          dropdownComponent={AwsRegionFlavorPicklist}
          label="Instance Type"
          values={params}
          value={params.instanceType}
          onChange={getParamsUpdater('instanceType')}
        />
        {!isEksCluster && (
          <>
            <AwsAmiField
              id="ami"
              dropdownComponent={AwsAmiPicklist}
              label="Operating System"
              values={params}
              value={params.ami}
              onChange={(value) => {
                const isCustomValue = value === 'custom'
                updateParams({
                  ami: value?.id,
                  cloudInit: value?.secretsManager ? cloudInit : null,
                  customAmi: isCustomValue,
                  customAmiId: isCustomValue ? params.amiId : '',
                })
              }}
            />
            <TextField
              id="customAmiId"
              label="Custom AMI ID (optional)"
              placeholder="Enter Value..."
              disabled={!params.customAmiId}
              value={params.customAmiId}
              onChange={(value) => {
                getParamsUpdater('customAmiId')(value)
                getParamsUpdater('amiId')(value)
                getParamsUpdater('cloudInit')(cloudInit)
              }}
            />
          </>
        )}

        <MixedInstancesPolicyFields context={params} onChange={getParamsUpdater} />
        <CapiMachinePoolNodeUpdateStrategies context={params} onChange={getParamsUpdater} />
        {!isEksCluster && (
          <>
            <Divider className={classes.divider} />
            <KeyValuesField
              id="labels"
              label="Labels"
              value={params.labels}
              onChange={(value) => getParamsUpdater('labels')(value)}
              addLabel="Add Label"
              allowMultipleValues
            />
            <Divider className={classes.divider} />
            <KeyValuesField
              id="taints"
              label="Taints"
              value={params.taints}
              onChange={getParamsUpdater('taints')}
              addLabel="Add Taint"
              allowMultipleValues
              additionalFields={[
                {
                  id: 'effect',
                  label: 'Effects',
                  Component: TaintEffectsPicklist,
                  props: {
                    isAwsManagedMachinePool: false,
                  },
                },
              ]}
            />
          </>
        )}
      </FormFieldSection>
    </ModalForm>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  divider: {
    margin: theme.spacing(1, 0, 0, 0),
  },
}))
