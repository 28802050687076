import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import { getClusterAddonBody, getCapiClusterAddonBody } from './helpers'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import UpdateAction from 'core/actions/UpdateAction'
import CreateAction from 'core/actions/CreateAction'
import DeleteAction from 'core/actions/DeleteAction'
import { allKey } from 'app/constants'
import { ClusterAddonType } from './model'
import { ClusterTypes } from '../model'

const { qbert, sunpike } = ApiClient.getInstance()

export const monitoringAddonActions = ActionsSet.make<DataKeys.MonitoringAddons>({
  uniqueIdentifier: 'metadata.uid',
  entityName: entityNamesByKey.MonitoringAddons,
  cacheKey: DataKeys.MonitoringAddons,
})

export const listMonitoringAddons = monitoringAddonActions.add(
  new ListAction<DataKeys.MonitoringAddons>(async () => {
    Bugsnag.leaveBreadcrumb('Attempting to get monitoring addons')
    return qbert.getAllClusterAddons('type=monitoring')
  }),
)

export const kubevirtAddonActions = ActionsSet.make<DataKeys.KubevirtAddons>({
  uniqueIdentifier: 'metadata.uid',
  entityName: entityNamesByKey.KubevirtAddons,
  cacheKey: DataKeys.KubevirtAddons,
})

export const listKubevirtAddons = kubevirtAddonActions.add(
  new ListAction<DataKeys.KubevirtAddons>(async () => {
    Bugsnag.leaveBreadcrumb('Attempting to get kubevirt addons')
    return qbert.getAllClusterAddons('type=kubevirt')
  }),
)

export const clusterAddonActions = ActionsSet.make<DataKeys.ClusterAddons>({
  uniqueIdentifier: 'metadata.name',
  indexBy: 'clusterId',
  entityName: entityNamesByKey.ClusterAddons,
  cacheKey: DataKeys.ClusterAddons,
})

// TODO:
// Capi clusters use cluster name, while legacy clusters use cluster id
// Cluster addons for legacy vs capi clusters should be separated into different actions
export const listClusterAddons = clusterAddonActions.add(
  new ListAction<
    DataKeys.ClusterAddons,
    { clusterId?: string; clusterName?: string; clusterNamespace?: string }
  >(async ({ clusterId, clusterName, clusterNamespace }) => {
    Bugsnag.leaveBreadcrumb('Attempting to get cluster addons')
    return clusterId === allKey
      ? qbert.getAllClusterAddons()
      : qbert.getClusterAddons(clusterName || clusterId, clusterNamespace)
  }),
)

export const listAllClusterAddons = new ListAction<DataKeys.ClusterAddons>(
  async () => {
    Bugsnag.leaveBreadcrumb('Attempting to get all cluster addons')
    return sunpike.getAllClusterAddons()
  },
  {
    uniqueIdentifier: 'metadata.name',
    indexBy: 'clusterId',
    entityName: entityNamesByKey.ClusterAddons,
    cacheKey: DataKeys.ClusterAddons,
  },
)

export const createClusterAddon = clusterAddonActions.add(
  new CreateAction<
    DataKeys.ClusterAddons,
    {
      clusterId?: string
      clusterName?: string
      clusterType?: string
      namespace?: string
      type: string
      overrideParams: any
      version: string
    },
    unknown
  >(async ({ clusterId, clusterName, clusterType, type, overrideParams, version, namespace }) => {
    const body =
      clusterType === ClusterTypes.Capi
        ? getCapiClusterAddonBody({ clusterName, type, overrideParams, version, namespace })
        : getClusterAddonBody({ clusterId, type, overrideParams, version })
    const result = await qbert.addClusterAddon(body)
    if (clusterType === ClusterTypes.Capi) {
      listClusterAddons.call({ clusterId, clusterName, clusterNamespace: namespace })
    }
    if (type === ClusterAddonType.Monitoring) {
      listMonitoringAddons.call({})
    }
    if (type === ClusterAddonType.Kubevirt) {
      listKubevirtAddons.call({})
    }
    return result
  }),
)

export const updateClusterAddon = clusterAddonActions.add(
  new UpdateAction<
    DataKeys.ClusterAddons,
    {
      clusterId?: string
      clusterName?: string
      clusterType?: string
      namespace?: string
      type: string
      name: string
      overrideParams: any
      version: string
    },
    unknown
  >(
    async ({
      clusterId,
      clusterName,
      clusterType,
      type,
      name,
      namespace,
      overrideParams,
      version,
    }) => {
      const body =
        clusterType === ClusterTypes.Capi
          ? getCapiClusterAddonBody({ clusterName, type, overrideParams, version, namespace })
          : getClusterAddonBody({ clusterId, type, overrideParams, version })
      const result = await qbert.editClusterAddon(name, body)
      if (clusterType === ClusterTypes.Capi) {
        listClusterAddons.call({ clusterId, clusterName })
      }
      if (type === ClusterAddonType.Monitoring) {
        listMonitoringAddons.call({})
      }
      if (type === ClusterAddonType.Kubevirt) {
        listKubevirtAddons.call({})
      }
      return result
    },
  ),
)

export const deleteClusterAddon = clusterAddonActions.add(
  new DeleteAction<DataKeys.ClusterAddons, { name: string; type: string }>(
    async ({ name, type }) => {
      Bugsnag.leaveBreadcrumb('Attempting to delete cluster addon', { addon: name, type })
      await qbert.deleteClusterAddon(name)
      if (type === ClusterAddonType.Monitoring) {
        listMonitoringAddons.call({})
      }
      if (type === ClusterAddonType.Kubevirt) {
        listKubevirtAddons.call({})
      }
    },
  ),
)

export const addonVersionActions = ActionsSet.make<DataKeys.AddonVersions>({
  uniqueIdentifier: 'metadata.id',
  indexBy: 'clusterId',
  entityName: entityNamesByKey.AddonVersions,
  cacheKey: DataKeys.AddonVersions,
})

export const listAddonVersions = addonVersionActions.add(
  new ListAction<DataKeys.AddonVersions, { clusterId: string }>(async ({ clusterId }) => {
    Bugsnag.leaveBreadcrumb('Attempting to get addon versions')
    return qbert.getAddonVersions(clusterId)
  }),
)

export const clusterVersionActions = ActionsSet.make<DataKeys.ClusterVersions>({
  uniqueIdentifier: 'metadata.uid',
  entityName: entityNamesByKey.ClusterVersions,
  cacheKey: DataKeys.ClusterVersions,
})

export const listClusterVersions = clusterVersionActions.add(
  new ListAction<DataKeys.ClusterVersions>(async () => {
    Bugsnag.leaveBreadcrumb('Attempting to get cluster versions')
    return qbert.getClusterVersions()
  }),
)
