import Card from 'core/elements/card'
import { identity } from 'ramda'
import React, { useEffect } from 'react'
import { ICapiClusterSelector } from '../../model'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Divider from 'core/elements/Divider'
import useListAction from 'core/hooks/useListAction'
import { hostUsageSelectorForCapiCluster } from './hosts/selectors'
import { useSelector } from 'react-redux'
import ResourceUsageTable from 'app/plugins/infrastructure/components/common/ResourceUsageTable'
import { listCapiHosts } from './hosts/actions'
import Progress from 'core/components/progress/Progress'
import { CapiResourceKind } from 'app/plugins/infrastructure/components/clusters/aws/capi/model'

export default function CapiClusterOverviewResources({
  cluster,
}: {
  cluster: ICapiClusterSelector
}) {
  const { name: clusterName, controlPlane } = cluster
  const isEksCluster = controlPlane?.kind === CapiResourceKind.AWSManagedControlPlane

  const { loading, reload } = useListAction(listCapiHosts, {
    params: { clusterName },
  })

  const usage = useSelector(hostUsageSelectorForCapiCluster)

  const { compute, disk, memory } = usage
  const { current: currentCompute, max: maxCompute } = compute
  const { current: currentMemory, max: maxMemory } = memory
  const { current: currentDisk, max: maxDisk } = disk

  useEffect(() => {
    reload(true)
  }, []) // reloading to keep the hosts updated for the selected capi cluster

  const getPreciseUsageValue = (
    usage: number,
    unit: string,
    valueConverter = identity as any, //todo:fix typings
    precision = 1,
  ) => `${valueConverter(usage).toFixed(precision)} ${unit}`

  const toMHz = (value: number) => value * 1024

  const classes = useStyles()
  const ResourceBlock = ({ title, value }) => (
    <div className={classes.resourcesColumn}>
      <Text variant="caption1" className={classes.contentTitle}>
        {title}
      </Text>
      <Text variant="caption1" className={classes.contentText}>
        {value}
      </Text>
    </div>
  )

  const CPU = () => (
    <>
      <Text variant="caption1" className={classes.title}>
        CPU
      </Text>
      <div className={classes.resourcesContainer}>
        <ResourceBlock title="Used" value={getPreciseUsageValue(currentCompute, 'MHz', toMHz)} />
        <ResourceBlock
          title="Available"
          value={getPreciseUsageValue(maxCompute - currentCompute, 'MHz', toMHz)}
        />
        <div>
          <ResourceUsageTable
            width={320}
            valueOff={false}
            valueConverter={toMHz}
            units="MHz"
            stats={compute}
          />
          <Text variant="caption2" component="p" className={classes.barSubtitle}>
            {`Total: ${getPreciseUsageValue(maxCompute, 'MHz', toMHz)}`}
          </Text>
        </div>
      </div>
    </>
  )
  const Memory = () => (
    <>
      <Text variant="caption1" className={classes.title}>
        Memory
      </Text>
      <div className={classes.resourcesContainer}>
        <ResourceBlock title="Used" value={getPreciseUsageValue(currentMemory, 'GiB')} />
        <ResourceBlock
          title="Available"
          value={getPreciseUsageValue(maxMemory - currentMemory, 'GiB')}
        />
        <div>
          <ResourceUsageTable width={320} valueOff={false} units="GiB" stats={memory} />
          <Text variant="caption2" component="p" className={classes.barSubtitle}>
            {`Total: ${getPreciseUsageValue(maxMemory, 'GiB')}`}
          </Text>
        </div>
      </div>
    </>
  )
  const Disk = () => (
    <>
      <Text variant="caption1" className={classes.title}>
        Disk
      </Text>
      <div className={classes.resourcesContainer}>
        <ResourceBlock title="Used" value={getPreciseUsageValue(currentDisk, 'GiB')} />
        <ResourceBlock
          title="Available"
          value={getPreciseUsageValue(maxDisk - currentDisk, 'GiB')}
        />
        <div>
          <ResourceUsageTable width={320} valueOff={false} units="GiB" stats={disk} />
          <Text variant="caption2" component="p" className={classes.barSubtitle}>
            {`Total: ${getPreciseUsageValue(maxDisk, 'GiB')}`}
          </Text>
        </div>
      </div>
    </>
  )

  return (
    <Progress loading={loading} overlay>
      <Card title="Resources" className={classes.container}>
        {isEksCluster ? (
          <Text variant="subtitle2" className={classes.emptyText}>
            No data found
          </Text>
        ) : (
          <>
            <CPU />
            <Divider className={classes.divider} />
            <Memory />
            <Divider className={classes.divider} />
            <Disk />
          </>
        )}
      </Card>
    </Progress>
  )
}

{
  /* <ResourceUsageTable
valueOff={valueOff}
valueConverter={toMHz}
units="MHz"
label="CPU"
stats={usage?.compute}
/>
<ResourceUsageTable valueOff={valueOff} units="GiB" label="Memory" stats={usage?.memory} />
<ResourceUsageTable valueOff={valueOff} units="GiB" label="Storage" stats={usage?.disk} /> */
}

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    maxWidth: 800,
    height: 500,
  },
  resourcesContainer: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  title: { marginLeft: theme.spacing(3) },
  summaryContent: {
    display: 'flex',
    background: theme.components.frame.accentBackground,
  },
  resourcesColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    margin: '18px 24px 32px 24px',
  },
  contentTitle: {
    color: theme.components.typography.passive,
  },
  barSubtitle: {
    width: 320,
    textAlign: 'right',
    color: theme.components.typography.passive,
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  emptyText: {
    marginTop: '25%', // center the text
    marginLeft: '40%',
  },
}))
