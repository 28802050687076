import React, { useMemo } from 'react'
import DocumentMeta from 'core/components/DocumentMeta'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { listTablePrefs } from 'app/constants'
import { useAppSelector } from 'app/store'
import ListContainer from 'core/containers/ListContainer'
import { pick } from 'ramda'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { DateAndTime } from 'core/components/listTable/cells/DateCell'
import { createGridStatusCell, StatusCellModel } from 'core/elements/grid/cells/GridStatusCell'
import { ICapiClusterSelector, NodeGroupTypeLabels } from '../../model'
import { useSelector } from 'react-redux'
import { machineDeploymentSelector } from '../../machine-deployment/selectors'
import { machinePoolSelector } from '../../machine-pool/selectors'
import { IMachineDeploymentSelector, Phase } from '../../machine-deployment/model'
import { IMachinePoolSelector } from '../../machine-pool/model'
import { IAwsManagedMachinePoolSelector } from '../../aws-managed-machine-pool/model'
import NodeGroupNameCell from '../../../cluster-cells/NodeGroupNameCell'
import CapiClustersNodeGroupsHeader from './CapiClustersNodeGroupsHeader'
import { isNilOrEmpty } from 'utils/fp'
import { routes } from 'core/utils/routes'
import SimpleLink from 'core/components/SimpleLink'
import Button from 'core/elements/button'
import AddNodeGroupsPage from './AddNodeGroupsPage'
import getGridDialogButton from 'core/elements/grid/helpers/getGridDialogButton'
import DeleteNodeGroupsDialog from './DeleteNodeGroupsDialog'

export type NodeGroupSelector =
  | IMachineDeploymentSelector
  | IMachinePoolSelector
  | IAwsManagedMachinePoolSelector

const usePrefParams = createUsePrefParamsHook('Machine Deployments', listTablePrefs)

const getPhaseStatus = (phase): StatusCellModel => {
  switch (phase) {
    case Phase.Running:
      return { variant: 'success', label: Phase.Running }

    case Phase.Failed:
      return { variant: 'error', label: Phase.Failed }

    case Phase.ScalingUp:
      return { variant: 'warning', label: Phase.ScalingUp }

    case Phase.ScalingDown:
      return { variant: 'warning', label: Phase.ScalingDown }

    case Phase.Deleting:
      return { variant: 'error', label: Phase.Deleting }

    default:
      return { variant: 'unknown', label: Phase.Unknown }
  }
}
const cellFormatFn = (value) => (isNilOrEmpty(value) ? '-' : value)
const columns: GridViewColumn<NodeGroupSelector>[] = [
  {
    key: 'name',
    label: 'Name',
    CellComponent: NodeGroupNameCell,
    memoizeCell: false,
  },
  {
    key: 'phase',
    label: 'Phase',
    accessor: (nodeGroup) => nodeGroup,
    CellComponent: createGridStatusCell({
      dataFn: (nodeGroup: NodeGroupSelector): StatusCellModel => {
        const phase = !!nodeGroup?.metadata?.deletionTimestamp ? 'Deleting' : nodeGroup?.phase
        return getPhaseStatus(phase)
      },
    }),
  },
  {
    key: 'type',
    label: 'Type',
  },
  {
    key: 'k8sVersion',
    label: 'K8s Version',
    formatFn: cellFormatFn,
  },
  {
    key: 'creationTimestamp',
    label: 'Creation',
    CellComponent: DateAndTime,
  },
  {
    key: 'replicas',
    label: 'Desired Replicas',
    formatFn: cellFormatFn,
  },
  {
    key: 'readyReplicas',
    label: 'Ready Replicas',
    formatFn: cellFormatFn,
  },
  {
    key: 'availableReplicas',
    label: 'Available Replicas',
    formatFn: cellFormatFn,
  },
  {
    key: 'unavailableReplicas',
    label: 'Unavailable Replicas',
    formatFn: cellFormatFn,
  },
]

const searchTargets = ['name', 'phase']

type NodeGroup = IMachineDeploymentSelector | IMachinePoolSelector

export default function CapiClustersNodeGroupsOverview({
  cluster,
  loading,
  reloadNodeGroups,
}: {
  cluster: ICapiClusterSelector
  loading: boolean
  reloadNodeGroups: () => void
}) {
  const { params, getParamsUpdater } = usePrefParams({})

  const allNodeGroups = cluster?.allNodeGroups || []
  const machineDeployments = cluster?.machineDeployments || []
  const machinePools = cluster?.machinePools || []

  const headerItems = useMemo(
    () => [
      { label: 'Node Groups Total', value: allNodeGroups.length },
      { label: NodeGroupTypeLabels.MachineDeployment, value: machineDeployments.length },
      { label: NodeGroupTypeLabels.MachinePool, value: machinePools.length },
    ],
    [allNodeGroups, machineDeployments, machinePools],
  )
  const batchActions = [
    {
      label: 'Delete',
      icon: 'trash-alt',
      BatchActionButton: getGridDialogButton(DeleteNodeGroupsDialog),
    },
  ]

  return (
    <>
      <DocumentMeta title="Capi Node Groups Overview" />
      <AddNodeGroupsPage existingNodeGroups={allNodeGroups} />
      <CapiClustersNodeGroupsHeader items={headerItems} />
      <ListContainer<any, NodeGroupSelector>
        searchTargets={searchTargets}
        uniqueIdentifier="uuid"
        loading={loading}
        loadingMessage="Loading node groups..."
        onRefresh={reloadNodeGroups}
        data={allNodeGroups}
        columns={columns}
        getParamsUpdater={getParamsUpdater}
        label="Node Groups"
        showItemsCountInLabel
        showBreadcrumbs={false}
        batchActions={batchActions}
        extraToolbarContent={
          <SimpleLink src={routes.cluster.capi.addNodeGroups.path({ id: cluster?.uuid })}>
            <Button>Add Node Group</Button>
          </SimpleLink>
        }
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
