import React, { useMemo } from 'react'
import { pathStrOr } from 'utils/fp'
import { uniq } from 'ramda'
import useDataLoader from 'core/hooks/useDataLoader'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'
import { loadCloudProviderRegionDetails } from 'app/plugins/infrastructure/components/cloudProviders/actions'

interface Props {
  cloudProviderId: string
  cloudProviderRegionId: string
}

export default function AzureResourceGroupPicklist({
  cloudProviderId,
  cloudProviderRegionId,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  const [details, loading] = useDataLoader(loadCloudProviderRegionDetails, {
    cloudProviderId,
    cloudProviderRegionId,
  })

  // Azure might have more than 1 virtualNetwork with the same resourceGroup be sure to use 'uniq'
  const items = useMemo<{ label: string; value: string }[]>(
    () =>
      uniq(
        pathStrOr([], '0.virtualNetworks', details).map((x) => ({
          label: x.resourceGroup,
          value: x.resourceGroup,
        })),
      ),
    [details],
  )

  return <AsyncDropdown loading={loading} items={items} {...rest} />
}
