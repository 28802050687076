import React, { FC, useEffect, useState } from 'react'
import FormWrapper from 'core/components/FormWrapper'
import Wizard from 'core/components/wizard/Wizard'
import useReactRouter from 'use-react-router'
import { pathJoin, snakeToPascalString } from 'utils/misc'
import { k8sPrefix } from 'app/constants'
import { routes } from 'core/utils/routes'
import { ClusterCreateTypeNames, ClusterCreateTypes } from '../model'
import { CloudProviders } from 'app/plugins/infrastructure/components/cloudProviders/model'
import DocumentMeta from 'core/components/DocumentMeta'
import { getFormTitle } from '../helpers'
import { bareOSClusterTracking } from '../tracking'
import { isNilOrEmpty } from 'utils/fp'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { createClusterAddon } from '../cluster-addons/new-actions'
import { enableMetal3 } from 'app/plugins/metal3/actions'
import { createCluster } from '../newActions'

const listUrl = pathJoin(k8sPrefix, 'infrastructure')

const canFinishAndReview = (createType) => ({
  masterNodes,
  workerNodes,
  allowWorkloadsOnMaster,
  masterVipIpv4,
  masterVipIface,
}) => {
  const hasMasters = !!masterNodes && masterNodes.length > 0
  const hasOneMaster = hasMasters && masterNodes.length === 1
  const hasMultipleMasters = hasMasters && masterNodes.length >= 1
  const hasWorkers = !!workerNodes && workerNodes.length > 0
  if (createType === ClusterCreateTypes.SingleMaster) {
    return hasOneMaster && (!!allowWorkloadsOnMaster || hasWorkers)
  }
  if (createType === ClusterCreateTypes.MultiMaster) {
    return hasMultipleMasters && !!masterVipIpv4 && !!masterVipIface
  }

  return false
}

const wizardMetaFormattedNames = {
  name: 'Cluster Name',
  masterNodes: 'Master Nodes',
  workerNodes: 'Worker Nodes',
  networkPlugin: 'CNI',
}
const wizardMetaCalloutFields = Object.keys(wizardMetaFormattedNames)

const AddBareOsClusterPage = () => {
  const { history, match } = useReactRouter()
  const createType = match?.params?.type || ClusterCreateTypes.Custom
  const providerType = match?.params?.platform || CloudProviders.VirtualMachine
  const [activeView, setActiveView] = useState<{ ViewComponent: FC<any> }>(null)
  const [formTitle, setFormTitle] = useState<string>('')
  const [initialContext, setInitialContext] = useState(null)
  const [enablingAddon, setEnablingAddon] = useState(false)

  const segmentTrackingFields = {
    platform: providerType,
    target: createType,
  }

  useEffect(() => {
    async function loadFile(name, provider) {
      const fileName = snakeToPascalString(provider + '-' + name)
      const view = await import(`./create-templates/${fileName}`)
      setActiveView({ ViewComponent: view.default })
      setFormTitle(ClusterCreateTypeNames[name])
      setInitialContext(view.initialContext)
    }
    loadFile(createType, providerType)
  }, [createType, providerType])

  useEffect(() => {
    bareOSClusterTracking.createStarted(segmentTrackingFields)()
  }, [])

  const {
    updating: creatingBareOSCluster,
    update: createBareOSCluster,
    error: errorCreatingBareOSCluster,
  } = useUpdateAction(createCluster)

  const { update: enableAddon } = useUpdateAction(createClusterAddon)

  const handleSubmit = async (data) => {
    const { success, response: result } = await createBareOSCluster({
      ...data,
      clusterType: 'local',
      segmentTrackingFields,
    })
    if (success && !isNilOrEmpty(result)) {
      const { uuid: clusterId } = result
      if (data.enableMetal3) {
        setEnablingAddon(true)
        await enableMetal3(clusterId, data, enableAddon, false)
        setEnablingAddon(false)
      }
      history.push(routes.cluster.legacy.detail.path({ tab: 'node-health', id: clusterId }))
    }
  }

  const title = getFormTitle(formTitle, createType)

  const ViewComponent = activeView?.ViewComponent
  return (
    <FormWrapper backUrl={listUrl} loading={creatingBareOSCluster || enablingAddon}>
      <DocumentMeta title={title} bodyClasses={['form-view']} />
      {!!initialContext && (
        <Wizard
          error={errorCreatingBareOSCluster}
          onComplete={handleSubmit}
          context={initialContext}
          originPath={routes.cluster.add.root.path({ type: providerType })}
          showFinishAndReviewButton={canFinishAndReview(createType)}
        >
          {({ wizardContext, setWizardContext, onNext }) => (
            <>
              {ViewComponent && (
                <ViewComponent
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                  onNext={onNext}
                  summaryTitle={getFormTitle(formTitle, createType)}
                  summaryIcon={providerType}
                  summaryKeyOverrides={wizardMetaFormattedNames}
                  summaryCalloutFields={wizardMetaCalloutFields}
                />
              )}
            </>
          )}
        </Wizard>
      )}
    </FormWrapper>
  )
}

export default AddBareOsClusterPage
