import { customValidator, requiredValidator } from 'core/utils/fieldValidators'
import { assocPath } from 'ramda'
import { isNilOrEmpty, switchCase } from 'utils/fp'
import { AwsClusterIdentityKinds } from './model'
import {
  defaultCodeMirrorYamlValidations,
  moizedYamlLoad,
} from 'app/plugins/infrastructure/components/clusters/capi/details/helpers'
import { CapiResourceKind } from '../aws/capi/model'
import { ClusterAddonType } from '../cluster-addons/model'
import { Phase } from './machine-deployment/model'
import { IClusterStatus } from '../model'

export const getSubnetConfigs = (subnets = []) => {
  return subnets.reduce((accum, subnet) => {
    const { availabilityZone, isPublic } = subnet
    const accessLabel = isPublic ? 'publicSubnets' : 'privateSubnets'
    const existingSubnets = accum[availabilityZone]?.[accessLabel] || []
    const subnets = [...existingSubnets, subnet]
    return assocPath([availabilityZone, accessLabel], subnets, accum)
  }, {})
}

// Assuming that the identityRef name is in this format: PROJECTID-cloudprovider-name
export const getCloudProviderName = (identityRefName = '') =>
  identityRefName
    .split('-')
    .slice(1)
    .join('-')

export const getRoleArn = (name, awsClusterRoleIdentities = []) => {
  if (!name) return undefined
  return awsClusterRoleIdentities.find((i) => i.metadata?.name === name)?.roleARN
}

const identityResourceYamlValidations = [
  requiredValidator,
  customValidator((yaml) => {
    try {
      moizedYamlLoad(yaml)
      return true
    } catch (err) {
      return false
    }
  }, 'Provided YAML code is invalid'),
  customValidator((yaml) => {
    const body = moizedYamlLoad(yaml)
    return !!body?.kind
  }, 'YAML kind must be set'),
  customValidator((yaml) => {
    const body = moizedYamlLoad(yaml)
    return !!body?.metadata?.name
  }, 'metadata.name must be set'),
]

const nodeletControlPlaneValidations = [
  ...defaultCodeMirrorYamlValidations,
  customValidator((yaml) => {
    const body = moizedYamlLoad(yaml)
    return !!body?.spec?.replicas
  }, 'Number of replicas must be specified under metadata.replicas'),
  customValidator((yaml) => {
    const body = moizedYamlLoad(yaml)
    return body?.spec?.replicas % 2 !== 0
  }, 'Replicas must be an odd number'),
]

export const getCodeMirrorYamlValidations = (kind) => {
  return switchCase(
    {
      [AwsClusterIdentityKinds.AWSClusterControllerIdentity]: identityResourceYamlValidations,
      [AwsClusterIdentityKinds.AWSClusterRoleIdentity]: identityResourceYamlValidations,
      [AwsClusterIdentityKinds.AWSClusterStaticIdentity]: identityResourceYamlValidations,
      [CapiResourceKind.NodeletControlPlane]: nodeletControlPlaneValidations,
    },
    defaultCodeMirrorYamlValidations,
  )(kind)
}

export const getIdentityResource = (
  identityRef,
  rawAwsClusterRoleIdentities,
  rawAwsClusterStaticIdentities,
) => {
  if (isNilOrEmpty(identityRef)) return null
  const { kind, name } = identityRef
  if (kind === 'AWSClusterRoleIdentity') {
    return rawAwsClusterStaticIdentities.find((i) => i.metadata?.name === name)
  } else if (kind === 'AWSClusterStaticIdentity') {
    return rawAwsClusterStaticIdentities.find((i) => i.metadata?.name === name)
  }
  return null
}

export const getCapiClusterProxyEndpoint = (cluster) =>
  `/sunpike/apis/opt.pf9.io/v1beta1/namespaces/${cluster?.metadata?.namespace}/clusters/${cluster?.metadata?.name}/proxy`

export const getCapiK8sDashboardLink = (cluster) => {
  return `${window.location.origin}${getCapiClusterProxyEndpoint(
    cluster,
  )}/api/v1/namespaces/kubernetes-dashboard/services/https:kubernetes-dashboard:443/proxy/`
}

export const getCapiGrafanaLink = (cluster) => {
  return `${window.location.origin}/k8s/v4${getCapiClusterProxyEndpoint(
    cluster,
  )}/api/v1/namespaces/pf9-monitoring/services/http:grafana-ui:80/proxy/`
}

// This function is for getting override params for rare cases when an
// unconfigurable addon still has override params
export const getDefaultOverrideParams = (addonType, cluster) => {
  if (addonType === ClusterAddonType.CapiAutoScaler) {
    return {
      clusterAutoscalerNamespace: 'kube-system',
      clusterNamespace: cluster?.namespace,
    }
  }
  return {}
}

interface Status {
  count: number
  tooltipText: string
  variant: IClusterStatus
}

export interface AllResourceStatuses {
  [key: string]: Status
}

export const getNodeGroupStatuses = (nodeGroups = []) => {
  const statuses: AllResourceStatuses = {
    [Phase.Running]: {
      count: 0,
      tooltipText: 'Healthy',
      variant: 'ok',
    },
    [Phase.ScalingUp]: {
      count: 0,
      tooltipText: 'Scaling Up',
      variant: 'pause',
    },
    [Phase.ScalingDown]: {
      count: 0,
      tooltipText: 'Scaling Down',
      variant: 'pause',
    },
    [Phase.Failed]: {
      count: 0,
      tooltipText: 'Failed',
      variant: 'error',
    },
    [Phase.Unknown]: {
      count: 0,
      tooltipText: 'Unknown',
      variant: 'unknown',
    },
  }
  return nodeGroups.reduce((accum, nodeGroup) => {
    const phase = nodeGroup?.phase && accum[nodeGroup?.phase] ? nodeGroup?.phase : Phase.Unknown
    accum[phase].count += 1
    return accum
  }, statuses)
}

const getStatusCondSeverityRanking = (severity) =>
  switchCase(
    {
      Warning: 0,
      Info: 1,
    },
    0,
  )(severity)

/*
  Sort by severity in descending order. Info < Warning so conditions with severity === 'Warning'
  will be listed first
*/
export const sortStatusCondsBySeverity = (condA, condB) => {
  if (isNilOrEmpty(condA?.severity) && isNilOrEmpty(condB?.severity)) return 0
  if (isNilOrEmpty(condA?.severity)) return -1
  if (isNilOrEmpty(condB?.severity)) return 1
  return getStatusCondSeverityRanking(condA.severity) - getStatusCondSeverityRanking(condB.severity)
}
