import React from 'react'
import { castBoolToStr } from 'utils/misc'
import Accordion from 'core/components/accordion/Accordion'
import FormReviewTable from 'core/components/validatedForm/review-table'

const columns = [
  {
    id: 'enableProfileAgent',
    label: 'Enable Profile Engine',
    render: (value) => castBoolToStr()(value === '-' ? false : value),
    title: 'Non-configurable add-ons',
  },
  {
    id: 'enableMetricsServer',
    label: 'Enable Metrics Server',
    render: (value) => castBoolToStr()(value === '-' ? false : value),
  },
  {
    id: 'enableKubernetesDashboard',
    label: 'Enable Kubernetes Dashboard',
    render: (value) => castBoolToStr()(value === '-' ? false : value),
  },
  {
    id: 'enableCapiAutoScaler',
    label: 'Enable Cluster Autoscaler',
    render: (value) => castBoolToStr()(value === '-' ? false : value),
  },
  {
    id: 'prometheusMonitoringEnabled',
    label: 'Prometheus Monitoring',
    title: 'Configurable add-ons',
    render: (value) => castBoolToStr()(value === '-' ? false : value),
  },
  // { id: '', label: 'Storage Class' },
  // { id: '', label: 'PVC Size (Gi)' },
  { id: 'retentionTime', label: 'Retention Time (days)' },
  {
    id: 'enableCoreDns',
    label: 'Enable Core DNS',
    render: (value) => castBoolToStr()(value === '-' ? false : value),
  },
  { id: 'dnsMemoryLimit', label: 'DNS Memory Limit', hide: (data) => data.useEntireConfigField },
  { id: 'dnsDomain', label: 'DNS Domain', hide: (data) => data.useEntireConfigField },
]

export default function CapiAddonsReview({ wizardContext, open }) {
  return (
    <Accordion open={open} id="addons" title="Add-ons">
      <FormReviewTable data={wizardContext} columns={columns} />
    </Accordion>
  )
}
