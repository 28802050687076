import React from 'react'
import DateCell from 'core/components/listTable/cells/DateCell'
import DataKeys from 'k8s/DataKeys'
import { routes } from 'core/utils/routes'
import { pick } from 'ramda'
import DetachImportedClusterDialog from 'app/plugins/infrastructure/components/importedClusters/DetachImportedClusterDialog'
import { IEditImportedClusterPageTabs } from 'app/plugins/infrastructure/components/importedClusters/model'
import { isAdmin } from 'app/plugins/infrastructure/components/common/helpers'
import useListAction from 'core/hooks/useListAction'
import { listImportedClusters } from 'app/plugins/infrastructure/components/importedClusters/new-actions'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { TablePrefsParams, listTablePrefs } from 'app/constants'
import DocumentMeta from 'core/components/DocumentMeta'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import ListContainer from 'core/containers/ListContainer'
import UUIDCell from 'app/plugins/infrastructure/components/common/cells/UUIDCell'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { importedClustersSelector } from 'app/plugins/infrastructure/components/importedClusters/selectors'
import { ArrayElement } from 'core/actions/Action'
import { GridBatchActionSpec } from 'core/elements/grid/hooks/useGridSelectableRows'
import getGridRedirectButton from 'core/elements/grid/helpers/getGridRedirectButton'
import getGridDialogButton from 'core/elements/grid/helpers/getGridDialogButton'
import AddClusterButton from 'app/plugins/infrastructure/components/clusters/AddClusterButton'
import { createGridStatusCell, StatusCellModel } from 'core/elements/grid/cells/GridStatusCell'
import EcoStatusCell from 'app/plugins/infrastructure/components/importedClusters/imported-cluster-cells/EcoStatusCell'
import { createGridLinkCell } from 'core/elements/grid/cells/GridLinkCell'
import InferActionParams from 'core/actions/InferActionParams'
import { createCidrRangesCell } from 'app/plugins/infrastructure/components/clusters/cluster-cells/CidrRangesCell'
import InfrastructureTypeCell from 'app/plugins/infrastructure/components/clusters/cluster-cells/InfrastructureTypeCell'
import PollingData from 'core/components/PollingData'

type ModelDataKey = DataKeys.ImportedClusters
type SelectorModel = ArrayElement<ReturnType<typeof importedClustersSelector>>
type ActionParams = InferActionParams<typeof listImportedClusters>
// @fixme using a type here because of https://github.com/microsoft/TypeScript/issues/15300
type Params = ActionParams
const importedClusterVariantMap = {
  Pending: 'default',
  Running: 'success',
  Terminating: 'warning',
  Failing: 'error',
  '': 'unknown',
}

const columns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'uuid',
    label: 'UUID',
    CellComponent: UUIDCell,
    display: false,
  } as GridViewColumn<SelectorModel, 'uuid'>,
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
    CellComponent: createGridLinkCell({
      routeToFn: ({ uuid }) => routes.cluster.imported.details.path({ id: uuid }),
    }),
  },
  {
    key: 'ecoInstalled',
    label: 'ECO Status',
    CellComponent: EcoStatusCell,
  } as GridViewColumn<SelectorModel, 'ecoInstalled'>,
  {
    key: 'external',
    label: 'Type',
    formatFn: (external) => (external ? 'External' : null),
  } as GridViewColumn<SelectorModel, 'external'>,
  { key: 'providerType', label: 'Infrastructure Type', CellComponent: InfrastructureTypeCell },
  {
    key: 'vpcText',
    label: 'VPC',
  },
  {
    key: 'status',
    label: 'Status',
    accessor: (cluster): string => cluster.status?.phase || '',
    CellComponent: createGridStatusCell({
      dataFn: (phase: string): StatusCellModel => {
        const variant = importedClusterVariantMap[phase] || 'unknown'
        return { variant, label: phase }
      },
    }),
  } as GridViewColumn<SelectorModel, 'status'>,
  { key: 'region', label: 'Region/Location' },
  { key: 'kubeVersion', label: 'K8s Version' },
  {
    key: 'nodeGroups',
    label: 'Node Groups/Pools',
    formatFn: (nodeGroups) => nodeGroups?.length || 0,
  } as GridViewColumn<SelectorModel, 'nodeGroups'>,
  {
    key: 'cidrRanges',
    label: 'CIDR Ranges',
    width: 'medium',
    CellComponent: createCidrRangesCell({
      dataFn: ({ containerCidr, servicesCidr }) => {
        return [
          { label: 'Container', value: containerCidr },
          { label: 'Services', value: servicesCidr },
        ]
      },
    }),
  },
  {
    key: 'creationTimestamp',
    label: 'Created',
    CellComponent: DateCell,
  } as GridViewColumn<SelectorModel, 'creationTimestamp'>,
]

const batchActions: GridBatchActionSpec<SelectorModel>[] = [
  {
    icon: 'edit',
    label: 'Edit',
    BatchActionButton: getGridRedirectButton<SelectorModel>(({ uuid }) =>
      routes.cluster.imported.edit.path({
        id: uuid,
        tab: IEditImportedClusterPageTabs.General,
      }),
    ),
  },
  {
    cond: isAdmin,
    icon: 'trash-alt',
    label: 'Detach',
    BatchActionButton: getGridDialogButton(DetachImportedClusterDialog),
  },
]

const searchTargets = ['name', 'uuid']
const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'Imported Clusters',
  listTablePrefs,
)

const defaultParams: Params = {}
const oneSecond = 1000

export default function ImportedClustersListPage() {
  const { params, getParamsUpdater } = usePrefParams(defaultParams)
  const { message, loading, reload } = useListAction(listImportedClusters, {
    params,
  })
  const data = useSelectorWithParams(importedClustersSelector, params)

  return (
    <>
      <DocumentMeta title="Imported Clusters" />
      <PollingData hidden loading={loading} onReload={reload} refreshDuration={oneSecond * 30} />
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.ImportedClusters}
        searchTargets={searchTargets}
        uniqueIdentifier="uuid"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        data={data}
        columns={columns}
        addCond={isAdmin}
        addText="Add Cluster"
        AddButtonComponent={AddClusterButton}
        getParamsUpdater={getParamsUpdater}
        batchActions={batchActions}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
