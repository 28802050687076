import React, { useMemo } from 'react'
import { pathStrOr } from 'utils/fp'
import useDataLoader from 'core/hooks/useDataLoader'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'
import { loadCloudProviderRegionDetails } from 'app/plugins/infrastructure/components/cloudProviders/actions'

interface Props {
  cloudProviderId: string
  cloudProviderRegionId: string
}

export default function AwsClusterSshKeyPicklist({
  cloudProviderId,
  cloudProviderRegionId,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  const [details, loading] = useDataLoader(loadCloudProviderRegionDetails, {
    cloudProviderId,
    cloudProviderRegionId,
  })
  const keypairs = useMemo(() => pathStrOr([], '0.keyPairs', details), [details])
  const items = useMemo(() => keypairs.map((x) => ({ label: x.KeyName, value: x.KeyName })), [
    keypairs,
  ])

  return <AsyncDropdown loading={loading} items={items} {...rest} />
}
