import React, { useMemo } from 'react'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/styles'
import Text from 'core/elements/Text'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import PieGraph, { PieDataEntry } from 'core/components/graphs/PieGraph'
import useListAction from 'core/hooks/useListAction'
import { listPods } from 'k8s/components/pods/new-actions'
import { podsSelector } from 'k8s/components/pods/selectors'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { listClusterAddons } from '../clusters/cluster-addons/new-actions'
import { clusterAddonsSelector } from '../clusters/cluster-addons/selectors'
import { getClusterAddonHealthStatus } from '../clusters/cluster-addons/helpers'
import clsx from 'clsx'
import { getCloudProviderLabel } from 'app/plugins/infrastructure/components/cloudProviders/model'

export interface StyleProps {
  hasAddonErrors: boolean
  healthStatus: string
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  container: {
    padding: 16,
    display: 'grid',
    gap: 16,
  },
  header: {
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'space-between',
    paddingLeft: 8,
  },
  star: {
    color: theme.components.badge.warning.color,
  },
  info: {
    padding: 16,
    background: theme.components.frame.accentBackground,
    display: 'grid',
    gridTemplateColumns: '1fr 1px 1fr',
    gap: 24,
  },
  divider: {
    height: '100%',
    background: theme.components.card.border,
  },
  stats: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr auto',
  },
  inline: {
    display: 'inline',
  },
  healthCircle: {
    '&:before': {
      content: '""',
      height: 12,
      width: 12,
      borderRadius: '50%',
      display: 'inline-block',
      marginRight: 6,
      backgroundColor: ({ healthStatus }) =>
        healthStatus === 'Unhealthy'
          ? theme.components.graph.fadedError
          : theme.components.graph.fadedSuccess,
    },
  },
  addonCircle: {
    '&:before': {
      content: '""',
      height: 12,
      width: 12,
      borderRadius: '50%',
      display: 'inline-block',
      marginRight: 6,
      backgroundColor: ({ hasAddonErrors }) =>
        hasAddonErrors ? theme.components.graph.fadedError : theme.components.graph.fadedSuccess,
    },
  },
}))

const noPieData = [{ name: 'unknown', value: 1, color: 'tray' }]

const nodeHealthStatus = ({ status }) => {
  if (status === 'converging') {
    return status
  }
  return status === 'disconnected' ? 'unknown' : status === 'ok' ? 'healthy' : 'unhealthy'
}

const podHealthStatus = (pod) => {
  const phase = pod?.status?.phase
  if (['Running', 'Succeeded'].includes(phase)) {
    return 'healthy'
  }
  if (phase === 'Failed') {
    return 'unhealthy'
  }
  return 'warning'
}

const getClusterHealth = (cluster) => {
  if (cluster?.clusterType === 'normal') {
    return cluster?.healthStatus === 'unhealthy' ? 'Unhealthy' : 'Healthy'
  } else if (cluster?.clusterType === 'imported') {
    if (
      cluster?.status?.phase === 'Failing' ||
      !cluster?.ecoInstalled ||
      cluster?.ecoStatus === 'errored' ||
      cluster?.ecoStatus === 'offline'
    ) {
      return 'Unhealthy'
    }
    return 'Healthy'
  } else if (cluster?.clusterType === 'capi') {
    return cluster?.phase === 'Provisioned' ? 'Healthy' : 'Unhealthy'
  }
  return 'Unhealthy'
}

const cloudProviderLabel = (cluster) => {
  if (cluster?.clusterType === 'capi') {
    return getCloudProviderLabel(cluster.infrastructureType) || cluster.infrastructureType
  } else {
    return getCloudProviderLabel(cluster.cloudProviderType) || cluster.cloudProviderType
  }
}

const clusterTypeLabel = {
  normal: 'Legacy',
  imported: 'Imported',
  capi: 'CAPI',
}

export default function DefaultClusterCard({ cluster }) {
  const { loading: loadingPods } = useListAction(listPods, { params: { clusterId: cluster?.uuid } })
  const pods = useSelectorWithParams(podsSelector, {
    clusterId: cluster?.uuid,
    useGlobalParams: false,
  })

  const { loading: loadingClusterAddons } = useListAction(listClusterAddons, {
    params: { clusterId: cluster?.uuid },
  })
  const addons = useSelectorWithParams(clusterAddonsSelector, { clusterId: cluster?.uuid })
  const numErrorAddons = useMemo(() => {
    return addons?.filter((addon) => {
      return getClusterAddonHealthStatus(addon) === 'Error'
    }).length
  }, [addons])

  const healthStatus = getClusterHealth(cluster)

  const classes = useStyles({ hasAddonErrors: !!numErrorAddons, healthStatus })

  const nodeGraphData = useMemo(() => {
    if (!cluster?.nodes?.length) {
      return noPieData
    }
    return [
      {
        name: 'healthy',
        value: cluster?.nodes?.filter((node) => nodeHealthStatus(node) === 'healthy').length || 0,
        color: 'fadedSuccess',
      },
      {
        name: 'unknown',
        value: cluster?.nodes?.filter((node) => nodeHealthStatus(node) === 'unknown').length || 0,
        color: 'fadedWarning',
      },
      {
        name: 'converging',
        value:
          cluster?.nodes?.filter((node) => nodeHealthStatus(node) === 'converging').length || 0,
        color: 'fadedDanger',
      },
      {
        name: 'unhealthy',
        value: cluster?.nodes?.filter((node) => nodeHealthStatus(node) === 'unhealthy').length || 0,
        color: 'fadedError',
      },
    ]
  }, [cluster]) as PieDataEntry[]

  const podGraphData = useMemo(() => {
    if (!pods?.length) {
      return noPieData
    }
    return [
      {
        name: 'healthy',
        value: pods?.filter((pod) => podHealthStatus(pod) === 'healthy').length || 0,
        color: 'fadedSuccess',
      },
      {
        name: 'warning',
        value: pods?.filter((pod) => podHealthStatus(pod) === 'warning').length || 0,
        color: 'fadedWarning',
      },
      {
        name: 'unhealthy',
        value: pods?.filter((pod) => podHealthStatus(pod) === 'unhealthy').length || 0,
        color: 'fadedError',
      },
    ]
  }, [pods]) as PieDataEntry[]

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div>
          <Text variant="subtitle2">{cluster.name}</Text>
          <Text variant="body2">{cloudProviderLabel(cluster)}</Text>
        </div>
        <div>
          <FontAwesomeIcon className={classes.star} size="md" solid>
            star
          </FontAwesomeIcon>
        </div>
      </div>
      <div className={classes.info}>
        <div>
          <Text variant="body2">Cluster Type</Text>
          <Text variant="caption1">{clusterTypeLabel[cluster?.clusterType]}</Text>
        </div>
        <div className={classes.divider} />
        <div>
          <Text variant="body2">Kubernetes version</Text>
          <Text variant="caption1">
            {cluster?.kubeRoleVersion || cluster?.kubeVersion || cluster?.controlPlane?.k8sVersion}
          </Text>
        </div>
      </div>
      <div className={classes.stats}>
        <div>
          <Text variant="caption1" className={classes.inline}>
            Nodes
          </Text>{' '}
          <Text variant="body2" className={classes.inline}>
            ({cluster?.nodes?.length || 0})
          </Text>
          <PieGraph data={nodeGraphData} arcWidth={14} sideLength={66} />
        </div>
        <div>
          {loadingPods ? (
            <FontAwesomeIcon spin>sync</FontAwesomeIcon>
          ) : (
            <>
              <Text variant="caption1" className={classes.inline}>
                Pods
              </Text>{' '}
              <Text variant="body2" className={classes.inline}>
                ({pods?.length})
              </Text>
              <PieGraph data={podGraphData} arcWidth={14} sideLength={66} />
            </>
          )}
        </div>
        <div>
          <div>
            <Text variant="body2" className={clsx(classes.inline, classes.healthCircle)}>
              Cluster health
            </Text>{' '}
            <Text variant="caption1" className={classes.inline}>
              {healthStatus}
            </Text>
          </div>
          <div>
            <Text variant="body2" className={clsx(classes.inline, classes.addonCircle)}>
              Add-ons health
            </Text>{' '}
            <Text variant="caption1" className={classes.inline}>
              {!!numErrorAddons ? `${numErrorAddons} issues` : 'No issues'}
            </Text>
          </div>
        </div>
      </div>
    </div>
  )
}
