import { createSelector } from '@reduxjs/toolkit'
import { filter, map, pipe, pluck, propEq } from 'ramda'
import { capitalizeString } from 'utils/misc'
import createSorter, { SortConfig } from 'core/helpers/createSorter'
import { importedClustersSelector } from 'app/plugins/infrastructure/components/importedClusters/selectors'
import { clustersSelector } from 'app/plugins/infrastructure/components/clusters/selectors'
import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { GetCloudProvider } from 'api-client/qbert.model'
import { ICloudProvidersSelector, CloudProviders } from './model'
import { calculateNodeUsages } from 'app/plugins/infrastructure/components/common/helpers'
import { selectParamsFromProps, createSharedSelector } from 'core/utils/selectorHelpers'

export const cloudProviderTypes = {
  aws: 'AWS',
  azure: 'Azure',
  gke: 'Google',
  openstack: 'OpenStack',
  local: 'BareOS',
}

export const cloudProvidersSelector = createSharedSelector(
  getDataSelector<DataKeys.CloudProviders>(DataKeys.CloudProviders),
  clustersSelector,
  importedClustersSelector,
  (cloudProviders, clusters, importedClusters) => {
    return pipe<GetCloudProvider[], GetCloudProvider[], ICloudProvidersSelector[]>(
      filter<GetCloudProvider>(({ type }) => type !== 'local'),
      map((cloudProvider) => {
        const descriptiveType =
          cloudProviderTypes[cloudProvider.type] || capitalizeString(cloudProvider.type)
        const filterCpClusters = propEq('nodePoolUuid', cloudProvider.nodePoolUuid)
        const filterCpImportedClusters = propEq('cloudProviderId', cloudProvider.uuid)
        const cpClusters = clusters.filter(filterCpClusters)
        const cpNodes = pluck('nodes', cpClusters).flat()
        const cpImportedClusters = importedClusters.filter(filterCpImportedClusters)
        const usage = calculateNodeUsages(cpNodes)
        return {
          ...cloudProvider,
          descriptiveType,
          deployedCapacity: usage,
          clusters: cpClusters,
          nodes: cpNodes,
          importedClusters: cpImportedClusters,
        }
      }),
    )(cloudProviders)
  },
)

export const makeCloudProvidersSelector = (
  defaultParams = {
    orderBy: 'created_at',
    orderDirection: 'desc',
  } as SortConfig & { type?: CloudProviders },
) => {
  const selectParams = selectParamsFromProps(defaultParams)
  return createSelector(cloudProvidersSelector, selectParams, (cloudProviders, params) => {
    const { type, orderBy, orderDirection } = params
    return pipe(
      filter((cp: ICloudProvidersSelector) => type === undefined || cp.type === type),
      createSorter({ orderBy, orderDirection }),
    )(cloudProviders)
  })
}

export const cloudProviderDetailsSelector = getDataSelector<DataKeys.CloudProviderDetails>(
  DataKeys.CloudProviderDetails,
  ['cloudProviderId'],
)

export const cloudProviderRegionDetailsSelector = getDataSelector<
  DataKeys.CloudProviderRegionDetails
>(DataKeys.CloudProviderRegionDetails, ['cloudProviderId', 'cloudProviderRegionId'])
