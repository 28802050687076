import React, { useMemo, useState } from 'react'
import { CapiVpcTypes, Subnet } from '../../../model'
import Dropdown from 'core/elements/dropdown'
import { projectAs } from 'utils/fp'
import { find, pipe, propEq, prop } from 'ramda'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

export default function SelfMangedSubnetReview({ items, isPublic }) {
  if (!items.length) return null
  const [selected, setSelected] = useState(items[0].id)
  const options = useMemo(
    () =>
      projectAs(
        { natGatewayId: 'natGatewayId', routeTableId: 'routeTableId', value: 'id' },
        items || [],
      ),
    [items],
  )
  const routeTableId = pipe(
    find<Subnet>(propEq('id', selected)),
    prop<string>('routeTableId'),
  )(items)
  const natGatewayId = pipe(
    find<Subnet>(propEq('id', selected)),
    prop<string>('natGatewayId'),
  )(items)
  const classes = useStyles()
  return (
    <table className={classes.table}>
      <tr>
        <td className={classes.rowLabel}>
          <Text variant="body2">{isPublic ? 'Public Subnets' : 'Private Subnets'}</Text>
        </td>
        <td className={classes.rowValue}>
          <Dropdown value={selected} items={options} onChange={setSelected} />
        </td>
      </tr>
      <tr>
        <td className={classes.rowLabel}>
          <Text variant="body2">Route Table ID</Text>
        </td>
        <td className={classes.rowValue}>
          <Text variant="caption1" component="span">
            {routeTableId}
          </Text>
        </td>
      </tr>
      <tr className={classes.row}>
        <td className={classes.rowLabel}>
          <Text variant="body2">NAT Gateway ID</Text>
        </td>
        <td className={classes.rowValue}>
          <Text variant="caption1" component="span">
            {natGatewayId}
          </Text>
        </td>
      </tr>
    </table>
  )
}
const useStyles = makeStyles<Theme>((theme) => ({
  table: {
    borderSpacing: '8px',
    tableLayout: 'fixed',
    verticalAlign: 'baseline',
    padding: theme.spacing(2, 0),
  },
  rowValue: {
    color: theme.components.typography.default,
  },
  rowLabel: {
    display: 'flex',
    width: 360,
  },
}))
