import { listTablePrefs, TablePrefsParams } from 'app/constants'
import DocumentMeta from 'core/components/DocumentMeta'
import { DateAndTime } from 'core/components/listTable/cells/DateCell'
import ListContainer from 'core/containers/ListContainer'
import { createGridStatusCell, StatusCellModel } from 'core/elements/grid/cells/GridStatusCell'
import { GridViewColumn } from 'core/elements/grid/Grid'
import useListAction from 'core/hooks/useListAction'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import DataKeys from 'k8s/DataKeys'
import { pick } from 'ramda'
import React from 'react'
import AddClusterButton from '../AddClusterButton'
import { createCidrRangesCell } from '../cluster-cells/CidrRangesCell'
import { deleteCapiCluster, listCapiClusters } from './actions'
import StatusesCell from './grid-cells/StatusesCell'
import { capiClustersSelector } from './selectors'
import getClusterBooleanCell from '../cluster-cells/getClusterBooleanCell'
import { ArrayElement } from 'core/actions/Action'
import InferActionParams from 'core/actions/InferActionParams'
import GridBadgesArrayCell from 'core/elements/grid/cells/GridBadgesArrayCell'
import ClusterNameCell from '../cluster-cells/ClusterNameCell'
import { CapiClusterPhases } from './model'
import PollingData from 'core/components/PollingData'
import CapiClusterDeleteDialog from './CapiClusterDeleteDialog'
import NodeGroupStatusesCell from '../cluster-cells/NodeGroupStatusesCell'

type ModelDataKey = DataKeys.CapiClusters
type SelectorModel = ArrayElement<ReturnType<typeof capiClustersSelector>>
type ActionParams = InferActionParams<typeof listCapiClusters>
type Params = ActionParams

export const clusterPhaseMap = {
  [CapiClusterPhases.Pending]: 'default',
  [CapiClusterPhases.Provisioning]: 'warning',
  [CapiClusterPhases.Provisioned]: 'success',
  [CapiClusterPhases.Deleting]: 'error',
  [CapiClusterPhases.Failed]: 'error',
}

const columns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
    CellComponent: ClusterNameCell,
  },
  {
    key: 'phase',
    label: 'Phase',
    CellComponent: createGridStatusCell({
      dataFn: (phase: string): StatusCellModel => {
        const variant = clusterPhaseMap[phase] || 'unknown'
        return { variant, label: phase }
      },
    }),
  },
  {
    key: 'status',
    label: 'Status',
    CellComponent: StatusesCell,
  },
  {
    key: 'infrastructureType',
    label: 'Infrastructure Type',
    formatFn: (value: string) => value?.toUpperCase(),
  },
  {
    key: 'controlPlane.version',
    label: 'K8s Version',
  },
  {
    key: 'creationTimestamp',
    label: 'Created',
    CellComponent: DateAndTime,
  },
  {
    key: 'nodeGroupsStatus',
    label: 'Node Groups Status',
    CellComponent: NodeGroupStatusesCell,
  },
  {
    key: 'infrastructure.region',
    label: 'Region',
  },
  // {
  //   key: 'alerts',
  //   label: 'Active Alerts',
  // },
  {
    key: 'controlPlaneEndpoint',
    label: 'ControlPlane Endpoint',
    display: false,
  },
  {
    key: 'cidrRanges',
    label: 'CIDR Ranges',
    width: 'medium',
    CellComponent: createCidrRangesCell({
      dataFn: ({ vpcCidrBlock, podsCidrBlocks, servicesCidrBlocks }) => {
        return [
          { label: 'VPC CIDR', value: vpcCidrBlock },
          { label: 'Pods', value: podsCidrBlocks },
          { label: 'Services', value: servicesCidrBlocks },
        ]
      },
    }),
    display: false,
  },
  {
    key: 'controlPlane.cni.plugin',
    label: 'CNI',
    display: false,
  },
  {
    key: 'replicas',
    label: 'ControlPlane Replica Count',
    formatFn: ({ total, ready }) => (total || ready ? `${ready || 0}/${total || 0}` : ''),
    display: false,
  },
  {
    key: 'infrastructure.cloudProviderName',
    label: 'Cloud Provider',
    display: false,
  },
  {
    key: 'allowWorkloadsOnMaster',
    label: 'Master Workloads',
    display: false,
    CellComponent: getClusterBooleanCell({
      key: 'allowWorkloadsOnMaster',
      trueLabel: 'True',
      falseLabel: 'False',
    }),
  },
  {
    key: 'privileged',
    label: 'Privileged',
    display: false,
    CellComponent: getClusterBooleanCell({
      key: 'privileged',
      trueLabel: 'True',
      falseLabel: 'False',
    }),
  },
  {
    key: 'infrastructure.availabilityZones',
    label: 'Availability Zones',
    CellComponent: GridBadgesArrayCell,
    display: false,
  },
  {
    key: 'infrastructure.sshKey',
    label: 'SSH Key',
    display: false,
  },
]

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'CAPI Clusters',
  listTablePrefs,
)
const defaultParams: Params = {}
const searchTargets = ['name', 'uuid']
const oneSecond = 1000

export default function CapiClustersListPage() {
  const { params, getParamsUpdater } = usePrefParams(defaultParams)
  const { loading, reload } = useListAction(listCapiClusters, {
    params,
  })
  const data = useSelectorWithParams(capiClustersSelector, params)

  return (
    <>
      <DocumentMeta title="CAPI Clusters" />
      <PollingData hidden loading={loading} onReload={reload} refreshDuration={oneSecond * 30} />
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.CapiClusters}
        searchTargets={searchTargets}
        uniqueIdentifier="uuid"
        loading={loading}
        loadingMessage="Loading clusters..."
        onRefresh={reload}
        data={data}
        columns={columns}
        addText="Add Cluster"
        AddButtonComponent={AddClusterButton}
        getParamsUpdater={getParamsUpdater}
        label="CAPI Clusters"
        showItemsCountInLabel
        deleteAction={deleteCapiCluster}
        DeleteDialogComponent={CapiClusterDeleteDialog}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
