import React, { useCallback, useEffect, useState } from 'react'
import DropdownField from 'core/components/validatedForm/DropdownField'
import TextField from 'core/components/validatedForm/TextField'
import AsyncDropdown from 'core/elements/dropdown/AsyncDropdown'
import { Apiserver } from '../../capi/control-plane/nodelet-control-plane/model'
import { assoc, head } from 'ramda'

const options = [
  { label: 'Default API groups and versions', value: { key: 'default', value: '' } },
  { label: 'All API groups and versions', value: { key: 'all', value: 'api/all=true' } },
  { label: 'Custom', value: { key: 'custom', value: '' } },
]

const defaultApiServer: Apiserver = {
  apiGroupsAndVersions: '',
  privileged: true,
  extraArgs: {},
}

const advancedApiConfigInfo = `Advance API Group and versions, which includes general configuration of Groups, versions, and even resources, that can be enabled/disabled at runtime with the apiserver's '--runtime-config' flag `

const CapiAwsAdvancedApiConfigFields = ({ wizardContext, setWizardContext }) => {
  useEffect(() => {
    if (!wizardContext?.apiserver) {
      const { privileged } = wizardContext
      setWizardContext({
        apiserver: assoc('privileged', privileged, defaultApiServer),
      })
    }
  }, [])
  const { apiGroupsAndVersions, apiServer } = wizardContext

  const [isCustom, setIsCustom] = useState(apiGroupsAndVersions?.key === 'custom')

  const handleChange = useCallback(
    ({ value: apiGroupsAndVersions }) => {
      const { apiserver } = wizardContext
      setWizardContext({
        apiserver: assoc(
          'apiGroupsAndVersions',
          apiGroupsAndVersions,
          apiserver || defaultApiServer,
        ),
      })
    },
    [wizardContext],
  )

  return (
    <>
      <DropdownField
        DropdownComponent={AsyncDropdown}
        id="apiGroupsAndVersions"
        label="Advanced API Configuration"
        items={options}
        tooltip={advancedApiConfigInfo}
        onChange={(selected) => {
          setIsCustom(selected.key === 'custom')
          handleChange(selected)
        }}
        required
      />

      {isCustom && (
        <TextField
          required
          value={isCustom ? apiServer?.apiGroupsAndVersions : ''}
          label="Custom API Configuration"
          info="For custom, `runtimeConfig` will be set to given key=value pairs that enable or disable built-in API"
          onChange={(value) => handleChange({ value })}
        />
      )}
    </>
  )
}

export default CapiAwsAdvancedApiConfigFields
