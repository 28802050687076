/* eslint-disable max-len */
import React, { FC, useEffect, useState } from 'react'
import FormWrapper from 'core/components/FormWrapper'
import Wizard from 'core/components/wizard/Wizard'
import useReactRouter from 'use-react-router'
import { pathJoin } from 'utils/misc'
import { k8sPrefix } from 'app/constants'
import { CloudProviders } from 'app/plugins/infrastructure/components/cloudProviders/model'
import { routes } from 'core/utils/routes'
import { ClusterCreateTypeNames, ClusterCreateTypes } from '../model'
import { getFormTitle } from '../helpers'
import DocumentMeta from 'core/components/DocumentMeta'
import { azureClusterTracking } from '../tracking'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { createCluster } from '../newActions'

const listUrl = pathJoin(k8sPrefix, 'infrastructure')

const wizardMetaFormattedNames = {
  name: 'Cluster Name',
  region: 'Region',
  kubeRoleVersion: 'K8s Version',
  numMasters: 'Masters',
  numWorkers: 'Workers',
  masterSku: 'Master SKU',
  workerSku: 'Worker SKU',
}
const wizardMetaCalloutFields = Object.keys(wizardMetaFormattedNames)

const AddAzureClusterPage = () => {
  const { history, match } = useReactRouter()
  const createType = match?.params?.type || ClusterCreateTypes.Custom
  const [activeView, setActiveView] = useState<{ ViewComponent: FC<any> }>(null)
  const [formTitle, setFormTitle] = useState<string>('')
  const [initialContext, setInitialContext] = useState(null)
  const segmentTrackingFields = {
    platform: CloudProviders.Azure,
    target: createType,
  }

  useEffect(() => {
    async function loadFile(name) {
      // Future-proof IDE refactoring by not using variables as filenames
      const view =
        name === 'one-click'
          ? await import(`./create-templates/OneClickAzureCluster`)
          : await import(`./create-templates/AdvancedAzureCluster`)
      setActiveView({ ViewComponent: view.default })
      setFormTitle(ClusterCreateTypeNames[name])
      setInitialContext(view.initialContext)
    }

    loadFile(createType)
  }, [createType])

  useEffect(() => {
    azureClusterTracking.createStarted(segmentTrackingFields)()
  }, [])

  const {
    updating: creatingAzureCluster,
    update: createAzureCluster,
    error: errorCreatingAzureCluster,
  } = useUpdateAction(createCluster)

  const handleSubmit = async (data) => {
    const { success, response: result } = await createAzureCluster({
      ...data,
      clusterType: CloudProviders.Azure,
      segmentTrackingFields,
    })

    if (success) {
      const { uuid: clusterId } = result
      history.push(routes.cluster.legacy.detail.path({ tab: 'node-health', id: clusterId }))
    }
  }
  const ViewComponent = activeView?.ViewComponent
  return (
    <FormWrapper backUrl={listUrl} loading={creatingAzureCluster}>
      <DocumentMeta title={formTitle} bodyClasses={['form-view']} />
      {!!initialContext && (
        <Wizard
          error={errorCreatingAzureCluster}
          onComplete={handleSubmit}
          context={initialContext}
          originPath={routes.cluster.add.root.path()}
          showFinishAndReviewButton
        >
          {({ wizardContext, setWizardContext, onNext }) => {
            return (
              <>
                {ViewComponent && (
                  <ViewComponent
                    wizardContext={wizardContext}
                    setWizardContext={setWizardContext}
                    onNext={onNext}
                    summaryTitle={getFormTitle(formTitle, createType)}
                    summaryIcon={CloudProviders.Azure}
                    summaryKeyOverrides={wizardMetaFormattedNames}
                    summaryCalloutFields={wizardMetaCalloutFields}
                  />
                )}
              </>
            )
          }}
        </Wizard>
      )}
    </FormWrapper>
  )
}

export default AddAzureClusterPage
