import React from 'react'
import { deauthNode } from 'app/plugins/infrastructure/components/nodes/new-actions'
import ConfirmationDialog from 'core/components/ConfirmationDialog'
import { GridDialogProps } from 'core/elements/grid/helpers/getGridDialogButton'
import { INodesSelector } from 'app/plugins/infrastructure/components/nodes/model'
import useUpdateAction from 'core/hooks/useUpdateAction'

export default function NodeDeAuthDialog({
  rows: [node],
  onClose,
}: GridDialogProps<INodesSelector>) {
  const { update: deauth, updating: deAuthorizing, error, reset } = useUpdateAction(deauthNode)

  const handleDeauthNode = async () => {
    const { success } = await deauth({ node })
    if (!success) {
      return
    }
    reset()
    onClose()
  }

  return (
    <ConfirmationDialog
      title="De-authorize node"
      error={error}
      text={
        <>
          You are about to de-authorize the node {node?.name} ({node?.primaryIp}).
          <br />
          Are you sure?
        </>
      }
      loading={deAuthorizing}
      open
      onCancel={onClose}
      onConfirm={handleDeauthNode}
    />
  )
}
