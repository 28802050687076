import React, { useContext, useMemo } from 'react'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import { ValidatedFormContext } from 'core/components/validatedForm/ValidatedForm'
import { compareVersions } from 'k8s/util/helpers'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import { coreDnsInfo } from 'app/plugins/infrastructure/components/clusters/constants'
import ToggleSwitchField from 'core/components/validatedForm/ToggleSwitchField'
import { topMiddle } from 'core/elements/menu/defaults'
import CoreDnsParamFields from './param-fields/CoreDnsParamFields'
import CoreDnsAutoscalerParamFields from './param-fields/CoreDnsAutoscalerParamFields'
import Text from 'core/elements/Text'

export const coreDnsFieldId = 'enableCoreDns'

export default function CoreDns({ wizardContext, setWizardContext, useToggleSwitch = false }) {
  const isNewK8sVersion = useMemo(() => {
    const version = wizardContext.kubeRoleVersion || wizardContext.version
    if (!version) {
      return false
    }
    return compareVersions(version, '1.20') >= 0
  }, [wizardContext.kubeRoleVersion, wizardContext.version])

  return (
    <>
      {isNewK8sVersion ? (
        useToggleSwitch ? (
          <ToggleSwitchField
            id={coreDnsFieldId}
            label="Core DNS"
            onChange={(value) => setWizardContext({ [coreDnsFieldId]: value })}
            info={coreDnsInfo}
            value={wizardContext?.[coreDnsFieldId]}
            infoPlacement={{ align: topMiddle.align, offset: topMiddle.offset }}
          />
        ) : (
          <CheckboxField
            id={coreDnsFieldId}
            label="Core DNS"
            info={coreDnsInfo}
            value={wizardContext[coreDnsFieldId]}
            onChange={(value) => setWizardContext({ [coreDnsFieldId]: value })}
          />
        )
      ) : null}
    </>
  )
}

export const CoreDnsAddonFields = ({ showHeader = true, setWizardContext, wizardContext }) => {
  const { values, setFieldValue } = useContext(ValidatedFormContext) as any
  return showHeader ? (
    <FormFieldSection title={'Core DNS Configurations'}>
      <CoreDnsParamFields
        setFieldValue={setFieldValue}
        values={values}
        setWizardContext={setWizardContext}
        wizardContext={wizardContext}
      />
    </FormFieldSection>
  ) : (
    <CoreDnsParamFields
      setFieldValue={setFieldValue}
      values={values}
      setWizardContext={setWizardContext}
      wizardContext={wizardContext}
    />
  )
}

export const dnsAutoscalerDefaults = {
  CoresPerReplica: '256',
  NodesPerReplica: '16',
  MinReplicas: '1',
  MaxReplicas: '10',
  PollPeriodSecs: '300',
}

export const CoreDnsAndAutoscalerAddonFields = ({
  showHeader = true,
  setWizardContext,
  wizardContext,
}) => {
  const { values, setFieldValue } = useContext(ValidatedFormContext) as any
  return showHeader ? (
    <>
      <FormFieldSection title={'Core DNS Configurations'}>
        <CoreDnsParamFields
          setFieldValue={setFieldValue}
          values={values}
          setWizardContext={setWizardContext}
          wizardContext={wizardContext}
        />
      </FormFieldSection>
      <FormFieldSection title="Configure Core DNS Autoscaler">
        <CoreDnsAutoscalerParamFields
          setFieldValue={setFieldValue}
          values={values}
          setWizardContext={setWizardContext}
          wizardContext={wizardContext}
        />
      </FormFieldSection>
    </>
  ) : (
    <>
      <CoreDnsParamFields
        setFieldValue={setFieldValue}
        values={values}
        setWizardContext={setWizardContext}
        wizardContext={wizardContext}
      />
      <Text variant="subtitle2">Configure Core DNS Autoscaler</Text>
      <CoreDnsAutoscalerParamFields
        setFieldValue={setFieldValue}
        values={values}
        setWizardContext={setWizardContext}
        wizardContext={wizardContext}
      />
    </>
  )
}
