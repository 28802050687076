import React, { useState } from 'react'
import TextField from 'core/components/validatedForm/TextField'
import WizardStep from 'core/components/wizard/WizardStep'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import Row from 'core/containers/Row'
import DropdownField from 'core/components/validatedForm/DropdownField'
import Dropdown from 'core/elements/dropdown'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import MultiValuesField from 'core/elements/MultiValuesField'
import CapiSpec from '../CapiSpec'
import ToggleSwitchField from 'core/components/validatedForm/ToggleSwitchField'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import Text from 'core/elements/Text'
import Divider from 'core/elements/Divider'
import { ipv4CidrValidator } from '../../../form-components/validators'
import KubernetesVersionField from '../../../form-components/KubernetesVersionField'
import awsManagedControlPlane from '../schema/eks/aws-managed-control-plane.json'
import { AwsClusterTypes } from '../../../capi/model'
import { YamlTemplate } from 'core/components/wizard/YamlTemplates'
import {
  apiServerLogsInfo,
  auditLogsInfo,
  authenticatorLogsInfo,
  controllerManagerLogsInfo,
  schedulerLogsInfo,
  publicCidrInfo,
  cidrBlockInfo,
} from '../../../capi/constants'
import TagsField from '../../../form-components/TagsField'
import { isNilOrEmpty, keyValueArrToObj } from 'utils/fp'

enum EndpoindAccessOption {
  PublicAndPrivate = 'publicAndPrivate',
  Private = 'private',
}

const endpointAccessOptions = [
  { label: 'Public + Private', value: EndpoindAccessOption.PublicAndPrivate },
  { label: 'Private', value: EndpoindAccessOption.Private },
]

const defaultEndpoint = '0.0.0.0/0'

const yamlTemplates: YamlTemplate[] = [
  {
    title: 'AWSManagedControlPlane',
    schema: awsManagedControlPlane,
    hide: (values) => values.type !== AwsClusterTypes.EKS,
  },
]
export default function EksCapiControlPlaneWizardStep({ wizardContext, setWizardContext, onNext }) {
  const classes = useStyles()
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false)

  const handleLoggingConfigChange = (field) => (value) =>
    setWizardContext({ logging: { ...(wizardContext.logging || {}), [field]: value } })

  const handleEndpointAccessChange = (value) => {
    const privateAccessOnly = value === EndpoindAccessOption.Private
    setWizardContext({
      endpointAccess: {
        private: true,
        public: !privateAccessOnly,
        publicCIDRs: privateAccessOnly ? [] : [defaultEndpoint],
      },
    })
  }

  return (
    <WizardStep
      stepId="controlPlane"
      label="Control Plane"
      yamlTemplates={yamlTemplates}

      // Todo: Implement tracking fn
      // onNext={}
    >
      <ValidatedForm
        fullWidth
        classes={{ root: classes.validatedFormContainer }}
        initialValues={wizardContext}
        triggerSubmit={onNext}
        elevated={false}
      >
        <CapiSpec title="Properties">
          <KubernetesVersionField
            id="version"
            wizardContext={wizardContext}
            setWizardContext={setWizardContext}
            showAwsEksVersions
            required
          />
          <DropdownField
            DropdownComponent={Dropdown}
            id="endpointAccess"
            label="API Server Endpoint Access"
            value={
              wizardContext.endpointAccess?.public
                ? EndpoindAccessOption.PublicAndPrivate
                : EndpoindAccessOption.Private
            }
            onChange={handleEndpointAccessChange}
            items={endpointAccessOptions}
            required
          />
          {!!wizardContext.endpointAccess?.public && (
            <MultiValuesField
              id="publicCIDRs"
              label="Public IP Access CIDR Ranges"
              items={wizardContext.endpointAccess?.publicCIDRs}
              addLabel="Add IP"
              placeholderText="Enter value..."
              onChange={(value) =>
                setWizardContext({
                  endpointAccess: { ...wizardContext.endpointAccess, publicCIDRs: value },
                })
              }
              info={publicCidrInfo}
            />
          )}
        </CapiSpec>
        <Divider />
        <CapiSpec title="Networking">
          <ToggleSwitchField
            id="disableVPCCNI"
            label="AWS VPC CNI"
            value={!wizardContext.disableVPCCNI}
            onChange={(value) => setWizardContext({ disableVPCCNI: !value })}
          />
          <TextField
            id="secondaryCidrBlock"
            label="Secondary CIDR Block (optional)"
            info={cidrBlockInfo}
            onChange={(value) => setWizardContext({ secondaryCidrBlock: value })}
            validations={[ipv4CidrValidator]}
          />
        </CapiSpec>
        <Divider />
        <CapiSpec title="Logging Configuration">
          <ToggleSwitchField
            id="apiServer"
            label="API Server"
            value={wizardContext.logging?.apiServer}
            onChange={handleLoggingConfigChange('apiServer')}
            info={apiServerLogsInfo}
          />
          <ToggleSwitchField
            id="audit"
            label="Audit"
            value={wizardContext.logging?.audit}
            onChange={handleLoggingConfigChange('audit')}
            info={auditLogsInfo}
          />
          <ToggleSwitchField
            id="authenticator"
            label="Authenticator"
            value={wizardContext.logging?.authenticator}
            onChange={handleLoggingConfigChange('authenticator')}
            info={authenticatorLogsInfo}
          />
          <ToggleSwitchField
            id="controllerManager"
            label="Controller Manager"
            value={wizardContext.logging?.controllerManager}
            onChange={handleLoggingConfigChange('controllerManager')}
            info={controllerManagerLogsInfo}
          />
          <ToggleSwitchField
            id="scheduler"
            label="Scheduler"
            value={wizardContext.logging?.scheduler}
            onChange={handleLoggingConfigChange('scheduler')}
            info={schedulerLogsInfo}
          />
        </CapiSpec>
        <Divider />
        <CapiSpec title="Tags (Additional)">
          <TagsField
            hideTitle
            onChange={(value) =>
              setWizardContext({
                additionalTags: isNilOrEmpty(value) ? null : keyValueArrToObj(value),
              })
            }
          />
        </CapiSpec>
        <Divider />
        <div className={classes.advancedOptionsLabel}>
          <Text variant="body2">Advanced Options</Text>
          <FontAwesomeIcon
            solid
            size="xs"
            className={classes.toggleCaret}
            onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}
          >
            {showAdvancedOptions ? 'chevron-up' : 'chevron-down'}
          </FontAwesomeIcon>
        </div>
        {showAdvancedOptions && (
          <CapiSpec title="Configuration">
            <ToggleSwitchField
              id="enableEncryption"
              label="Resource Encryption"
              value={wizardContext.enableEncryption}
              onChange={(value) =>
                setWizardContext(
                  value
                    ? { enableEncryption: value, encryptionConfig: {} }
                    : { enableEncryption: value, encryptionConfig: undefined },
                )
              }
            />
            {wizardContext.enableEncryption && (
              <Row>
                <TextField
                  required
                  id="encryptionProvider"
                  label="Provider"
                  placeholder="ARN or alias of the CMK in AWS KMS"
                  value={wizardContext.encryptionConfig?.provider}
                  onChange={(value) =>
                    setWizardContext({
                      encryptionConfig: { ...wizardContext.encryptionConfig, provider: value },
                    })
                  }
                />
                <TextField
                  required
                  id="resources"
                  label="Resources"
                  placeholder="secrets"
                  value={wizardContext.encryptionConfig?.resources}
                  onChange={(value) =>
                    setWizardContext({
                      encryptionConfig: { ...wizardContext.encryptionConfig, resources: [value] },
                    })
                  }
                />
              </Row>
            )}
          </CapiSpec>
        )}
      </ValidatedForm>
    </WizardStep>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  validatedFormContainer: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
  divider: {
    height: 1,
    background: theme.components.card.border,
    border: 0,
    margin: theme.spacing(3, 0),
  },
  advancedOptionsLabel: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'max-content',
    gridGap: theme.spacing(2),
    alignItems: 'center',
    marginBottom: theme.spacing(4),
  },
}))
