import React, { useEffect, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import DataKeys from 'k8s/DataKeys'
import { ArrayElement } from 'core/actions/Action'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { clusterAddonsSelector } from 'app/plugins/infrastructure/components/clusters/cluster-addons/selectors'
import { listClusterAddons } from 'app/plugins/infrastructure/components/clusters/cluster-addons/new-actions'
import { ClusterCloudPlatforms, listTablePrefs, TablePrefsParams } from 'app/constants'
import ListContainer from 'core/containers/ListContainer'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { pick, without } from 'ramda'
import {
  clusterAddonDisplayNames,
  getClusterAddonHealthStatus,
  addonTypeToNameMap,
} from 'app/plugins/infrastructure/components/clusters/cluster-addons/helpers'
import BareMetalCard from 'app/plugins/infrastructure/components/clusters/cluster-addons/cluster-details-page/BareMetalCard'
import { BadgeVariant } from 'core/elements/badge/Badge'
import AddonHealthStatusesCard from 'app/plugins/infrastructure/components/clusters/cluster-addons/cluster-details-page/AddonHealthStatusesCard'
import { createGridStatusCell } from 'core/elements/grid/cells/GridStatusCell'
import InferActionParams from 'core/actions/InferActionParams'
import { ClusterAddonType } from '../../../cluster-addons/model'
import { listClusterVersions } from '../../../cluster-addons/new-actions'
import { clusterVersionsSelector } from '../../../cluster-addons/selectors'
import { useSelector } from 'react-redux'
import AddonActionMenu from './AddonActionMenu'
import { getEksAddons } from '../../../aws/capi/CapiAddonsWizardStep'

type ModelDataKey = DataKeys.ClusterAddons
type SelectorModel = ArrayElement<ReturnType<typeof clusterAddonsSelector>>
type ActionParams = InferActionParams<typeof listClusterAddons>

const defaultParams: ActionParams = {
  clusterId: null,
}

const usePrefParams = createUsePrefParamsHook<ActionParams & TablePrefsParams>(
  'Cluster Addons',
  listTablePrefs,
)

const configurableAddons = [ClusterAddonType.Monitoring, ClusterAddonType.CoreDns]
const awsAddons = [
  ClusterAddonType.Monitoring,
  ClusterAddonType.CoreDns,
  ClusterAddonType.ProfileAgent,
  ClusterAddonType.MetricsServer,
  ClusterAddonType.KubernetesDashboard,
  ClusterAddonType.CapiAutoScaler,
]
const eksAdons = [
  ClusterAddonType.Monitoring,
  ClusterAddonType.MetricsServer,
  ClusterAddonType.KubernetesDashboard,
  ClusterAddonType.CapiAutoScaler,
]

export const getAddonHealthStatus = (addon) => {
  if (!addon) return { variant: 'unknown' as const, label: 'Unknown' }
  const addonStatus = getClusterAddonHealthStatus(addon)
  const label = addonStatus
  let status: BadgeVariant = 'success'

  if (addonStatus === 'Error') {
    status = 'error'
  }
  if (addonStatus === 'Installing') {
    status = 'warning'
  }
  if (addonStatus === 'Unknown') {
    status = 'unknown'
  }
  return { variant: status, label }
}

export default function CapiClusterAddonsPage({ cluster, addons }) {
  const classes = useStyles()
  const { params, getParamsUpdater } = usePrefParams(defaultParams)
  const isEksCluster = !!(
    cluster?.infrastructureType && cluster.infrastructureType === ClusterCloudPlatforms.EKS
  )

  const { loading, message, reload } = useListAction(listClusterAddons, {
    params: {
      clusterId: cluster?.uuid,
      clusterName: cluster?.name,
      clusterNamespace: cluster?.namespace,
    },
  })

  const { loading: loadingClusterVersions } = useListAction(listClusterVersions)
  const clusterVersions = useSelector(clusterVersionsSelector)

  const addonVersions = useMemo(() => {
    if (cluster?.version) {
      const version = cluster.version[0] === 'v' ? cluster.version.substr(1) : cluster.version
      const addOnVersions = isEksCluster
        ? getEksAddons(version, clusterVersions)
        : clusterVersions.find((clusterVersion) => clusterVersion.version === version)?.addons
      const versionMap = addOnVersions?.reduce((accum, addon) => {
        return {
          ...accum,
          [addon.name]: addon.version,
        }
      }, {})
      return versionMap
    }
    return {}
  }, [clusterVersions, cluster])

  const allAddons = useMemo(() => {
    const potentialAddons = isEksCluster ? eksAdons : awsAddons
    const installedAddons = addons.map((addon) => {
      return addon.type
    })
    const notInstalled = without(installedAddons, potentialAddons) as ClusterAddonType[]
    const notInstalledObjects = notInstalled.map((addon) => {
      return {
        type: addon,
        isConfigurable: configurableAddons.includes(addon),
        isEnabled: false,
        version: addonVersions?.[addonTypeToNameMap[addon]],
      }
    })
    return [...addons, ...notInstalledObjects] as SelectorModel[]
  }, [addons, addonVersions, isEksCluster])

  useEffect(() => {
    // Refetch the addons to get the latest statuses
    reload(true)
  }, [])

  const columns = useMemo(
    () => [
      { key: 'type', label: 'Add-on Name', formatFn: (type) => clusterAddonDisplayNames[type] },
      {
        key: 'isEnabled',
        label: 'Status',
        formatFn: (enabled) => (enabled ? 'Enabled' : 'Disabled'),
      },
      {
        key: 'isConfigurable',
        label: 'Configurable',
        formatFn: (configurable) => (configurable ? 'Yes' : 'No'),
      },
      {
        key: 'healthy',
        label: 'Health Status',
        accessor: (addon) => addon,
        CellComponent: createGridStatusCell({
          dataFn: getAddonHealthStatus,
        }),
      },
      { key: 'version', label: 'Version' },
      {
        key: 'none',
        label: '',
        width: 8,
        render: (_, addon) => (
          <AddonActionMenu
            addon={addon}
            cluster={cluster}
            addonVersions={addonVersions}
            allAddons={allAddons}
          />
        ),
      },
    ],
    [cluster, addonVersions],
  )

  return (
    <div className={classes.addons}>
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.ClusterAddons}
        searchTargets={['name', 'type']}
        uniqueIdentifier="type"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        data={allAddons}
        columns={columns}
        getParamsUpdater={getParamsUpdater}
        showBreadcrumbs={false}
        label="Cluster Add-ons"
        disableColumnOrdering
        {...pick(listTablePrefs, params)}
      />
      <div className={classes.sideContent}>
        <div>
          <AddonHealthStatusesCard addons={allAddons} />
        </div>
        <BareMetalCard />
      </div>
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  addons: {
    display: 'grid',
    gridTemplateColumns: '1fr 395px',
    gridGap: theme.spacing(4),
    paddingTop: theme.spacing(2),
  },
  sideContent: {
    display: 'grid',
    gap: theme.spacing(3),
  },
  editAddonsButton: {
    display: 'flex',
    justifyContent: 'end',
    marginBottom: theme.spacing(2),
  },
}))
