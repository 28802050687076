import React, { useContext, useMemo } from 'react'
import { compareVersions } from 'k8s/util/helpers'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import Metal3ParamFields from './param-fields/Metal3ParamFields'
import { ValidatedFormContext } from 'core/components/validatedForm/ValidatedForm'

export const metal3FieldId = 'enableMetal3'

export default function Metal3({ wizardContext, setWizardContext }) {
  const isNewK8sVersion = useMemo(() => {
    if (!wizardContext.kubeRoleVersion) {
      return false
    }
    return compareVersions(wizardContext.kubeRoleVersion, '1.21') >= 0
  }, [wizardContext.kubeRoleVersion])

  return (
    <>
      {isNewK8sVersion ? (
        <CheckboxField
          id={metal3FieldId}
          label="Metal³"
          value={wizardContext[metal3FieldId]}
          onChange={(value) => setWizardContext({ [metal3FieldId]: value })}
        />
      ) : null}
    </>
  )
}

export const Metal3AddonFields = () => {
  const { values, setFieldValue } = useContext(ValidatedFormContext) as any
  return (
    <FormFieldSection title={'Metal3 Configuration'}>
      <Metal3ParamFields
        setFieldValue={setFieldValue}
        values={values}
        showStorageClassField={false}
      />
    </FormFieldSection>
  )
}
