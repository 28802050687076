import React, { FC, useEffect } from 'react'
import { UserPreferences } from 'app/constants'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { emptyObj, isNilOrEmpty } from 'utils/fp'
import CapiInfrastructureWizardStep from '../capi/CapiInfrastructureWizardStep'
import { compareVersions } from 'k8s/util/helpers'
import { etcdBackupDefaults } from '../../cluster-addons/etcd-backup'
import CapiAddonsWizardStep from '../capi/CapiAddonsWizardStep'
import CapiWorkerNodeGroupsWizardStep from '../capi/CapiWorkerNodeGroupsWizardStep'
import EksCapiControlPlaneWizardStep from '../capi/eks/EksCapiControlPlaneWizardStep'
import CapiFinalReviewWizardStep from '../capi/CapiFinalReviewWizardStep'
import { IdentityRef } from './CapiAwsClusters'
import { CapiVpcTypes } from '../../model'
import { AwsClusterTypes } from '../../capi/model'
import { dnsAutoscalerDefaults } from '../../cluster-addons/CoreDns'

export const initialContext = {
  type: AwsClusterTypes.EKS,
  azs: [],
  subnets: [],
  clusterPodCidr: ['10.20.0.0/16'],
  clusterServiceCidr: ['10.21.0.0/16'],
  vpcCidrBlock: '10.0.0.0/16',
  identityRefKind: IdentityRef.AWSClusterStaticIdentity,
  ami: 'ubuntu',
  vpcType: CapiVpcTypes.PmkManaged,
  enableCAS: false,
  usePf9Domain: false,
  network: 'newPublic',
  networkPlugin: 'flannel',
  mtuSize: 1440,
  runtimeConfigOption: 'default',
  prometheusMonitoringEnabled: true,
  tags: [],
  networkStack: 'ipv4',
  privileged: true,
  allowWorkloadsOnMaster: false,
  useRoute53: false,
  domainId: '',
  enableProfileAgent: false,
  disableVPCCNI: false,
  logging: {
    apiServer: false,
    audit: false,
    authenticator: false,
    controllerManager: false,
    scheduler: false,
  },
  endpointAccess: {
    private: true,
    public: true,
    publicCIDRs: ['0.0.0.0/0'],
  },
  // For all of the cluster resources
  namespace: 'default',
  // For the future when clusterNamespace is selectable (for capi autoscaler addon)
  clusterNamespace: 'default',
  retentionTime: 7,
  dnsMemoryLimit: 170,
  ...etcdBackupDefaults,
  ...dnsAutoscalerDefaults,
}

interface Props {
  wizardContext: any
  setWizardContext: any
  onNext: any
}

const CapiAwsClusters: FC<Props> = ({ wizardContext, setWizardContext, onNext, ...rest }) => {
  const { prefs, fetchUserDefaults } = useScopedPreferences('defaults')
  const cloudDefaults =
    prefs?.[UserPreferences.CloudProviders]?.[wizardContext.cloudProviderId] || emptyObj

  useEffect(() => {
    // Load user defaults from the preference store
    fetchUserDefaults(UserPreferences.CloudProviders)
  }, [])

  useEffect(() => {
    if (isNilOrEmpty(cloudDefaults)) return
    setWizardContext({ ...cloudDefaults })
  }, [cloudDefaults])

  return (
    <>
      <CapiInfrastructureWizardStep
        {...rest}
        wizardContext={wizardContext}
        setWizardContext={setWizardContext}
        onNext={onNext}
        isEksCluster
      />
      <EksCapiControlPlaneWizardStep
        wizardContext={wizardContext}
        setWizardContext={setWizardContext}
        onNext={onNext}
      />
      <CapiWorkerNodeGroupsWizardStep
        wizardContext={wizardContext}
        setWizardContext={setWizardContext}
        onNext={onNext}
        isEksCluster
      />
      <CapiAddonsWizardStep
        wizardContext={wizardContext}
        setWizardContext={setWizardContext}
        onNext={onNext}
        isEksCluster
      />

      <CapiFinalReviewWizardStep wizardContext={wizardContext} />
    </>
  )
}

export default CapiAwsClusters
