import { AppSelector } from 'app/store'
import { createSelector } from '@reduxjs/toolkit'
import { either, partition, pathOr, pipe, pluck, propEq } from 'ramda'
import { arrayIfEmpty, filterIf } from 'utils/fp'
import createSorter, { SortConfig } from 'core/helpers/createSorter'
import {
  getConnectionStatus,
  getHealthStatus,
  getMasterNodesHealthStatus,
  getWorkerNodesHealthStatus,
} from 'app/plugins/infrastructure/components/clusters/ClusterStatusUtils'
import { castFuzzyBool } from 'utils/misc'
import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { ClusterSelector, ClusterTypes, IEventSelector } from './model'
import {
  getK8sDashboardLinkFromVersion,
  getScopedClusterProxyEndpoint,
} from 'app/plugins/infrastructure/components/clusters/action-helpers'
import {
  hasHealthyMasterNodes,
  hasMasterNode,
  masterlessCluster,
  prometheusCluster,
  nonPrometheusCluster,
  kubevirtCluster,
} from 'app/plugins/infrastructure/components/clusters/helpers'
import { hasPrometheusEnabled } from 'k8s/components/prometheus/helpers'
import { INodesSelector } from 'app/plugins/infrastructure/components/nodes/model'
import { calculateNodeUsages } from 'app/plugins/infrastructure/components/common/helpers'
import { allKey } from 'app/constants'
import {
  combinedHostsSelector,
  qbertEndpointSelector,
} from 'app/plugins/infrastructure/components/common/selectors'
import { nodesSelector } from 'app/plugins/infrastructure/components/nodes/selectors'
import {
  monitoringAddonsByClusterSelector,
  kubevirtAddonsByClusterSelector,
} from './cluster-addons/selectors'
import { selectParamsFromProps, createSharedSelector } from 'core/utils/selectorHelpers'
import { ClusterParams } from 'app/plugins/infrastructure/components/common/model'

export const clustersSelector: AppSelector<ClusterSelector[]> = createSharedSelector(
  getDataSelector<DataKeys.Clusters>(DataKeys.Clusters),
  monitoringAddonsByClusterSelector,
  kubevirtAddonsByClusterSelector,
  nodesSelector,
  combinedHostsSelector,
  qbertEndpointSelector,
  (
    rawClusters,
    monitoringAddonsByCluster,
    kubevirtAddonsByCluster,
    nodes: INodesSelector[],
    combinedHostsById,
    qbertEndpoint: string,
  ): ClusterSelector[] => {
    return rawClusters.map(
      (cluster): ClusterSelector => {
        const monitoringAddon = monitoringAddonsByCluster[cluster.uuid]
        const kubevirtAddon = kubevirtAddonsByCluster[cluster.uuid]
        const nodesInCluster = nodes.filter((node) => node.clusterUuid === cluster.uuid)
        const nodeIds = pluck('uuid', nodesInCluster)
        const combinedNodes = nodeIds.map((id) => combinedHostsById[id])
        const proxyEndpoint = getScopedClusterProxyEndpoint(
          qbertEndpoint.replace(/\/qbert\//, '/k8s/'),
          cluster,
        )
        const grafanaLink = `${proxyEndpoint}/namespaces/pf9-monitoring/services/http:grafana-ui:80/proxy/`
        const isPrometheusEnabled = hasPrometheusEnabled(monitoringAddon)
        // 5.4 & after, only need to check for the kubevirt addon
        const isKubevirtEnabled = kubevirtAddon?.status?.healthy === true
        const _usage = calculateNodeUsages(combinedNodes)
        const usage = {
          ..._usage,
          grafanaLink: isPrometheusEnabled ? grafanaLink : null,
        }
        const isMasterNode = (node) => node.isMaster === 1
        const [masterNodes, workerNodes] = partition(isMasterNode, nodesInCluster)
        const healthyMasterNodes = masterNodes.filter((node) => node.status === 'ok')
        const healthyWorkerNodes = workerNodes.filter((node) => node.status === 'ok')
        const masterNodesHealthStatus = getMasterNodesHealthStatus(masterNodes, healthyMasterNodes)
        const workerNodesHealthStatus = getWorkerNodesHealthStatus(workerNodes, healthyWorkerNodes)
        const connectionStatus = getConnectionStatus(cluster.taskStatus, nodesInCluster)
        const healthStatus = getHealthStatus(
          connectionStatus,
          masterNodesHealthStatus,
          workerNodesHealthStatus,
        )
        const hasMasterNode = healthyMasterNodes.length > 0
        const clusterOk = nodesInCluster.length > 0 && cluster.status === 'ok'
        const fuzzyBools = ['allowWorkloadsOnMaster', 'privileged'].reduce((accum, key) => {
          accum[key] = castFuzzyBool(cluster[key])
          return accum
        }, {})
        const version = hasMasterNode
          ? cluster.actualKubeRoleVersion || cluster.kubeRoleVersion
          : 'N/A'
        const dashboardLink = getK8sDashboardLinkFromVersion(qbertEndpoint, cluster)
        return {
          ...cluster,
          version,
          usage,
          nodes: nodesInCluster,
          masterNodes,
          workerNodes,
          healthyMasterNodes,
          healthyWorkerNodes,
          masterNodesHealthStatus,
          workerNodesHealthStatus,
          connectionStatus,
          healthStatus,
          hasMasterNode,
          endpoint: cluster.externalDnsName || cluster.masterIp,
          highlyAvailable: healthyMasterNodes.length > 2,
          links: {
            dashboard: clusterOk ? dashboardLink : null,
            // Rendering happens in <DownloadKubeConfigLink />
            kubeconfig: clusterOk ? { cluster } : null,
            // Rendering happens in <ClusterCLI />
            cli: clusterOk ? { host: qbertEndpoint?.match(/(.*?)\/qbert/)?.[1], cluster } : null,
          },
          ...fuzzyBools,
          hasVpn: castFuzzyBool(pathOr(false, ['cloudProperties', 'internalElb'], cluster)),
          hasLoadBalancer: castFuzzyBool(
            cluster.enableMetallb || pathOr(false, ['cloudProperties', 'enableLbaas'], cluster),
          ),
          etcdBackupEnabled: castFuzzyBool(
            pathOr(false, ['etcdBackup', 'isEtcdBackupEnabled'], cluster),
          ),
          hasPrometheus: isPrometheusEnabled,
          hasKubevirt: isKubevirtEnabled,
          clusterType: ClusterTypes.Normal,
          creationTimestamp: cluster.created_at,
        }
      },
    )
  },
)

const clusterSelectorDefaultParams: ClusterParams = {
  orderBy: 'created_at',
  orderDirection: 'desc',
}
export const makeParamsClustersSelector = (
  defaultParams = clusterSelectorDefaultParams,
): AppSelector<ClusterSelector[]> => {
  const selectParams = selectParamsFromProps(defaultParams)
  return createSelector(clustersSelector, selectParams, (clusters, params) => {
    const {
      masterNodeClusters,
      masterlessClusters,
      hasControlPanel,
      healthyClusters,
      prometheusClusters,
      nonPrometheusClusters,
      kubevirtClusters,
    } = params
    return pipe<
      ClusterSelector[],
      ClusterSelector[],
      ClusterSelector[],
      ClusterSelector[],
      ClusterSelector[],
      ClusterSelector[],
      ClusterSelector[],
      ClusterSelector[]
    >(
      filterIf(masterNodeClusters, hasMasterNode),
      filterIf(masterlessClusters, masterlessCluster),
      filterIf(prometheusClusters, prometheusCluster),
      filterIf(nonPrometheusClusters, nonPrometheusCluster),
      filterIf(kubevirtClusters, kubevirtCluster),
      filterIf(hasControlPanel, either(hasMasterNode, masterlessCluster)),
      filterIf(healthyClusters, hasHealthyMasterNodes),
    )(clusters)
  })
}

export const clusterEventsSelector: AppSelector<IEventSelector[]> = createSharedSelector(
  getDataSelector<DataKeys.Events>(DataKeys.Events),
  (rawEvents) => {
    return rawEvents.map((event) => {
      return {
        ...event,
        id: event?.metadata?.uid,
        name: event?.metadata?.name,
      }
    })
  },
)

export const makeClusterEventsSelector = (
  defaultParams = {} as SortConfig & { clusterId?: string; namespace?: string },
): AppSelector<IEventSelector[]> => {
  const selectParams = selectParamsFromProps(defaultParams)
  return createSelector(clusterEventsSelector, selectParams, (events, params) => {
    const { clusterId, namespace, orderBy, orderDirection } = params
    return pipe<
      IEventSelector[],
      IEventSelector[],
      IEventSelector[],
      IEventSelector[],
      IEventSelector[]
    >(
      filterIf(clusterId && clusterId !== allKey, propEq('clusterId', clusterId)),
      filterIf(namespace && namespace !== allKey, propEq('namespace', namespace)),
      createSorter({ orderBy, orderDirection }),
      arrayIfEmpty,
    )(events)
  })
}

export const supportedRoleVersionsSelector = getDataSelector<DataKeys.SupportedRoleVersions>(
  DataKeys.SupportedRoleVersions,
)
