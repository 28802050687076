import React, { useCallback, useMemo } from 'react'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/styles'
import Text from 'core/elements/Text'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import { cloudProvidersSelector } from '../cloudProviders/selectors'
import { listCloudProviders } from '../cloudProviders/new-actions'
import { listClusters } from '../clusters/newActions'
import { listImportedClusters } from '../importedClusters/new-actions'
import { CombinedClusterSelector } from '../combinedClusters/model'
import { allClustersSelector } from '../combinedClusters/selectors'
import { listNodes } from '../nodes/new-actions'
import { nodesSelector } from '../nodes/selectors'
import { allKey } from 'app/constants'
import { routes } from 'core/utils/routes'
import useReactRouter from 'use-react-router'
import IssuesTable from './IssuesTable'
import { insightIssuesSelector } from './selectors'
import DefaultClusters from './DefaultClusters'
import { listCapiClusters } from 'app/plugins/infrastructure/components/clusters/capi/actions'

const useStyles = makeStyles((theme: Theme) => ({
  dashboardPage: {
    display: 'grid',
    gap: 60,
  },
  issuesSection: {
    display: 'grid',
    gridTemplateColumns: '312px auto',
    gap: 8,
  },
  cardsBorder: {
    background: theme.components.card.border,
    border: `1px solid ${theme.components.card.border}`,
    borderRadius: 4,
  },
  infraCards: {
    display: 'grid',
    gap: 1,
  },
  infraCard: {
    background: theme.components.card.background,
    height: 92,
    padding: 16,
    cursor: 'pointer',
  },
  cardHeader: {
    display: 'grid',
    gridTemplateColumns: 'min-content auto min-content',
    gap: 10,
    alignItems: 'center',
  },
  cardIcon: {
    width: 20,
  },
  cardBody: {
    marginTop: 10,
  },
  cardCount: {
    fontWeight: 400,
  },
}))

const cardLogos = {
  cloudProviders: '/ui/images/vector-clouds.png',
  clusters: '/ui/images/vector-kubernetes.png',
  nodes: '/ui/images/vector-nodes.png',
}

const cardLinks = {
  cloudProviders: routes.cloudProviders.aws.list.path(),
  clusters: routes.cluster.overview.path(),
  nodes: routes.nodes.list.path(),
}

const InfraCard = ({ type, title, data, loading }) => {
  const classes = useStyles()
  const { history } = useReactRouter()

  const onCardClick = useCallback(() => {
    if (loading) {
      return
    }
    history.push(cardLinks[type])
  }, [history, loading])

  // Not using SimpleLink here bc don't want all the text to get underlined
  return (
    <div className={classes.infraCard} onClick={onCardClick}>
      {loading ? (
        <FontAwesomeIcon spin>sync</FontAwesomeIcon>
      ) : (
        <>
          <div className={classes.cardHeader}>
            <img className={classes.cardIcon} alt={title} src={cardLogos[type]} />
            <Text variant="caption1">{title}</Text>
            <FontAwesomeIcon size="md">angle-right</FontAwesomeIcon>
          </div>
          <div className={classes.cardBody}>
            <Text variant="h1" className={classes.cardCount}>
              {data?.length}
            </Text>
          </div>
        </>
      )}
    </div>
  )
}

export default function Insights() {
  const classes = useStyles()

  const { loading: loadingCloudProviders } = useListAction(listCloudProviders)
  const cloudProviders = useSelector(cloudProvidersSelector)

  const { loading: loadingClusters } = useListAction(listClusters)
  const { loading: loadingImportedClusters } = useListAction(listImportedClusters)
  const { loading: loadingCapiClusters } = useListAction(listCapiClusters)
  // Todo: Prob need to load capi clusters here too

  const allClusters: CombinedClusterSelector[] = useSelector(allClustersSelector)

  const { loading: loadingNodes } = useListAction(listNodes, {
    params: {
      clusterId: allKey,
    },
    requiredParams: ['clusterId'],
  })
  const nodes = useSelector(nodesSelector)

  const activeIssues = useSelector(insightIssuesSelector)

  const loadingActiveIssues =
    loadingClusters || loadingImportedClusters || loadingCapiClusters || loadingNodes

  return (
    <div className={classes.dashboardPage}>
      <div className={classes.issuesSection}>
        <div className={classes.cardsBorder}>
          <div className={classes.infraCards}>
            <InfraCard
              type="cloudProviders"
              title="Cloud Providers"
              data={cloudProviders}
              loading={loadingCloudProviders}
            />
            <InfraCard
              type="clusters"
              title="Clusters"
              data={allClusters}
              loading={loadingClusters || loadingImportedClusters}
            />
            <InfraCard type="nodes" title="Nodes" data={nodes} loading={loadingNodes} />
          </div>
        </div>
        <IssuesTable issues={activeIssues} loading={loadingActiveIssues} />
      </div>
      <DefaultClusters />
    </div>
  )
}
