import React, { useMemo, useCallback } from 'react'
import DocumentMeta from 'core/components/DocumentMeta'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { listTablePrefs } from 'app/constants'
import { listClusters } from './newActions'
import { clustersSelector } from './selectors'
import { useAppSelector } from 'app/store'
import useListAction from 'core/hooks/useListAction'
import { allClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'
import { listImportedClusters } from 'app/plugins/infrastructure/components/importedClusters/new-actions'
import { importedClustersSelector } from 'app/plugins/infrastructure/components/importedClusters/selectors'
import ListContainer from 'core/containers/ListContainer'
import AddClusterButton from './AddClusterButton'
import { pick } from 'ramda'
import { ArrayElement } from 'core/actions/Action'
import { GridViewColumn } from 'core/elements/grid/Grid'
import K8sVersionCell from './cluster-cells/K8sVersionCell'
import { DateAndTime } from 'core/components/listTable/cells/DateCell'
import ClusterNameCell from './cluster-cells/ClusterNameCell'
import ClusterTypeCell from './cluster-cells/ClusterTypeCell'
import { createGridStatusCell, StatusCellModel } from 'core/elements/grid/cells/GridStatusCell'
import ClustersOverviewHeader from './ClustersOverviewHeader'
import { ClusterTypes } from './model'
import {
  getClusterApiServerHealthStatus,
  getClusterConnectionStatus,
  getClusterHealthStatus,
} from './ClusterStatusUtils'
import { capiClustersSelector } from './capi/selectors'
import { listCapiClusters } from './capi/actions'
import { CombinedClusterSelector } from 'app/plugins/infrastructure/components/combinedClusters/model'
import InfrastructureTypeCell from './cluster-cells/InfrastructureTypeCell'
import { CapiClusterPhases } from './capi/model'
import PollingData from 'core/components/PollingData'

type SelectorModel = ArrayElement<ReturnType<typeof allClustersSelector>>

const usePrefParams = createUsePrefParamsHook('All Clusters', listTablePrefs)

const getClusterOverallHealthStatus = (cluster): StatusCellModel => {
  let healthy = null
  if (cluster.clusterType === ClusterTypes.Imported) {
    healthy = cluster.status?.phase === 'Running'
  } else if (cluster.clusterType === ClusterTypes.Normal) {
    const connectionStatus = getClusterConnectionStatus(cluster)
    const pf9ComponentsStatus = getClusterHealthStatus(cluster)
    const apiServerHealthStatus = getClusterApiServerHealthStatus(cluster)

    const connected = connectionStatus?.clusterStatus === 'ok'
    const pf9ComponentsAreHealhy = pf9ComponentsStatus?.status === 'ok'
    const apiServerIsHealthy = apiServerHealthStatus?.clusterStatus === 'ok'

    healthy = connected && pf9ComponentsAreHealhy && apiServerIsHealthy
  } else {
    healthy = cluster.phase === CapiClusterPhases.Provisioned
  }
  return healthy
    ? { variant: 'success', label: 'Healthy' }
    : { variant: 'error', label: 'Unhealthy' }
}

const columns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
    CellComponent: ClusterNameCell,
    memoizeCell: false,
  },
  {
    key: 'status',
    label: 'Overall Status',
    accessor: (cluster) => cluster,
    CellComponent: createGridStatusCell({
      dataFn: (cluster: CombinedClusterSelector): StatusCellModel => {
        return getClusterOverallHealthStatus(cluster)
      },
    }),
  },
  {
    key: 'clusterType',
    label: 'Cluster Type',
    CellComponent: ClusterTypeCell,
  },
  {
    key: 'infrastructureType',
    label: 'Infrastructure Type',
    CellComponent: InfrastructureTypeCell,
  },
  { key: 'version', label: 'K8s Version', CellComponent: K8sVersionCell },
  {
    key: 'creationTimestamp',
    label: 'Created',
    CellComponent: DateAndTime,
  },
  {
    key: 'lastOp',
    label: 'Updated',
    CellComponent: DateAndTime,
  },
]

const defaultParams = {}
const searchTargets = ['name', 'uuid']
const oneSecond = 1000

export default function ClustersOverviewPage() {
  const { params, getParamsUpdater } = usePrefParams(defaultParams)
  const { loading: loadingCapiClusters, reload: reloadCapiClusters } = useListAction(
    listCapiClusters,
    {
      params,
    },
  )
  const { loading: loadingLegacyClusters, reload: reloadLegacyClusters } = useListAction(
    listClusters,
    {
      params,
    },
  )
  const { loading: loadingImportedClusters, reload: reloadImportedClusters } = useListAction(
    listImportedClusters,
    {
      params,
    },
  )
  const allClusters = useAppSelector(allClustersSelector)
  const capiClusters = useAppSelector(capiClustersSelector)
  const legacyClusters = useAppSelector(clustersSelector)
  const importedClusters = useAppSelector(importedClustersSelector)

  const totalClusters = useMemo(() => allClusters.length, [allClusters])
  const totalCapiClusters = useMemo(() => capiClusters.length, [capiClusters])
  const totalLegacyClusters = useMemo(() => legacyClusters.length, [legacyClusters])
  const totalImportedClusters = useMemo(() => importedClusters.length, [importedClusters])

  const loading = loadingCapiClusters || loadingLegacyClusters || loadingImportedClusters

  const handleReload = useCallback(async (refetch = false, updateLoadingState = true) => {
    reloadCapiClusters(refetch, updateLoadingState)
    reloadLegacyClusters(refetch, updateLoadingState)
    reloadImportedClusters(refetch, updateLoadingState)
  }, [])

  return (
    <>
      <DocumentMeta title="Clusters Overview" />
      <PollingData
        hidden
        loading={loading}
        onReload={handleReload}
        refreshDuration={oneSecond * 30}
      />
      <ClustersOverviewHeader
        totalClusters={totalClusters}
        totalCapiClusters={totalCapiClusters}
        totalLegacyClusters={totalLegacyClusters}
        totalImportedClusters={totalImportedClusters}
      />
      <ListContainer<any, SelectorModel>
        searchTargets={searchTargets}
        uniqueIdentifier="uuid"
        loading={loading}
        loadingMessage="Loading clusters..."
        onRefresh={handleReload}
        data={allClusters}
        columns={columns}
        addText="Add Cluster"
        AddButtonComponent={AddClusterButton}
        getParamsUpdater={getParamsUpdater}
        label="All Clusters"
        showItemsCountInLabel
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
