import React, { useMemo } from 'react'
import { pathStrOr, projectAs } from 'utils/fp'
import MultiDropdown from 'core/elements/dropdown/MultiDropdown'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { listCloudProviderRegionDetails } from 'app/plugins/infrastructure/components/cloudProviders/new-actions'
import { cloudProviderRegionDetailsSelector } from 'app/plugins/infrastructure/components/cloudProviders/selectors'
import DropdownField from 'core/components/validatedForm/DropdownField'

interface Props {
  id: string
  label: string
  cloudProviderId: string
  cloudProviderRegionId: string
  values: any
  onChange: any
  required?: boolean
  disabled?: boolean
}

const CapiAwsAvailiabilityZoneChooser = ({
  cloudProviderId,
  cloudProviderRegionId,
  onChange,
  id,
  label,
  ...rest
}: Props) => {
  const { loading: loadingCloudProviderRegionDetails } = useListAction(
    listCloudProviderRegionDetails,
    {
      params: { cloudProviderId, cloudProviderRegionId },
    },
  )

  const regionDetails = useSelectorWithParams(cloudProviderRegionDetailsSelector, {
    cloudProviderId,
    cloudProviderRegionId,
  })

  const regions = useMemo(() => {
    const azs = pathStrOr([], '0.azs', regionDetails)
    return projectAs({ label: 'ZoneName', value: 'ZoneName' }, azs)
  }, [regionDetails])

  return (
    <MultiDropdown
      id={id}
      name={id}
      label={label}
      className="validatedFormInput"
      items={regions}
      onChange={onChange}
      loading={loadingCloudProviderRegionDetails}
      enableSearch
      {...rest}
    />
  )
}

export default CapiAwsAvailiabilityZoneChooser
