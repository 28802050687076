import React from 'react'
import PicklistField from 'core/components/validatedForm/DropdownField'
import CapiCloudProviderPicklist from './CapiCloudProviderPicklist'

const CapiCloudProviderField = ({
  cloudProviderType,
  setWizardContext,
  wizardContext,
  ...rest
}) => {
  const defaultOnChange = (value) =>
    setWizardContext({
      cloudProviderId: value?.uuid,
      projectId: value?.projectId,
      cloudProviderName: value?.name,
      provider: `${value?.projectId}-${value?.name}`,
      identityRefName: wizardContext?.awsAssumeRole
        ? wizardContext?.awsAccountId
        : `${value?.projectId}-${value?.name}`,
    })
  return (
    <PicklistField
      id="capiCloudProviderId"
      DropdownComponent={CapiCloudProviderPicklist}
      type={cloudProviderType}
      onChange={defaultOnChange}
      value={wizardContext?.cloudProviderId}
      required
      {...rest}
    />
  )
}

export default CapiCloudProviderField
