import React from 'react'
import { propEq } from 'ramda'
import { pathStrOr } from 'utils/fp'
import useDataLoader from 'core/hooks/useDataLoader'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'
import { loadCloudProviderRegionDetails } from 'app/plugins/infrastructure/components/cloudProviders/actions'

interface Props {
  cloudProviderId: string
  cloudProviderRegionId: string
  resourceGroup: string
}

export default function AzureVnetPicklist({
  cloudProviderId,
  cloudProviderRegionId,
  resourceGroup,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  const [details, loading] = useDataLoader(loadCloudProviderRegionDetails, {
    cloudProviderId,
    cloudProviderRegionId,
  })

  const networks = pathStrOr([], '0.virtualNetworks', details)
  const netsMatchingResourceGroup = networks.filter(propEq('resourceGroup', resourceGroup))
  const items = netsMatchingResourceGroup.map((x) => ({ label: x.name, value: x.name }))

  return <AsyncDropdown loading={loading} items={items} {...rest} />
}
