import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import useParams from 'core/hooks/useParams'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import ModalForm from 'core/elements/modal/ModalForm'
import { CapiDetailsPageTabs, ICapiClusterSelector } from '../../model'
import {
  defaultYamlTemplates,
  initialEksWorkerNodeGroup,
  initialAwsWorkerNodeGroup,
  WorkerNodeGroupsFields,
} from '../../../aws/capi/CapiWorkerNodeGroupsWizardStep'
import { YamlTemplate } from 'core/components/wizard/YamlTemplates'
import uuid from 'uuid'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import { listCapiClusters } from '../../actions'
import { capiClustersSelector } from '../../selectors'
import { ClusterCloudPlatforms } from 'app/constants'
import { cloudProvidersSelector } from 'app/plugins/infrastructure/components/cloudProviders/selectors'
import { listCloudProviders } from 'app/plugins/infrastructure/components/cloudProviders/new-actions'
import { CancellableDebounceFn, debounce } from 'utils/async'
import useCapiResourceUpdate from '../../../aws/capi/useCapiResourceUpdate'
import YamlTemplateParser from 'utils/YamlTemplateParser'
import { getVersionFromClusterVersion } from 'k8s/components/common/EksKubernetesVersionPicklist'

const defaultParams = {}

const createNewWorkerNodeGroup = (isEksCluster) => {
  return {
    ...(isEksCluster ? initialEksWorkerNodeGroup : initialAwsWorkerNodeGroup),
    id: uuid.v4(),
  }
}

const getParsedYamls = (yamlTemplates, context) =>
  yamlTemplates
    .map((yamlTemplate) => {
      const { schema, hide, setValues } = yamlTemplate
      const values = setValues ? setValues(context) : context
      if (hide && hide(values)) return null
      const templateParser = new YamlTemplateParser(schema || {})
      templateParser.setValues(values)
      return templateParser.getJson()
    })
    .filter(Boolean)

export default function AddNodeGroupPage({ existingNodeGroups = [] }) {
  const { history, match } = useReactRouter()
  const { id: clusterId } = match.params
  const debounceRef = useRef<CancellableDebounceFn>()

  const { loading } = useListAction(listCapiClusters)
  const clusters = useSelector(capiClustersSelector)
  const cluster = useMemo(
    () => clusters.find((cluster) => cluster.uuid === clusterId) || ({} as ICapiClusterSelector),
    [clusterId, clusters],
  )
  const { loading: loadingCloudProviders } = useListAction(listCloudProviders)
  const cloudProviders = useSelector(cloudProvidersSelector)
  // Start node group naming index with number above highest existing node group
  // or if no node groups, just start with 1
  const nodeGroupIndex = useMemo(() => {
    const existingIndexes = existingNodeGroups.map((nodeGroup) => {
      const nameParts = nodeGroup?.name?.split('-')
      if (!nameParts) {
        return 0
      }
      const index = parseInt(nameParts[nameParts.length - 1])
      return Number.isInteger(index) ? index : 0
    })
    const highestIndex = existingIndexes.sort().reverse()[0] || 0
    return highestIndex + 1
  }, [existingNodeGroups])
  const isEksCluster = !!(
    cluster?.infrastructureType && cluster.infrastructureType === ClusterCloudPlatforms.EKS
  )
  const [workerNodeGroups, setWorkerNodeGroups] = useState([createNewWorkerNodeGroup(isEksCluster)])
  const { params, updateParams } = useParams(defaultParams)

  const [yamlTemplates, setYamlTemplates] = useState(defaultYamlTemplates)

  useEffect(() => {
    debouncedUpdateYamlTemplates(workerNodeGroups, defaultYamlTemplates)
    updateParams({ workerNodeGroups })
  }, [workerNodeGroups])

  const debouncedUpdateYamlTemplates = useCallback(
    (workerNodeGroups, yamlTemplates: YamlTemplate[] = []) => {
      debounceRef.current?.cancel()
      debounceRef.current = debounce(() => {
        setYamlTemplates(
          workerNodeGroups // updating the custom values for each node group in yaml templates
            .map((nodeGroup) => {
              const setValues = () => nodeGroup
              return yamlTemplates.map((yamlTemplate) => {
                return { ...yamlTemplate, setValues }
              })
            })
            .flat(),
        )
      }, 500)
      debounceRef.current()
    },
    [workerNodeGroups],
  )

  useEffect(() => {
    // Cancel debouncing when component gets unmounted
    return () => debounceRef.current?.cancel()
  }, [])

  const handleChange = useCallback((updatedGroup) => {
    setWorkerNodeGroups((prevGroups) =>
      prevGroups.map((group) => (group.id === updatedGroup.id ? updatedGroup : group)),
    )
  }, [])

  useEffect(() => {
    // TODO: once clusters are being made properly, confirm that
    // the cloud provider name is accurate
    const cloudProvider = cloudProviders.find(
      (cp) => cp.name === cluster?.infrastructure?.cloudProviderName,
    )
    // Add info to params that was expected to be in wizardContext
    // in cluster creation wizard
    updateParams({
      name: cluster?.name,
      namespace: cluster?.namespace,
      sshKey: cluster?.infrastructure?.sshKey,
      cloudProviderId: cloudProvider?.uuid,
      region: cluster?.infrastructure?.region,
      version: isEksCluster ? getVersionFromClusterVersion(cluster?.version) : cluster?.version,
      type: cluster?.infrastructureType,
    })
  }, [cluster, cloudProviders, isEksCluster])

  const {
    updating: creatingNodeGroups,
    execute,
    errors: creatingNodeGroupsErrors,
  } = useCapiResourceUpdate()

  const submitForm = useCallback(async () => {
    // TODO: Check if manually reloading machineDeployments & machinePools is necessary
    const schemas = getParsedYamls(yamlTemplates, params)
    const { errors, results } = await execute(schemas)
    if (!errors?.length && results?.length) {
      history.push(
        routes.cluster.capi.details.path({ id: clusterId, tab: CapiDetailsPageTabs.NodeGroups }),
      )
    }
  }, [params, yamlTemplates, clusterId])

  const handleClose = () => {
    // Todo: ask what's the point of reset?
    // reset()
    history.push(
      routes.cluster.capi.details.path({ id: clusterId, tab: CapiDetailsPageTabs.NodeGroups }),
    )
  }

  return (
    <ModalForm
      route={routes.cluster.capi.addNodeGroups}
      title="Add Node Group"
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={creatingNodeGroups}
      error={creatingNodeGroupsErrors ? creatingNodeGroupsErrors[0] : null}
      submitTitle="Add Node Group"
    >
      <WorkerNodeGroupsFields
        workerNodeGroups={workerNodeGroups}
        wizardContext={params}
        handleChange={handleChange}
        isEksCluster={isEksCluster}
        baseIdx={nodeGroupIndex}
      />
    </ModalForm>
  )
}
