import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import {
  getEtcdBackupPayload,
  getMetalLbCidr,
} from 'app/plugins/infrastructure/components/clusters/helpers'
import { getCalicoDetectionMethod } from 'app/plugins/infrastructure/components/clusters/action-helpers'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import { pick, propEq } from 'ramda'
import { trackEvent } from 'utils/tracking'
import ListAction from 'core/actions/ListAction'
import { ClusterElement } from 'api-client/qbert.model'
import CreateAction from 'core/actions/CreateAction'
import Bugsnag from 'utils/bugsnag'
import UpdateAction from 'core/actions/UpdateAction'
import DeleteAction from 'core/actions/DeleteAction'
import store from 'app/store'
import CustomAction from 'core/actions/CustomAction'
import { cacheActions } from 'core/caching/cacheReducers'
import { ClusterSelector, UpgradeTypes } from './model'
import {
  bareOSClusterTracking,
  awsClusterTracking,
  azureClusterTracking,
} from 'app/plugins/infrastructure/components/clusters/tracking'
import { sanitizeUrl } from 'utils/misc'
import { MonitoringParams } from 'app/plugins/infrastructure/components/clusters/cluster-addons/model'
import { addUnitToParam } from 'app/plugins/infrastructure/components/clusters/cluster-addons/helpers'
import { onboardingMonitoringSetup } from 'app/constants'
import { keyValueArrToObj } from 'utils/fp'
import { compareVersions } from 'k8s/util/helpers'
import { NetworkStackTypes } from 'app/plugins/infrastructure/components/clusters/constants'

const { dispatch } = store
const { qbert } = ApiClient.getInstance()

const clusterActions = ActionsSet.make<DataKeys.Clusters>({
  uniqueIdentifier: 'uuid',
  entityName: 'Clusters',
  cacheKey: DataKeys.Clusters,
})

export default clusterActions

export type Cluster = ClusterElement & {
  csiDrivers: any[]
  progressPercent: string
  version: string
  baseUrl: string
}

// eslint-disable-next-line @typescript-eslint/require-await
export const listClusters = clusterActions.add(
  new ListAction<DataKeys.Clusters>(async () => {
    Bugsnag.leaveBreadcrumb('Attempting to get clusters')
    const clusters = await qbert.getClusters()
    return clusters.map((cluster) => {
      return {
        ...cluster,
        // @todo ask backend to return the base url with the cluster so we don't have to do this
        baseUrl: qbert.clusterBaseUrl(cluster.uuid),
      }
    })
  })
    .addDependency(DataKeys.MonitoringAddons)
    .addDependency(DataKeys.KubevirtAddons)
    .addDependency(DataKeys.Nodes)
    .addDependency(DataKeys.ResMgrHosts),
)

type ClusterType = 'aws' | 'azure' | 'local'

export const createBareOSCluster = async (data) => {
  const keysToPluck = [
    'name',
    'masterNodes',
    'allowWorkloadsOnMaster',
    'workerNodes',
    'masterVipIpv4',
    'masterVipIface',
    'externalDnsName',
    'containersCidr',
    'servicesCidr',
    'mtuSize',
    'privileged',
    'deployKubevirt',
    'deployLuigiOperator',
    'useHostname',
  ]

  const body = pick(keysToPluck, data)

  if (data.enableMetallb) {
    body.enableMetallb = data.enableMetallb
    body.metallbCidr = getMetalLbCidr(data.MetallbIpRange)
  }

  // 1. Get the nodePoolUuid from the nodePools API and look for the pool with name 'defaultPool'
  const nodePools = await qbert.getNodePools()
  body.nodePoolUuid = nodePools.find((x) => x.name === 'defaultPool').uuid

  // 2. Create the cluster
  const cluster = await createGenericCluster(body, data)

  // Placed beneath API call -- send the tracking when the request is successful
  bareOSClusterTracking.createFinished(data.segmentTrackingFields)(data)

  // 3. Attach the nodes
  const { masterNodes, workerNodes = [] } = data
  const nodes = [
    ...masterNodes.map((uuid) => ({ isMaster: true, uuid })),
    ...workerNodes.map((uuid) => ({ isMaster: false, uuid })),
  ]

  await qbert.attachNodes(cluster.uuid, nodes)

  return cluster
}

export const createAwsCluster = async (data) => {
  const keysToPluck = [
    'name',
    'region',
    'azs',
    'ami',
    'sshKey',
    'masterFlavor',
    'workerFlavor',
    'numMasters',
    'enableCAS',
    'numWorkers',
    'allowWorkloadsOnMaster',
    'numSpotWorkers',
    'spotPrice',
    'vpc',
    'isPrivate',
    'privateSubnets',
    'subnets',
    'internalElb',
    'serviceFqdn',
    'containersCidr',
    'servicesCidr',
    'networkPlugin',
    'privileged',
    'customAmi',
  ]

  const body = pick(keysToPluck, data)

  if (data.enableCAS) {
    body.numMinWorkers = data.minNumWorkers
    body.numMaxWorkers = data.maxNumWorkers
  }

  body.externalDnsName = data.usePf9Domain
    ? 'auto-generate'
    : sanitizeUrl(data.externalDnsName || '')
  body.serviceFqdn = data.usePf9Domain ? 'auto-generate' : sanitizeUrl(data.serviceFqdn || '')

  // TODO: Follow up with backend team to find out why platform9.net is not showing up in the
  // domain list and why we are hard-coding this id.
  body.domainId = data.usePf9Domain ? '/hostedzone/Z2LZB5ZNQY6JC2' : data.domainId?.domainId || ''

  // Set other fields based on what the user chose for 'networkOptions'
  if (['newPublicPrivate', 'existingPublicPrivate', 'existingPrivate'].includes(data.network)) {
    body.isPrivate = true
  }
  if (data.network === 'existingPrivate') {
    body.internalElb = true
  }

  const cluster = createGenericCluster(body, data)

  // Placed beneath API call -- send the tracking when the request is successful
  awsClusterTracking.createFinished(data.segmentTrackingFields)(data)

  return cluster
}

export const createAzureCluster = async (data) => {
  const keysToPluck = [
    'name',
    'location',
    'zones',
    'sshKey',
    'masterSku',
    'workerSku',
    'numMasters',
    'numWorkers',
    'allowWorkloadsOnMaster',
    'enableCAS',
    'assignPublicIps',
    'vnetResourceGroup',
    'vnetName',
    'masterSubnetName',
    'workerSubnetName',
    'externalDnsName',
    'serviceFqdn',
    'containersCidr',
    'servicesCidr',
    'networkPlugin',
    'privileged',
  ]

  const body = pick(keysToPluck, data)

  if (data.enableCAS) {
    body.numMinWorkers = data.minNumWorkers
    body.numMaxWorkers = data.maxNumWorkers
  }

  if (data.useAllAvailabilityZones) {
    body.zones = []
  }
  const cluster = createGenericCluster(body, data)

  // Placed beneath API call -- send the tracking when the request is successful
  azureClusterTracking.createFinished(data.segmentTrackingFields)(data)

  return cluster
}

export const createCluster = clusterActions.add(
  new CreateAction<DataKeys.Clusters, { clusterType: ClusterType }>(async (params) => {
    if (params.clusterType === 'aws') {
      return createAwsCluster(params)
    }
    if (params.clusterType === 'azure') {
      return createAzureCluster(params)
    }
    if (params.clusterType === 'local') {
      return createBareOSCluster(params)
    }
    return null
  }),
)

const createGenericCluster = async (body, data) => {
  const { cloudProviderId } = data

  if (!body.nodePoolUuid && !!cloudProviderId) {
    // Get the nodePoolUuid from the cloudProviderId.
    // There is a 1-to-1 mapping between cloudProviderId and nodePoolUuuid right now.
    const cloudProviders = await qbert.getCloudProviders()
    body.nodePoolUuid = cloudProviders.find(propEq('uuid', cloudProviderId)).nodePoolUuid
  }

  if (data.enableProfileAgent) {
    body.enableProfileAgent = data.enableProfileAgent
  }

  if (data.httpProxy) {
    body.httpProxy = data.httpProxy
  }

  if (data.kubeRoleVersion) {
    body.kubeRoleVersion = data.kubeRoleVersion
  }

  if (data.apiServerFlags) {
    body.apiServerFlags = data.apiServerFlags?.split(',')
  }

  if (data.controllerManagerFlags) {
    body.controllerManagerFlags = data.controllerManagerFlags?.split(',')
  }

  if (data.schedulerFlags) {
    body.schedulerFlags = data.schedulerFlags?.split(',')
  }

  if (data.cpuManagerPolicy) {
    body.cpuManagerPolicy = data.cpuManagerPolicy
  }

  if (data.topologyManagerPolicy) {
    body.topologyManagerPolicy = data.topologyManagerPolicy
  }
  if (data.reservedCPUs) {
    body.reservedCPUs = data.reservedCPUs
  }
  // Calico is required when ipv6 is selected
  if (data.networkStack === NetworkStackTypes.IPv6) {
    body.calicoIPv6PoolCidr = body.containersCidr
    body.ipv6 = true
    body.calicoIPv6DetectionMethod = getCalicoDetectionMethod(data)
    body.calicoIPv6PoolBlockSize = data.calicoBlockSize
    body.calicoIPv6PoolNatOutgoing = data.calicoNatOutgoing
  }

  if (data.networkPlugin === 'calico') {
    body.mtuSize = data.mtuSize
    body.calicoIpIpMode = data.calicoIpIpMode

    if (data.networkStack === NetworkStackTypes.IPv4) {
      body.calicoNatOutgoing = data.calicoNatOutgoing
      body.calicoV4BlockSize = data.calicoBlockSize
      body.calicoIPv4DetectionMethod = getCalicoDetectionMethod(data)
    }
  }
  body.networkPlugin = data.networkPlugin
  body.runtimeConfig = {
    default: '',
    all: 'api/all=true',
    custom: data.customRuntimeConfig,
  }[data.runtimeConfigOption]

  if (data.containerRuntime) {
    body.containerRuntime = data.containerRuntime
  }

  // This is currently supported by all cloud providers except GCP (which we
  // don't have yet anyway)
  body.etcdBackup = getEtcdBackupPayload('etcdBackup', data)

  const monitoringIsManagedByAddonManager = compareVersions(data.kubeRoleVersion, '1.21') >= 0
  if (data.prometheusMonitoringEnabled && monitoringIsManagedByAddonManager) {
    const params = pick(
      [MonitoringParams.RetentionTime, MonitoringParams.StorageClassName, MonitoringParams.PvcSize],
      data,
    )
    body.monitoring = {}
    Object.entries(params).forEach(
      ([key, value]) => (body.monitoring[key] = addUnitToParam(key, value)),
    )
  }
  body.tags = keyValueArrToObj(data.tags || [])

  const createResponse = await qbert.createCluster(body)
  const uuid = createResponse.uuid

  if (data.prometheusMonitoringEnabled) {
    localStorage.setItem(onboardingMonitoringSetup, 'true')
  }

  // The POST call only returns the `uuid` and that's it.
  // We need to perform a GET afterwards and return that to add to the cache.
  return qbert.getClusterDetails(uuid)
}

interface UpgradeClusterBodyType {
  // batchUpgradeNodes?: string[]
  batchUpgradePercent?: number
  containerRuntime?: string
}

export const upgradeNodesCluster = async (data) => {
  const body: UpgradeClusterBodyType = {}

  if (data?.percentageClusterUpgrade && data.batchUpgradePercent) {
    body.batchUpgradePercent = data.batchUpgradePercent
  }

  if (data.upgradeType === UpgradeTypes.Minor) {
    body.containerRuntime = data.containerRuntime
  }

  const upgradedCluster = await qbert.upgradeClusterNodes(data.clusterId, data.upgradeType, body)
  trackEvent('Upgrade Cluster', { clusterUuid: data.clusterId })

  return upgradedCluster
}

export const updateCluster = clusterActions.add(
  new UpdateAction<
    DataKeys.Clusters,
    {
      uuid: string
      name: string
      tags: string
      numWorkers: number
      numMinWorkers: number
      numMaxWorkers: number
      etcdBackup: any
    }
  >(async (params) => {
    const { uuid } = params
    const updateableParams = 'name tags numWorkers numMinWorkers numMaxWorkers'.split(' ')

    const body = pick(updateableParams, params)
    if (params.etcdBackup) {
      body.etcdBackup = getEtcdBackupPayload('etcdBackup', params)
    }

    const result = await qbert.updateCluster(uuid, body)
    trackEvent('Update Cluster', { uuid })

    return result
  }),
)

export const deleteCluster = clusterActions.add(
  new DeleteAction<DataKeys.Clusters, { uuid: string }>(async ({ uuid }) => {
    await qbert.deleteCluster(uuid)
    // Delete cluster Segment tracking is done in ClusterDeleteDialog.tsx because that code
    // has more context about the cluster name, etc.

    // Refresh clusters since combinedHosts will still
    // have references to the deleted cluster.
    // loadCombinedHosts.invalidateCache()
  }),
)

export const scaleCluster = clusterActions.add(
  new CustomAction<
    DataKeys.Clusters,
    {
      cluster: Cluster
      numWorkers: number
      numSpotWorkers: number
      spotPrice: number
    },
    {
      numWorkers: number
    }
  >(
    'scaleCluster',
    async ({ cluster, numSpotWorkers, numWorkers, spotPrice }) => {
      const body = {
        numWorkers,
        numSpotWorkers: numSpotWorkers || 0,
        spotPrice: spotPrice || 0.001,
        spotWorkerFlavor: cluster.cloudProperties.workerFlavor,
      }
      await qbert.updateCluster(cluster.uuid, body)
      trackEvent('Scale Cluster', { clusterUuid: cluster.uuid, numSpotWorkers, numWorkers })

      return {
        numWorkers,
      }
    },
    (result, { cluster: { uuid } }) => {
      // Update the cluster in the cache
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'uuid',
          cacheKey: DataKeys.Clusters,
          params: { uuid },
          item: result,
        }),
      )
    },
  ),
)

export const upgradeClusterNodes = clusterActions.add(
  new CustomAction<
    DataKeys.Clusters,
    {
      clusterId: string
      upgradeType: string
      batchUpgradePercent: number
      percentageClusterUpgrade: boolean
      sequentialClusterUpgrade: boolean
    }
  >(
    'upgradeCluster',
    async (params) => {
      Bugsnag.leaveBreadcrumb('Attempting to upgrade cluster', {
        clusterId: params.clusterId,
      })
      return upgradeNodesCluster(params)
    },
    (result, { clusterId: uuid }) => {
      // Update the cluster in the cache
      dispatch(
        cacheActions.updateItem({
          uniqueIdentifier: 'uuid',
          cacheKey: DataKeys.Clusters,
          params: { uuid },
          item: result,
        }),
      )
    },
  ),
)

export const attachNodes = clusterActions.add(
  new CustomAction<
    DataKeys.Clusters,
    { cluster: ClusterSelector; nodes: { uuid: string; isMaster: boolean }[] }
  >('attachNodes', async ({ cluster, nodes }) => {
    Bugsnag.leaveBreadcrumb('Attempting to attach nodes to cluster', {
      clusterId: cluster.uuid,
      numNodes: (nodes || []).length,
    })
    await qbert.attachNodes(cluster.uuid, nodes)
    trackEvent('Cluster Attach Nodes', {
      numNodes: (nodes || []).length,
      clusterUuid: cluster.uuid,
    })
  }),
)

export const detachNodes = clusterActions.add(
  new CustomAction<DataKeys.Clusters, { cluster: ClusterSelector; nodes: string[] }>(
    'detachNodes',
    async ({ cluster, nodes }) => {
      Bugsnag.leaveBreadcrumb('Attempting to detach nodes from cluster', {
        clusterId: cluster.uuid,
        numNodes: (nodes || []).length,
      })
      await qbert.detachNodes(cluster.uuid, nodes)
      trackEvent('Cluster Detach Nodes', {
        numNodes: (nodes || []).length,
        clusterUuid: cluster.uuid,
      })
    },
  ),
)

const supportedRoleVersionActions = ActionsSet.make<DataKeys.SupportedRoleVersions>({
  uniqueIdentifier: 'uuid',
  entityName: entityNamesByKey.SupportedRoleVersions,
  cacheKey: DataKeys.SupportedRoleVersions,
  cache: false,
})

export const listSupportedRoleVersions = supportedRoleVersionActions.add(
  new ListAction<DataKeys.SupportedRoleVersions>(async () => {
    const response = await qbert.getK8sSupportedRoleVersions()
    return response.roles
  }),
)

const clusterEventsActions = ActionsSet.make<DataKeys.Events>({
  uniqueIdentifier: 'id',
  indexBy: 'clusterId',
  entityName: entityNamesByKey.Events,
  cacheKey: DataKeys.Events,
  cache: false,
})

export const listClusterEvents = clusterEventsActions.add(
  new ListAction<DataKeys.Events, { clusterId: string }>(async ({ clusterId }) => {
    Bugsnag.leaveBreadcrumb('Attempting to get cluster events', { clusterId })
    try {
      return qbert.getClusterEvents(clusterId)
    } catch {
      return []
    }
  }),
)

export const deleteClusterEvent = clusterEventsActions.add(
  new DeleteAction<DataKeys.Events, { clusterId: string; namespace: string; name: string }>(
    async ({ clusterId, namespace, name }) => {
      Bugsnag.leaveBreadcrumb('Attempting to delete cluster event', {
        clusterId,
        namespace,
        name,
      })
      trackEvent('Delete Cluster Event', { clusterId, namespace, name })
      await qbert.deleteClusterEvent(clusterId, namespace, name)
    },
  ),
)
