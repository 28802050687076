import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import Bugsnag from 'utils/bugsnag'
import ListAction from 'core/actions/ListAction'
import DeleteAction from 'core/actions/DeleteAction'
import UpdateAction from 'core/actions/UpdateAction'

const { sunpike } = ApiClient.getInstance()

export const capiClusterActions = ActionsSet.make<DataKeys.CapiClusters>({
  uniqueIdentifier: 'metadata.uid',
  entityName: entityNamesByKey[DataKeys.CapiClusters],
  cacheKey: DataKeys.CapiClusters,
})

export const listCapiClusters = capiClusterActions.add(
  new ListAction<DataKeys.CapiClusters>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get CAPI clusters', params)
    return sunpike.getCapiClusters()
  })
    .addDependency(DataKeys.CapiAwsClusters)
    .addDependency(DataKeys.NodeletControlPlanes)
    .addDependency(DataKeys.AwsManagedControlPlanes)
    .addDependency(DataKeys.AwsMachineTemplates)
    .addDependency(DataKeys.MachineDeployments)
    .addDependency(DataKeys.MachinePools)
    .addDependency(DataKeys.AwsMachinePools)
    .addDependency(DataKeys.AwsManagedMachinePools)
    .addDependency(DataKeys.NodeletConfigs)
    .addDependency(DataKeys.NodeletConfigTemplates)
    .addDependency(DataKeys.EksConfigs)
    .addDependency(DataKeys.EksConfigTemplates)
    .addDependency(DataKeys.AwsClusterRoleIdentities)
    .addDependency(DataKeys.AwsClusterStaticIdentities)
    .addDependency(DataKeys.CloudProviders)
    .addDependency(DataKeys.CapiHosts)
    .addDependency(DataKeys.ResMgrHosts),
  // .addDependency(DataKeys.ClusterAddons),
)

export const updateCapiCluster = capiClusterActions.add(
  new UpdateAction<DataKeys.CapiClusters, { namespace: string; name: string; body: unknown }>(
    async ({ namespace, name, body }) => {
      Bugsnag.leaveBreadcrumb('Attempting to update CAPI cluster', { namespace, name, body })
      return sunpike.updateCapiCluster(namespace, name, body)
    },
  ),
)

export const deleteCapiCluster = capiClusterActions.add(
  new DeleteAction<DataKeys.CapiClusters, { namespace: string; name: string }>(
    async ({ namespace, name }) => {
      Bugsnag.leaveBreadcrumb('Attempting to delete CAPI cluster', { namespace, name })
      await sunpike.deleteCapiCluster(namespace, name)
    },
  ),
)

export const listAwsAmis = new ListAction<DataKeys.AwsAmis, { namespace: string }>(
  async ({ namespace }) => {
    Bugsnag.leaveBreadcrumb('Attempting to get AWS AMI IDs')
    return sunpike.getAwsAmis(namespace)
  },
  {
    cache: false,
    entityName: entityNamesByKey[DataKeys.AwsAmis],
    cacheKey: DataKeys.AwsAmis,
  },
)
