import React, { useState } from 'react'
import Text from 'core/elements/Text'
import TextField from 'core/components/validatedForm/TextField'
import DropdownField from 'core/components/validatedForm/DropdownField'
import Dropdown from 'core/elements/dropdown'
import Row from 'core/containers/Row'
import { merge } from 'ramda'
import { RollingUpdate } from '../../capi/machine-deployment/model'
import { validators } from 'core/utils/fieldValidators'

export const enum NodeUpdateStrategyTypes {
  Count = 'count',
  Percentage = 'percentage',
}
const rollingUpdateTypeOptions = [
  { label: 'Count', value: NodeUpdateStrategyTypes.Count },
  { label: 'Percentage', value: NodeUpdateStrategyTypes.Percentage },
]

export default function CapiMachineDeploymentNodeUpdateStrategies({ workerNodeGroup, onChange }) {
  const [maxSurgeType, setMaxSurgeType] = useState(NodeUpdateStrategyTypes.Count)
  const [maxUnavailableType, setMaxUnavailableType] = useState(NodeUpdateStrategyTypes.Count)

  const { rollingUpdate } = workerNodeGroup

  return (
    <>
      <Text variant="caption1">Nodes Update Strategy</Text>
      <Row minItemWidth="300">
        <DropdownField
          DropdownComponent={Dropdown}
          id="nodesUpdateStrategy"
          label="Strategy"
          value="rollingUpdate"
          disabled
          items={[{ label: 'Rolling Update', value: 'rollingUpdate' }]}
        />
      </Row>
      <Row minItemWidth="300" gap={24}>
        <DropdownField
          DropdownComponent={Dropdown}
          id="maxSurgeType"
          label="Max Surge - Type"
          value={maxSurgeType}
          items={rollingUpdateTypeOptions}
          onChange={(value) => {
            setMaxSurgeType(value),
              onChange('rollingUpdate')(
                merge(rollingUpdate, {
                  // Rolling Update 'count' should be integer and 'percentage' should be string as per the crd
                  maxSurge:
                    value === NodeUpdateStrategyTypes.Count
                      ? Number(rollingUpdate?.maxSurge)
                      : rollingUpdate?.maxSurge?.toString(),
                }),
              )
          }}
        />
        <TextField
          id="maxSurgeValue"
          label="Max Surge - value"
          icon={maxSurgeType === NodeUpdateStrategyTypes.Percentage ? 'percent' : null}
          value={rollingUpdate?.maxSurge}
          validations={[validators.rangeValue(1, 100)]}
          onChange={(value) => {
            onChange('rollingUpdate')(
              merge(rollingUpdate, {
                maxSurge:
                  maxSurgeType === NodeUpdateStrategyTypes.Count ? Number(value) : value.toString(),
              }),
            )
          }}
          required
        />
      </Row>
      <Row minItemWidth="300" gap={24}>
        <DropdownField
          DropdownComponent={Dropdown}
          id="maxUnavailableType"
          label="Max Unavailable - Type"
          value={maxUnavailableType}
          items={rollingUpdateTypeOptions}
          onChange={(value) => {
            setMaxUnavailableType(value),
              onChange('rollingUpdate')(
                merge(rollingUpdate, {
                  maxUnavailable:
                    value === NodeUpdateStrategyTypes.Count
                      ? Number(rollingUpdate?.maxUnavailable)
                      : rollingUpdate?.maxUnavailable?.toString(),
                }),
              )
          }}
        />
        <TextField
          id="maxUnavailableValue"
          label="Max Unavailable - value"
          icon={maxUnavailableType === NodeUpdateStrategyTypes.Percentage ? 'percent' : null}
          validations={[validators.rangeValue(0, 100)]}
          value={rollingUpdate?.maxUnavailable}
          onChange={(value) => {
            onChange('rollingUpdate')(
              merge(rollingUpdate, {
                maxUnavailable:
                  maxUnavailableType === NodeUpdateStrategyTypes.Count
                    ? Number(value)
                    : value.toString(),
              }),
            )
          }}
          required
        />
      </Row>
    </>
  )
}
