import { GridCellProps } from 'core/elements/grid/hooks/useGridRows'
import {
  ClusterApiServerHealthStatus,
  ClusterConnectionStatus,
  ClusterHealthStatus,
} from 'app/plugins/infrastructure/components/clusters/ClusterStatusSpan'
import React from 'react'
import { CombinedClusterSelector } from 'app/plugins/infrastructure/components/combinedClusters/model'
import { ClusterTypes } from '../model'

export default function HealthStatusCell({
  item: cluster,
}: GridCellProps<CombinedClusterSelector>) {
  if (cluster?.clusterType === ClusterTypes.Capi) return null
  const showIconStatus = cluster.connectionStatus === 'converging' || cluster.status === 'pending'
  return (
    <div>
      <ClusterConnectionStatus
        iconStatus={cluster.connectionStatus === 'converging'}
        cluster={cluster}
        label="Connection Status"
      />
      <ClusterHealthStatus iconStatus={showIconStatus} cluster={cluster} label="PF9 Components" />
      <ClusterApiServerHealthStatus cluster={cluster} label="API Server Health" />
    </div>
  )
}
