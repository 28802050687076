import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Progress from 'core/components/progress/Progress'
import React, { useState, useMemo, useEffect, useCallback } from 'react'
import StatusConditionsCard from '../StatusConditionsCard'
import Button from 'core/elements/button'
import YamlCard from '../YamlCard'
import { customValidator, requiredValidator } from 'core/utils/fieldValidators'
import { convertResourcesToYamls, downloadYamlsZipFile, Yamls, moizedYamlLoad } from '../helpers'
import DocumentMeta from 'core/components/DocumentMeta'
import { useDispatch } from 'react-redux'
import useReactRouter from 'use-react-router'
import { clientActions } from 'core/client/clientReducers'
import { allNodeGroupsSelector } from '../../machine-deployment/selectors'
import { useAppSelector } from 'app/store'
import CapiClustersNodeGroupsHeader from './CapiClustersNodeGroupsHeader'
import FormReviewTable from 'core/components/validatedForm/review-table'
import { calculateAge, castBoolToStr } from 'utils/misc'
import Card from 'core/elements/card'
import Text from 'core/elements/Text'
import {
  renderResourceLabels,
  renderResourceAnnotations,
  renderResourceTaints,
} from 'k8s/components/common/entity/labels-and-annotations/helpers'
import Spacer from 'core/elements/Spacer'
import { CapacityType } from '../../aws-managed-machine-pool/model'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { cleanResource } from '../../../aws/capi/capiUtils'
import { listMachineDeployments, updateMachineDeployment } from '../../machine-deployment/actions'
import {
  createAwsMachineTemplate,
  deleteAwsMachineTemplate,
} from '../../aws-machine-templates/actions'
import {
  updateEksConfig,
  updateEksConfigTemplate,
  updateNodeletConfig,
  updateNodeletConfigTemplate,
} from '../../configs/actions'
import { listMachinePools, updateMachinePool } from '../../machine-pool/actions'
import { updateAwsMachinePool } from '../../aws-machine-pool/actions'
import { updateAwsManagedMachinePool } from '../../aws-managed-machine-pool/actions'
import { ConfigTypes, NodeGroupTypes } from '../../model'
import EditMachinePoolModal from './EditMachinePoolModal'
import { switchCase } from 'utils/fp'
import { listCapiClusters } from '../../actions'
import { capiClustersSelector } from '../../selectors'
import useListAction from 'core/hooks/useListAction'
import EditAwsManagedMachinePoolModal from './EditAwsManagedMachinePoolModal'
import EditMachineDeploymentModal from './EditMachineDeploymentModal'

const yamlValidations = [
  requiredValidator,
  customValidator((yaml) => {
    try {
      moizedYamlLoad(yaml)
      return true
    } catch (err) {
      return false
    }
  }, 'Provided YAML code is invalid'),
  customValidator((yaml) => {
    const body = moizedYamlLoad(yaml)
    return !!body?.metadata?.name
  }, 'metadata.name must be set'),
]

const Labels = ({ value }) => (
  <>
    <Text variant="caption1">Labels</Text>
    <Spacer></Spacer>
    {renderResourceLabels({ separator: '=', ellipsisAt: 80 })(value)}
    <Spacer height={24}></Spacer>
  </>
)
const Annotations = ({ value }) => (
  <>
    <Text variant="caption1">Annotations</Text>
    <Spacer></Spacer>
    {renderResourceAnnotations({ values: value, ellipsisAt: 80 })}
    <Spacer height={24}></Spacer>
  </>
)
const Taints = ({ value }) => (
  <>
    <Text variant="caption1">Taints</Text>
    <Spacer></Spacer>
    {renderResourceTaints(value)}
    <Spacer height={24}></Spacer>
  </>
)

const columns = [
  { id: 'name', label: 'Node Group Name' },
  { id: 'phase', label: 'Phase' },
  { id: 'type', label: 'Type' },
  { id: 'clusterName', label: 'Cluster' },
  { id: 'k8sVersion', label: 'Kubernetes Version' },
  { id: 'creationTimestamp', label: 'Age', render: (value) => calculateAge(value) },
  {
    id: 'replicas',
    label: 'Node Count',
  },
  {
    id: 'spec.strategy.type',
    label: 'Node Update Strategy',
    insertDivider: true,
    hide: (values) => !values?.machineTemplate,
  },
  {
    id: 'spec.strategy.rollingUpdate.maxSurge',
    label: 'Max Surge',
    render: (value) => `${value} (No.) `,
    hide: (values) => !values?.machineTemplate,
  },
  {
    id: 'spec.strategy.rollingUpdate.maxUnavailable',
    label: 'Max Unavailable',
    render: (value) => `${value} (%) `,
    hide: (values) => !values?.machineTemplate,
  },
  {
    id: 'machineTemplate.instanceType',
    label: 'Instance Type',
    header: 'InfrastructureRef ',
    subHeader: 'AWS Machine Template',
    insertDivider: true,
    hide: (values) => !values?.machineTemplate,
  },
  {
    id: 'machineTemplate.amiId',
    label: 'Operating System',
    hide: (values) => !values?.machineTemplate,
  },
  {
    id: 'machineTemplate.publicIP',
    label: 'Make Nodes Public',
    render: (value) => (value === '-' ? 'False' : 'True'),
    hide: (values) => !values?.machineTemplate,
  },
  {
    id: 'machineTemplate.spotInstances',
    label: 'Spot Instances',
    render: castBoolToStr(),
    insertDivider: true,
    hide: (values) => !values?.machineTemplate,
  },
  {
    id: 'machineTemplate.spotInstanceMaxPrice',
    label: 'Spot Instance Max Price',
    hide: (values) => !values?.machineTemplate?.spotInstances,
  },
  {
    id: 'autoscalingEnabled',
    label: 'Auto Scaling',
    render: (value) => (value ? 'Enabled' : 'Disabled'),
    insertDivider: true,
    hide: (values) => !values?.machineTemplate,
  },
  { id: 'minCasSize', label: 'Min Nodes', hide: (values) => !values?.autoscalingEnabled },
  { id: 'maxCasSize', label: 'Max Nodes', hide: (values) => !values?.autoscalingEnabled },
  {
    id: 'awsMachinePool.instanceType',
    label: 'Instance Type',
    header: 'InfrastructureRef ',
    subHeader: 'AWS Machine Pool',
    insertDivider: true,
    hide: (values) => !values?.awsMachinePool,
  },
  {
    id: 'awsMachinePool.availabilityZones',
    label: 'Availability Zones',
    hide: (values) => !values?.awsMachinePool,
  },
  {
    id: 'awsMachinePool.amiId',
    label: 'Operating System',
    hide: (values) => !values?.awsMachinePool,
  },
  {
    id: 'awsMachinePool.mixedInstances',
    label: 'Mixed  Instances',
    render: castBoolToStr(),
    insertDivider: true,
    hide: (values) => !values?.awsMachinePool,
  },
  {
    id: 'awsMachinePool.mixedInstancesPolicy.instancesDistribution.onDemandBaseCapacity',
    label: 'Node On Demand Base Capacity',
    hide: (values) => !values?.awsMachinePool?.mixedInstances,
  },
  {
    id:
      'awsMachinePool.mixedInstancesPolicy.instancesDistribution.onDemandPercentageAboveBaseCapacity',
    label: 'Node On Demand % Above  Base',
    hide: (values) => !values?.awsMachinePool?.mixedInstancesPolicy,
  },
  {
    id: 'awsMachinePool.mixedInstancesPolicy.instancesDistribution.spotAllocationStrategy',
    label: 'Spot Instance Allocation Strategy',
    hide: (values) => !values?.awsMachinePool?.mixedInstances,
  },
  {
    id: 'awsManagedMachinePool.instanceType',
    label: 'Instance Type',
    header: 'InfrastructureRef ',
    subHeader: 'AWS Managed Machine Pool',
    insertDivider: true,
    hide: (values) => !values?.awsManagedMachinePool,
  },
  {
    id: 'awsManagedMachinePool.availabilityZones',
    label: 'Availability Zones',
    hide: (values) => !values?.awsManagedMachinePool,
  },
  {
    id: 'awsManagedMachinePool.amiType',
    label: 'Operating System AMI Type',
    hide: (values) => !values?.awsManagedMachinePool,
  },
  {
    id: 'awsManagedMachinePool.capacityType',
    label: 'Spot Instances',
    render: (value) => castBoolToStr()(value === CapacityType.spot),
    hide: (values) => !values?.awsManagedMachinePool,
  },
  {
    id: 'awsManagedMachinePool.updateConfig.maxUnavailable',
    title: 'Node Update Strategy',
    label: 'Max Unavailable',
    hide: (values) => !values?.awsManagedMachinePool?.updateConfig?.maxUnavailable,
  },
  {
    id: 'awsManagedMachinePool.updateConfig.maxUnavailablePrecentage',
    title: 'Node Update Strategy',
    label: 'Max Unavailable Percentage',
    hide: (values) => !values?.awsManagedMachinePool?.updateConfig?.maxUnavailablePrecentage,
  },
  {
    id: 'config.labels',
    label: 'Labels',
    RowComponent: Labels,
    header: 'Bootstrap ConfigRef',
    subHeader: 'Nodelet Config',
    insertDivider: true,
    hide: (values) => values?.config?.kind !== ConfigTypes.NodeletConfig || !values.config?.labels,
  },
  {
    id: 'config.labels',
    label: 'Labels',
    RowComponent: Labels,
    header: 'Bootstrap ConfigRef',
    subHeader: 'Nodelet Config Template',
    insertDivider: true,
    hide: (values) =>
      values?.config?.kind !== ConfigTypes.NodeletConfigTemplate || !values.config?.labels,
  },
  {
    id: 'config.taints',
    label: 'Taints',
    RowComponent: Taints,
    hide: (values) => !values?.config?.taints,
  },
  {
    id: 'awsManagedMachinePool.spec.labels',
    label: 'Labels',
    RowComponent: Labels,
    insertDivider: true,
    hide: (values) => !values?.awsManagedMachinePool?.spec?.labels,
  },
  {
    id: 'awsManagedMachinePool.spec.taints',
    label: 'Taints',
    RowComponent: Taints,
    hide: (values) => !values.awsManagedMachinePool?.spec?.taints,
  },
]

export default function CapiNodeGroupsDetails() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { match } = useReactRouter()
  const { id, name } = match.params
  const [yamls, setYamls] = useState<Yamls>({})
  const [loadingYamls, setLoadingYamls] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)

  const { loading: loadingCapiClusters, reload: reloadCapiClusters } = useListAction(
    listCapiClusters,
  )

  const nodeGroups = useAppSelector(allNodeGroupsSelector)
  const nodeGroup = useMemo(() => nodeGroups.find((nodeGroup) => nodeGroup.name === name), [
    name,
    nodeGroups,
  ])
  const clusters = useAppSelector(capiClustersSelector)
  const cluster = useMemo(
    () => clusters.find((cluster) => cluster.name === nodeGroup?.clusterName),
    [nodeGroup?.clusterName, clusters],
  )

  const { update: updateDeploymentFn, updating: updatingDeployment } = useUpdateAction(
    updateMachineDeployment,
  )
  const { update: updateNodeletConfigFn, updating: updatingNodeletConfig } = useUpdateAction(
    updateNodeletConfig,
  )
  const {
    update: updateNodeletConfigTemplateFn,
    updating: updatingNodeletConfigTemplate,
  } = useUpdateAction(updateNodeletConfigTemplate)
  const { update: updateMachinePoolFn, updating: updatingMachinePool } = useUpdateAction(
    updateMachinePool,
  )
  const { update: updateAwsMachinePoolFn, updating: updatingAwsMachinePool } = useUpdateAction(
    updateAwsMachinePool,
  )
  const {
    update: updateAwsManagedMachinePoolFn,
    updating: updatingAwsManagedMachinePoolFn,
  } = useUpdateAction(updateAwsManagedMachinePool)
  const { update: updateEksConfigFn, updating: updatingEksConfig } = useUpdateAction(
    updateEksConfig,
  )
  const {
    update: updateEksConfigTemplateFn,
    updating: updatingEksConfigTemplate,
  } = useUpdateAction(updateEksConfigTemplate)
  const { update: createMachineTemplateFn, updating: creatingMachineTemplate } = useUpdateAction(
    createAwsMachineTemplate,
  )
  const { update: deleteMachineTemplateFn, updating: deletingMachineTemplate } = useUpdateAction(
    deleteAwsMachineTemplate,
  )

  useEffect(() => {
    dispatch(clientActions.updateBreadcrumbParams({ id: nodeGroup?.clusterName, name }))
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [nodeGroup?.clusterName, name, id])

  useEffect(() => {
    if (!nodeGroup) return
    setLoadingYamls(true)
    setYamls(convertResourcesToYamls(nodeGroup.resources))
    setLoadingYamls(false)
  }, [nodeGroup])

  const headerItems = useMemo(
    () => [
      { label: 'Desired Replicas', value: nodeGroup?.replicas },
      { label: 'Ready Replicas', value: nodeGroup?.readyReplicas },
      { label: 'Available Replicas', value: nodeGroup?.availableReplicas },
      { label: 'Unavailable Replicas', value: nodeGroup?.unavailableReplicas },
    ],
    [nodeGroup],
  )

  const updateFnMap = useMemo(
    () => ({
      MachineDeployment: updateDeploymentFn,
      NodeletConfig: updateNodeletConfigFn,
      NodeletConfigTemplate: updateNodeletConfigTemplateFn,
      MachinePool: updateMachinePoolFn,
      AWSMachinePool: updateAwsMachinePoolFn,
      AWSManagedMachinePool: updateAwsManagedMachinePoolFn,
      EKSConfigTemplate: updateEksConfigFn,
      EKSConfig: updateEksConfigTemplateFn,
      AWSMachineTemplate: createMachineTemplateFn,
    }),
    [],
  )

  const handleYamlUpdate = useCallback(
    async ({ kind, namespace, name, updatedYaml }) => {
      if (kind === 'AWSMachineTemplate') {
        await deleteMachineTemplateFn({ namespace, name })
      }
      const updateFn = updateFnMap[kind]
      if (!updateFn) return
      const body =
        kind === 'AWSMachineTemplate'
          ? cleanResource(['metadata.creationTimestamp', 'metadata.resourceVersion'])(updatedYaml)
          : updatedYaml
      return updateFn({
        namespace,
        name,
        body,
      })
    },
    [updateFnMap],
  )

  const updating =
    updatingDeployment ||
    updatingNodeletConfig ||
    updatingNodeletConfigTemplate ||
    updatingMachinePool ||
    updatingAwsMachinePool ||
    updatingAwsManagedMachinePoolFn ||
    updatingEksConfig ||
    updatingEksConfigTemplate ||
    creatingMachineTemplate ||
    deletingMachineTemplate

  const EditModal = useMemo(() => {
    if (!nodeGroup?.kind) return null
    return switchCase({
      MachineDeployment: EditMachineDeploymentModal,
      MachinePool:
        nodeGroup.infrastructureRef?.kind === 'AWSMachinePool'
          ? EditMachinePoolModal
          : EditAwsManagedMachinePoolModal,
    })(nodeGroup.kind)
  }, [nodeGroup?.kind])

  const refresh = useCallback(() => {
    reloadCapiClusters(true)
  }, [])

  const loading = loadingCapiClusters

  if (!nodeGroup) return null

  return (
    <>
      <DocumentMeta title="CAPI Node Group Details" breadcrumbs />
      {showEditModal && EditModal && (
        <EditModal
          nodeGroup={nodeGroup}
          cluster={cluster}
          onClose={() => setShowEditModal(false)}
          refreshData={refresh}
        />
      )}
      <Progress loading={loading || updating} overlay>
        <div className={classes.statusContainer}>
          <StatusConditionsCard
            resourceType={nodeGroup?.type}
            conditions={nodeGroup?.status?.conditions}
          />
          {nodeGroup?.kind === NodeGroupTypes.MachinePool && nodeGroup?.awsMachinePool && (
            <StatusConditionsCard
              resourceType={nodeGroup?.awsMachinePool?.type}
              conditions={nodeGroup?.awsMachinePool?.status?.conditions}
            />
          )}
          {nodeGroup?.kind === NodeGroupTypes.MachinePool && nodeGroup?.awsManagedMachinePool && (
            <StatusConditionsCard
              resourceType={nodeGroup?.awsManagedMachinePool?.type}
              conditions={nodeGroup?.awsManagedMachinePool?.status?.conditions}
            />
          )}
        </div>
        <div className={classes.buttons}>
          <Button variant="secondary" icon="pencil" onClick={() => setShowEditModal(true)}>
            Edit
          </Button>
          <Button
            variant="secondary"
            icon="download"
            onClick={() => downloadYamlsZipFile(yamls, `${nodeGroup?.name}-${nodeGroup?.kind}`)}
          >
            Download YAMLs
          </Button>
        </div>
        <div className={classes.detailsContainer}>
          <div className={classes.details}>
            <CapiClustersNodeGroupsHeader items={headerItems} className={classes.header} />
            <Card title="Properties">
              <FormReviewTable
                className={classes.propertiesTable}
                data={nodeGroup}
                columns={columns}
              />
            </Card>
          </div>
          <div className={classes.yamls}>
            {Object.entries(yamls).map(([kind, yaml]) => (
              <YamlCard
                key={kind}
                kind={kind}
                yaml={yaml}
                open={
                  kind === NodeGroupTypes.MachineDeployment || kind === NodeGroupTypes.MachinePool
                }
                updateYamlFn={handleYamlUpdate}
                yamlValidations={yamlValidations}
                loading={loadingYamls}
              />
            ))}
          </div>
        </div>
      </Progress>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  conatainer: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridGap: theme.spacing(2),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(3, 0, 1, 0),
    gap: theme.spacing(1),
    minWidth: `${626 * 2 + 16}px`,
    maxWidth: `${774 * 2 + 16}px`,
  },
  statusContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(700px, 1fr))',
    gridGap: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  yamls: {
    display: 'grid',
    gridTemplateColumns: 'minmax(626px, 774px)',
    gridAutoRows: 'max-content',
    gridGap: theme.spacing(2),
  },
  propertiesTable: {
    height: 'max-content',
    width: 750,
  },
  details: {},
  header: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  detailsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(700px, 1fr))',
    gridGap: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}))
