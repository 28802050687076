import { AppSelector } from 'app/store'
import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import { NodeGroupTypeLabels } from '../model'
import { AwsMachinePool, IAwsMachinePoolSelector } from './model'
import { Phase } from '../machine-deployment/model'

export const awsMachinePoolsSelector: AppSelector<IAwsMachinePoolSelector[]> = createSharedSelector(
  getDataSelector<DataKeys.AwsMachinePools>(DataKeys.AwsMachinePools),
  (machineTemplates): IAwsMachinePoolSelector[] =>
    machineTemplates.map((mp: AwsMachinePool) => {
      return {
        ...mp,
        uuid: mp.metadata?.uid,
        name: mp.metadata?.name,
        namespace: mp.metadata?.namespace,
        clusterName: mp.metadata?.labels?.['cluster.x-k8s.io/cluster-name'],
        creationTimestamp: mp.metadata?.creationTimestamp,
        type: NodeGroupTypeLabels.AWSMachinePool,
        instanceType: mp.spec?.awsLaunchTemplate?.instanceType,
        awsLaunchTemplate: mp.spec?.awsLaunchTemplate,
        amiId: mp.spec?.awsLaunchTemplate?.ami?.id,
        sshKeyName: mp.spec?.awsLaunchTemplate?.sshKeyName,
        availabilityZones: mp.spec?.availabilityZones,
        minSize: mp.spec?.minSize,
        maxSize: mp.spec?.maxSize,
        mixedInstancesPolicy: mp.spec?.mixedInstancesPolicy,
        mixedInstances: !!mp.spec?.mixedInstancesPolicy,
        ready: mp.status?.ready,
        phase: mp.status?.ready ? Phase.Running : Phase.Failed,
        resources: [mp],
      }
    }),
)
