import { INodesSelector } from 'app/plugins/infrastructure/components/nodes/model'
import { ClusterSelector } from 'app/plugins/infrastructure/components/clusters/model'
import { GetCloudProvider } from 'api-client/qbert.model'
import { ImportedClusterSelector } from 'app/plugins/infrastructure/components/importedClusters/model'
import { switchCase } from 'utils/fp'
import { ClusterCloudPlatforms } from 'app/constants'

export enum CloudProviders {
  Aws = 'aws',
  Azure = 'azure',
  Gcp = 'gke',
  BareOS = 'local',
  PhysicalMachine = 'physical',
  VirtualMachine = 'virtual',
}

export enum CloudProvidersFriendlyName {
  aws = 'AWS',
  azure = 'Azure',
  local = 'BareOS',
}

export enum CloudProviderRouteNames {
  Aws = 'aws',
  Azure = 'azure',
  Google = 'google',
}

export enum CloudDefaults {
  Region = 'region',
  RegionLabel = 'regionDisplayName',
  Domain = 'domain', // Route 53 Domain. AWS only
  DomainLabel = 'domainLabel',
  SshKey = 'sshKey',
  SshKeyLabel = 'sshKeyLabel',
}

export interface ICloudProvidersSelector extends GetCloudProvider {
  descriptiveType: string
  deployedCapacity: DeployedCapacity
  clusters: ClusterSelector[]
  nodes: INodesSelector[]
  importedClusters: ImportedClusterSelector[]
}

// export interface ICloudProvider {
//   name: string
//   type: CloudProviders
//   uuid: string
//   nodePoolUuid: string
//   descriptiveType: string
//   deployedCapacity: DeployedCapacity
//   clusters: IClusterSelector[]
//   nodes: ICombinedNode[]
// }
interface DeployedCapacity {
  compute: Compute
  memory: Compute
  disk: Compute
  grafanaLink?: null | string
}

interface Compute {
  current: number
  max: number
  percent: number
}

export interface CloudProviderDetailsResponse {
  Regions: any[]
}

export interface CreateCloudProviderResponse {
  uuid: string
}

export interface AwsCloudProviderRequest {
  type?: string
  name?: string
  key?: string
  secret?: string
}

export interface AzureCloudProviderRequest {
  type?: string
  name?: string
  clientId?: string
  clientSecret?: string
  tenantId?: string
  subscriptionId?: string
}

export interface GoogleCloudProviderRequest {
  type?: string
  name?: string
  account_type?: string
}

export interface AwsCloudProviderRegionDetails {
  vpcs: any[]
  keyPairs: any[]
  azs: any[]
  flavors: string[]
  domains: any[]
  operatingSystems: string[]
}

export interface AzureCloudProviderRegionDetails {
  skus: any[]
  virtualNetworks: any[]
}

export const getCloudProviderIcon = (
  provider: CloudProviders,
  size: 'small' | 'medium' | 'large' = 'small',
) => {
  const iconSizes = { small: '', medium: '@2x', large: '@3x' }
  const iconSize = iconSizes[size]
  const rootPath = '/ui/images/icon-cloudproviders'
  return switchCase(
    {
      [CloudProviders.Aws]: `${rootPath}/cloudaws-default${iconSize}.png`,
      [ClusterCloudPlatforms.EKS]: `${rootPath}/cloudaws-default${iconSize}.png`,
      [CloudProviders.Azure]: `${rootPath}/cloudazure-default${iconSize}.png`,
      [ClusterCloudPlatforms.AKS]: `${rootPath}/cloudazure-default${iconSize}.png`,
      [CloudProviders.Gcp]: `${rootPath}/cloudgoogle-default${iconSize}.png`,
      [ClusterCloudPlatforms.GKE]: `${rootPath}/cloudgoogle-default${iconSize}.png`,
      [CloudProviders.VirtualMachine]: `${rootPath}/vm-default${iconSize}.png`,
      [CloudProviders.PhysicalMachine]: `${rootPath}/physical-default${iconSize}.png`,
    },
    '', // TODO: design an icon for a missing provider
  )(provider)
}

export const getCloudProviderLabel = switchCase(
  {
    [CloudProviders.Aws]: 'Amazon Web Services',
    [ClusterCloudPlatforms.EKS]: 'Amazon EKS',
    [CloudProviders.Azure]: 'Microsoft Azure',
    [ClusterCloudPlatforms.AKS]: 'Microsoft Azure AKS',
    [CloudProviders.Gcp]: 'Google Cloud',
    [ClusterCloudPlatforms.GKE]: 'Google GKE',
    [CloudProviders.VirtualMachine]: 'BareOS Virtual Machines',
    [CloudProviders.PhysicalMachine]: 'BareOS Physical Servers',
    [CloudProviders.BareOS]: 'BareOS',
  },
  '',
)
