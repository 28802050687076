import React, { useMemo, useCallback } from 'react'
import Card from 'core/elements/card'
import {
  paramDisplayNames,
  configParams,
  clusterAddonDisplayNames,
  getClusterAddonHealthStatus,
  addonInfoMessages,
  addonTypeToNameMap,
} from '../helpers'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { DetailRow } from 'k8s/components/common/entity/info-card'
import { omit } from 'ramda'
import { uncamelizeString } from 'utils/misc'
import Button from 'core/elements/button'
import ClusterStatusSpan from '../../ClusterStatusSpan'
import Text from 'core/elements/Text'
import { compareVersions } from 'k8s/util/helpers'
import SimpleLink from 'core/components/SimpleLink'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { updateClusterAddon } from '../new-actions'
import useToggler from 'core/hooks/useToggler'
import { IClusterStatus } from '../../model'
import Tooltip from 'core/elements/tooltip'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { ClusterAddonType } from '../model'

const fieldsToOmit = ['clusterUUID', 'clusterRegion', 'useIntervalBackup', 'useTimestampBackup']

const disableText = {
  [ClusterAddonType.Luigi]: 'Cannot be disabled because Kubevirt is still enabled on the cluster.',
  [ClusterAddonType.MetalLb]: 'Cannot be disabled because Metal³ is still enabled on the cluster.',
}

const renderHealthStatus = (addon) => {
  if (!addon) return null
  const addonStatus = getClusterAddonHealthStatus(addon)
  const label = addonStatus
  let status: IClusterStatus = 'ok'
  if (addonStatus === 'Error') {
    status = 'fail'
  }
  if (addonStatus === 'Installing') {
    status = 'pending'
  }
  if (addonStatus === 'Unknown') {
    status = 'unknown'
  }
  return <ClusterStatusSpan status={status}>{label}</ClusterStatusSpan>
}

export const AddonName = ({ addonType }) => {
  const classes = useStyles()
  return (
    <div className={classes.cardTitle}>
      <Text variant="subtitle2">{clusterAddonDisplayNames[addonType]}</Text>
      {addonInfoMessages[addonType] && (
        <Tooltip message={addonInfoMessages[addonType]}>
          <FontAwesomeIcon className={classes.icon}>question-circle</FontAwesomeIcon>
        </Tooltip>
      )}
    </div>
  )
}

const CardHeader = ({ type, version, latestVersion, updateAddon }) => {
  const classes = useStyles()
  const isUpToDate = compareVersions(version, latestVersion) === 0

  const renderVersion = useCallback(
    () => (
      <>
        {isUpToDate ? (
          <ClusterStatusSpan status="ok">{'Up to date'}</ClusterStatusSpan>
        ) : type === 'monitoring' ? (
          <Tooltip message="To update, please uninstall and reinstall the addon">
            <Text variant="caption1">Out of Date</Text>
          </Tooltip>
        ) : (
          <SimpleLink
            className={classes.updateLink}
            src={null}
            icon="arrow-alt-circle-up"
            textVariant="caption1"
            variant="secondary"
            onClick={updateAddon}
          >
            {`Update (${latestVersion})`}
          </SimpleLink>
        )}
      </>
    ),
    [isUpToDate, latestVersion],
  )
  return (
    <div className={classes.cardHeader}>
      <AddonName addonType={type} />
      {version && latestVersion && renderVersion()}
    </div>
  )
}

export default function ClusterAddonCard({
  addon,
  handleEditClick,
  handleDisableClick,
  handleViewConfigClick,
  existingClusterAddons,
  currentAddonVersions,
}) {
  const classes = useStyles()
  const [showAll, toggleShowall] = useToggler()
  const { type, version, params: allParams } = addon
  const latestVersion = currentAddonVersions[addonTypeToNameMap[type]]
  const params = omit(fieldsToOmit, allParams)
  const { update: updateAddon } = useUpdateAction(updateClusterAddon)

  const handleUpdateAddonVersion = () => {
    updateAddon({ ...addon, version: latestVersion })
  }

  const numFields = useMemo(() => Object.entries(params).length, [params])

  const addonFields = useMemo(() => {
    const fields = Object.entries(params)
    if (!showAll && numFields > 3) {
      return fields.slice(0, 3)
    }
    return fields
  }, [params, showAll])

  const canBeDisabled = useMemo(() => {
    if (type === ClusterAddonType.Luigi) {
      const hasKubevirtEnabled = !!existingClusterAddons.find(
        (addon) => addon.type === ClusterAddonType.Kubevirt,
      )
      return hasKubevirtEnabled ? false : true
    }
    if (type === ClusterAddonType.MetalLb) {
      const hasMetal3Enabled = !!existingClusterAddons.find(
        (addon) => addon.type === ClusterAddonType.Metal3,
      )
      return hasMetal3Enabled ? false : true
    }
    return true
  }, [type, existingClusterAddons])

  return (
    <Card
      className={classes.card}
      title={
        <CardHeader
          type={type}
          version={version}
          latestVersion={latestVersion}
          updateAddon={handleUpdateAddonVersion}
        />
      }
      footer={
        <div className={classes.footerActions}>
          <div>
            {numFields > 4 && (
              <SimpleLink src={null} onClick={toggleShowall}>
                {showAll ? 'View less' : 'View more'}
              </SimpleLink>
            )}
          </div>
          <div className={classes.buttons}>
            {addon?.isConfigurable && (
              <Button variant="secondary" onClick={() => handleEditClick(addon)}>
                Edit Configuration
              </Button>
            )}
            <Button
              variant="tertiary"
              onClick={() => handleDisableClick(addon)}
              disabled={!canBeDisabled}
              info={canBeDisabled ? null : disableText[type]}
            >
              Disable
            </Button>
          </div>
        </div>
      }
      withCustomFooter
    >
      <table className={classes.cardContent}>
        <DetailRow label="Version" value={version || 'N/A'} />
        <DetailRow label="Healthy" value={renderHealthStatus(addon)} />
        {addonFields?.map(([key, value]) => {
          const isConfigValue = configParams.has(key)
          const formattedParamName = paramDisplayNames[key] || uncamelizeString(key)
          const valueToRender = isConfigValue ? (
            <SimpleLink src={null} onClick={() => handleViewConfigClick(formattedParamName, value)}>
              View
            </SimpleLink>
          ) : (
            value
          )
          return (
            <DetailRow key={key} label={formattedParamName} value={valueToRender} lineClamp={2} />
          )
        })}
      </table>
    </Card>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  card: {
    display: 'grid',
    gridTemplateRows: 'max-content 1fr max-content',
  },
  cardHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '16px 32px',
    borderBottom: `1px solid ${theme.components.card.border}`,
  },
  cardTitle: {
    display: 'flex',
    gap: theme.spacing(1.5),
  },
  icon: {
    color: theme.palette.grey['300'],
  },
  cardContent: {
    borderSpacing: '20px',
    position: 'relative',
    left: -20,
  },
  footerActions: {
    display: 'grid',
    gridTemplateColumns: '1fr max-content',
    padding: '12px 24px 12px 36px',
    borderTop: `1px solid ${theme.components.card.border}`,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 16,
  },
  updateLink: {
    '& > i': {
      color: theme.palette.pink.main,
    },
  },
}))
