import React, { useCallback, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import { routes } from 'core/utils/routes'
import useReactRouter from 'use-react-router'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import SimpleLink from 'core/components/SimpleLink'
import DisconnectRepositoryDialog from 'k8s/components/app-catalog/repositories/components/disconnect-repository-dialog'
import ClusterReposListDialog from './cluster-repos-list-dialog'
import generateTestId from 'utils/test-helpers'
import ListContainer from 'core/containers/ListContainer'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import DataKeys from 'k8s/DataKeys'
import { ArrayElement } from 'core/actions/Action'
import { listDeployedApps } from 'k8s/components/app-catalog/deployed-apps/new-actions'
import { deployedAppsSelector } from 'k8s/components/app-catalog/deployed-apps/selectors'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { listAppsAvailableToCluster } from 'k8s/components/app-catalog/apps/new-actions'
import { availableAppsByRepositorySelector } from 'k8s/components/app-catalog/apps/selectors'
import { isEmpty, pick } from 'ramda'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { IDeployedAppDetailsPageTabs } from 'k8s/components/app-catalog/deployed-apps/model'
import { createGridLinkCell } from 'core/elements/grid/cells/GridLinkCell'
import { createGridStatusCell, StatusCellModel } from 'core/elements/grid/cells/GridStatusCell'
import { capitalizeString } from 'utils/misc'
import InfoCard from 'k8s/components/common/entity/info-card'
import Button from 'core/elements/button'
import { getFieldsForCard } from 'core/components/InfoPanel'
import ConnectRepositoriesCard from './ConnectRepositoriesCard'
import NamespacePicklist from 'k8s/components/common/NamespacePicklist'
import { GridFilterSpec } from 'core/elements/grid/hooks/useGridFiltering'
import { getDeployedAppHealthStatusBadgeVariant } from 'k8s/components/app-catalog/deployed-apps/helpers'
import InferActionParams from 'core/actions/InferActionParams'

type ModelDataKey = DataKeys.DeployedApps
type SelectorModel = ArrayElement<ReturnType<typeof deployedAppsSelector>>
type ActionParams = InferActionParams<typeof listDeployedApps>

type Params = ActionParams & {
  namespace?: string
}

const defaultParams: Params = {
  clusterId: null,
  // namespace: allKey,
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'Cluster Deployed Apps',
  listTablePrefs,
)

interface RepositoryValueLabelProps {
  name: string
  numDeployedAppsFromRepository: number
  totalAppsInRepository: number
  onClick: (name) => void
}

const RepositoryValueLabel = ({
  name,
  numDeployedAppsFromRepository,
  totalAppsInRepository,
  onClick,
}: RepositoryValueLabelProps) => {
  const { repositoryValueLabel, deleteIcon } = useStyles()
  return (
    <div key={name} className={repositoryValueLabel}>
      <Text data-testid={generateTestId(numDeployedAppsFromRepository)} variant="body2">
        <b>{`${numDeployedAppsFromRepository} of ${totalAppsInRepository}`}</b> apps deployed
      </Text>
      <SimpleLink
        data-testid={generateTestId('disconnect', 'repository')}
        src=""
        onClick={() => onClick(name)}
      >
        <FontAwesomeIcon solid size="sm" className={deleteIcon}>
          minus-circle
        </FontAwesomeIcon>
      </SimpleLink>
    </div>
  )
}

const searchTargets = ['name']
const columns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
    CellComponent: createGridLinkCell({
      routeToFn: ({ clusterId, namespace, repository, chart, name }) =>
        routes.apps.deployed.details.path({
          clusterId,
          namespace,
          repository,
          chart,
          name,
          tab: IDeployedAppDetailsPageTabs.Overview,
        }),
    }),
  },
  {
    key: 'status',
    label: 'Status',
    CellComponent: createGridStatusCell({
      dataFn: (status: string): StatusCellModel => {
        const badgeVariant = getDeployedAppHealthStatusBadgeVariant(status)
        return { variant: badgeVariant, label: capitalizeString(status) }
      },
    }),
  },
  { key: 'namespace', label: 'Namespace' },

  { key: 'chartVersion', label: 'Version' },
]

const overviewFields = [
  { id: 'version', title: 'Kubernetes Version', required: true },
  { id: 'apps', title: 'Number of Apps', required: true },
  // use workerNodes instead of numWorkerNodes bc numWorkerNodes is not accurate with bareOS
  {
    id: 'workerNodes',
    title: 'Number of Worker Nodes',
    render: (value) => value?.length,
    required: true,
  },
]

interface ClusterDeployedAppsProps {
  cluster: any
  reload?: any
  loading?: boolean
}

const ClusterDeployedApps = ({ cluster }: ClusterDeployedAppsProps) => {
  const classes = useStyles()
  const { params, getParamsUpdater } = usePrefParams(defaultParams)
  const { history } = useReactRouter()
  const [showDisconnectRepoDialog, setShowDisconnectRepoDialog] = useState(false)
  const [showReposListDialog, setShowReposListDialog] = useState(false)
  const [activeRepository, setActiveRepository] = useState(null)

  const {
    loading: loadingDeployedApps,
    reload: reloadDeployedApps,
  } = useListAction(listDeployedApps, { params: { clusterId: cluster?.uuid } })
  const deployedApps = useSelectorWithParams(deployedAppsSelector, {
    clusterId: cluster?.uuid,
  })

  // Get all apps from repositories that are connected to the cluster
  const {
    loading: loadingAppsAvailableToCluster,
    message,
  } = useListAction(listAppsAvailableToCluster, { params: { clusterId: cluster?.uuid } })
  // Get a mapping of connected repositories to apps
  const availableAppsByRepository = useSelectorWithParams(availableAppsByRepositorySelector, {
    clusterId: cluster?.uuid,
  })

  const hasNoConnectedRepositories = useMemo(
    () => !loadingAppsAvailableToCluster && isEmpty(availableAppsByRepository),
    [availableAppsByRepository, loadingAppsAvailableToCluster],
  )

  const handleRepositoryDisconnectButtonClick = (name) => {
    setShowDisconnectRepoDialog(true)
    setActiveRepository(name)
  }

  const closeDialogs = () => {
    showDisconnectRepoDialog && setShowDisconnectRepoDialog(false)
    showReposListDialog && setShowReposListDialog(false)
  }

  const overview = useMemo(() => {
    return getFieldsForCard(overviewFields, { ...cluster, apps: deployedApps.length })
  }, [cluster, deployedApps])

  const renderRepositoryValue = useCallback(
    (repositoryName, numDeployedAppsFromRepository, totalAppsInRepository) => (
      <RepositoryValueLabel
        name={repositoryName}
        numDeployedAppsFromRepository={numDeployedAppsFromRepository}
        totalAppsInRepository={totalAppsInRepository}
        onClick={handleRepositoryDisconnectButtonClick}
      />
    ),
    [],
  )

  const repositoryFields = useMemo(() => {
    const fields = {}
    Object.entries(availableAppsByRepository).forEach(([repositoryName, apps]) => {
      const numDeployedAppsFromRepository = deployedApps.filter(
        (app) => app.repository === repositoryName,
      ).length
      const totalAppsInRepository = availableAppsByRepository[repositoryName]?.length
      fields[repositoryName] = {
        value: renderRepositoryValue(
          repositoryName,
          numDeployedAppsFromRepository,
          totalAppsInRepository,
        ),
      }
    })
    return fields
  }, [availableAppsByRepository, deployedApps])

  const filters = useMemo(
    () => [
      {
        columnKey: 'namespace',
        FilterComponent: ({ ...filterProps }) => (
          <NamespacePicklist selectFirst={false} clusterId={cluster?.uuid} {...filterProps} />
        ),
        onChange: getParamsUpdater('namespace'),
      } as GridFilterSpec<SelectorModel, Params, 'namespace'>,
    ],
    [cluster?.uuid],
  )

  return (
    <>
      {showDisconnectRepoDialog && (
        <DisconnectRepositoryDialog
          name={activeRepository}
          clusterId={cluster?.uuid}
          onSubmit={null}
          onClose={closeDialogs}
        />
      )}
      {showReposListDialog && (
        <ClusterReposListDialog clusterId={cluster?.uuid} onClose={closeDialogs} />
      )}
      <div className={classes.clusterDeployedAppsPage}>
        <ListContainer<ModelDataKey, SelectorModel>
          dataKey={DataKeys.DeployedApps}
          searchTargets={searchTargets}
          uniqueIdentifier="name"
          loading={loadingDeployedApps}
          loadingMessage={message}
          onRefresh={reloadDeployedApps}
          data={deployedApps}
          columns={columns}
          filters={filters}
          getParamsUpdater={getParamsUpdater}
          showBreadcrumbs={false}
          {...pick(listTablePrefs, params)}
        />
        <div className={classes.infoCardsContainer}>
          <InfoCard
            title="Overview"
            items={overview}
            footer={
              <footer className={classes.overviewCardFooter}>
                <Button
                  variant="secondary"
                  onClick={() => history.push(routes.apps.list.path())}
                  disabled={hasNoConnectedRepositories}
                >
                  Deploy App
                </Button>
              </footer>
            }
            withCustomFooter
          />
          {!hasNoConnectedRepositories && (
            <InfoCard
              title="Repositories"
              items={repositoryFields}
              footer={
                hasNoConnectedRepositories ? null : (
                  <footer className={classes.overviewCardFooter}>
                    <Button variant="secondary" onClick={() => setShowReposListDialog(true)}>
                      Connect Repository
                    </Button>
                  </footer>
                )
              }
              withCustomFooter
            />
          )}
          {hasNoConnectedRepositories && (
            <ConnectRepositoriesCard
              handleConnectRepositoryClick={() => setShowReposListDialog(true)}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default ClusterDeployedApps

const useStyles = makeStyles((theme: Theme) => ({
  clusterDeployedAppsPage: {
    display: 'grid',
    gridTemplateColumns: 'minmax(max-content, 1fr) max-content',
    gridGap: theme.spacing(3),
  },
  infoCardsContainer: {
    display: 'grid',
    gridGap: theme.spacing(2),
    gridAutoRows: 'max-content',
  },
  cardsContainer: {
    display: 'grid',
    gridGap: theme.spacing(2),
    minWidth: '1200px',
    marginBottom: theme.spacing(2),
    alignItems: 'stretch',
  },
  repositoriesCard: {
    maxHeight: '280px',
  },
  rowHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    color: theme.palette.grey[700],
  },
  rowValue: {
    marginLeft: theme.spacing(4),
    color: theme.palette.grey[700],
    wordBreak: 'break-all',
  },
  repositoryValueLabel: {
    display: 'grid',
    gridTemplateColumns: '1fr max-content',
    gridGap: theme.spacing(3),
  },
  deleteIcon: {
    marginRight: theme.spacing(1.5),
    color: theme.palette.blue[500],
  },
  overviewCardFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '8px 16px',
    borderTop: `1px solid ${theme.components.card.border}`,
    height: 'max-content',
  },
}))
