// import { Host } from 'api-client/resmgr.model'
import { Node } from 'api-client/qbert.model'
import { NetworkStackTypes } from '../clusters/constants'
import { ICombinedHost } from 'app/plugins/infrastructure/components/common/model'
import {
  IClusterStatus,
  IResourceUsage,
} from 'app/plugins/infrastructure/components/clusters/model'

export type IUseDataLoader<T> = [T[], boolean, (ignoreCache?: boolean) => T[]]

export enum NodeState {
  Authorized = 'Authorized',
  Unauthorized = 'Unauthorized',
}

export type ApiServerHealthStatus = 'online' | 'offline'
export interface ApiServerHealthStatusFields {
  label: string
  message: string
  clusterStatus: IClusterStatus
}

export type errorMessageLevel = 'warn'

export enum ErrorMessageCodes {
  timeDrift = 'HOST_TIMEDRIFT_DETECTED',
}

export interface INodesSelector extends Node {
  status: string
  combined: ICombinedHost
  logs: string
  roles: ICombinedHost['roles']
  operatingSystem: ICombinedHost['resmgr']['info']['os_info']
  primaryNetwork: ICombinedHost['qbert']['primaryIp']
  networkStack: NetworkStackTypes
  networkInterfaces: ICombinedHost['networkInterfaces']
  cpuArchitecture: ICombinedHost['resmgr']['info']['arch']
  message: ICombinedHost['resmgr']['message']
  ready: string
  conditions: Condition[]
  unschedulable: boolean
  isAuthorized: boolean
  isSpotInstance: boolean
  usage: IResourceUsage
}

export interface Resmgr {
  info: Info
  hypervisor_info: HypervisorInfo
  roles: string[]
  role_status: string
  extensions: Extensions
  message: string
  id: string
}

interface Extensions {
  volumes_present: VolumesPresent
  kube_api_status: KubeAPIStatus
  listened_ports: ListenedPorts
  firewalld_status: FirewalldStatus
  interfaces: Interfaces
  cloud_metadata: CloudMetadata
  resource_usage: ResourceUsage
  physical_nics: PhysicalNics
  mounted_nfs: MountedNFS
  node_metadata: NodeMetadata
  pf9_kube_status: Pf9KubeStatus
  ip_address: IPAddress
  selinux_status: SelinuxStatus
  cpu_stats: CPUStats
}

interface Pf9KubeStatus {
  status: string
  data: Pf9KubeStatusData
}

interface Pf9KubeStatusData {
  pf9_kube_start_attempt: number
  last_failed_status_check: string
  last_failed_task: string
  all_tasks: string[]
  current_task: string
  current_status_check: string
  completed_tasks: string[]
  pf9_kube_service_state: boolean
  all_status_checks: string[]
  last_failed_status_time: string
  pf9_kube_node_state: IKubeNodeState
  total_task_count: number
  status_check_timestamp: string
}

type IKubeNodeState = 'failed' | 'ok' | 'converging' | 'retrying'

interface CloudMetadata {
  status: string
  data: CloudMetadataData
}

interface CloudMetadataData {
  instanceId: string
  publicHostname: string
}

interface CPUStats {
  status: string
  data: CPUStatsData
}

interface CPUStatsData {
  load_average: string
}

interface FirewalldStatus {
  status: string
  data: FirewalldStatusData
}

interface FirewalldStatusData {
  error: string
}

interface Interfaces {
  status: string
  data: InterfacesData
}

interface InterfacesData {
  iface_ip: Record<string, string>
  iface_info: { [key: string]: IfaceInfo }
  ovs_bridges: any[]
}

interface IfaceInfo {
  mac: string
  ifaces: Iface[]
}

interface Iface {
  broadcast: string
  netmask: string
  addr: string
}

interface IPAddress {
  status: string
  data: string[]
}

interface KubeAPIStatus {
  status: string
  data: KubeAPIStatusData
}

interface KubeAPIStatusData {
  responding: boolean
}

interface ListenedPorts {
  status: string
  data: ListenedPortsData
}

interface ListenedPortsData {
  udp: string
  tcp: string
}

interface MountedNFS {
  status: string
  data: MountedNFSData
}

interface MountedNFSData {
  mounted: any[]
  last_updated: string
}

interface NodeMetadata {
  status: string
  data: NodeMetadataData
}

interface NodeMetadataData {
  isSpotInstance: boolean
}

interface PhysicalNics {
  status: string
  data: PhysicalNicsData
}

interface PhysicalNicsData {
  default: string
  cni0?: string
  eth0: string
  flannel0: string
}

interface ResourceUsage {
  status: string
  data: ResourceUsageData
}

interface ResourceUsageData {
  disk: CPU
  cpu: CPU
  memory: Memory
}

interface CPU {
  total: number
  percent: number
  used: number
}

interface Memory {
  available: number
  total: number
  percent: number
}

interface SelinuxStatus {
  status: string
  data: SelinuxStatusData
}

interface SelinuxStatusData {
  status: string
}

interface VolumesPresent {
  status: string
  data: VolumeData[]
}

interface VolumeData {
  name: string
  free: string
  size: string
}

interface HypervisorInfo {
  hypervisor_type: string
}

interface Info {
  responding: boolean
  hostname: string
  last_response_time: string
  os_family: string
  arch: string
  os_info: string
}

export interface K8SNodeResponse {
  kind: string
  apiVersion: string
  metadata: K8SNodeMetadata
  items: K8sNode[]
}

export interface K8sNode {
  metadata: ItemMetadata
  spec: Spec
  status: StatusClass
}

interface ItemMetadata {
  name: string
  uid: string
  resourceVersion: string
  creationTimestamp: string
  labels: Record<string, string>
  annotations: Record<string, string>
}

interface Spec {
  podCIDR: string
  podCIDRs: string[]
  providerID: string
}

interface StatusClass {
  capacity: Allocatable
  allocatable: Allocatable
  conditions: Condition[]
  addresses: Address[]
  daemonEndpoints: DaemonEndpoints
  nodeInfo: NodeInfo
  images: Image[]
}

interface Address {
  type: string
  address: string
}

interface Allocatable {
  'attachable-volumes-gce-pd': string
  cpu: string
  'ephemeral-storage': string[]
  'hugepages-1Gi': string
  'hugepages-2Mi': string
  memory: string
  pods: string
}

interface Condition {
  type: string
  status: string
  lastHeartbeatTime: string
  lastTransitionTime: string
  reason: string
  message: string
}

interface DaemonEndpoints {
  kubeletEndpoint: KubeletEndpoint
}

interface KubeletEndpoint {
  Port: number
}

interface Image {
  names: string[]
  sizeBytes: number
}

interface NodeInfo {
  machineID: string
  systemUUID: string
  bootID: string
  kernelVersion: string
  osImage: string
  containerRuntimeVersion: string
  kubeletVersion: string
  kubeProxyVersion: string
  operatingSystem: string
  architecture: string
}

interface K8SNodeMetadata {
  resourceVersion: string
}
