import PicklistField from 'core/components/validatedForm/DropdownField'
import React from 'react'

const defaultLabel = 'Operating System'

const AwsAmiField = ({
  dropdownComponent,
  values,
  onChange = undefined,
  label = defaultLabel,
  id = 'awsAmiId',
  ...rest
}) => (
  <PicklistField
    DropdownComponent={dropdownComponent}
    disabled={!values.region}
    id={id}
    label={label}
    region={values.region}
    onChange={onChange}
    required
    {...rest}
  />
)

export default AwsAmiField
