import React from 'react'
import PicklistField from 'core/components/validatedForm/DropdownField'
import KubernetesVersionPicklist from 'k8s/components/common/KubernetesVersionPicklist'
import EksKubernetesVersionPicklist from 'k8s/components/common/EksKubernetesVersionPicklist'

export default function KubernetesVersionField({
  wizardContext,
  setWizardContext,
  required = true,
  id = 'kubeRoleVersion',
  value = undefined,
  showAwsEksVersions = false,
  ...props
}) {
  return (
    <PicklistField
      DropdownComponent={
        showAwsEksVersions ? EksKubernetesVersionPicklist : KubernetesVersionPicklist
      }
      id={id}
      label="Kubernetes Version"
      onChange={(value) => setWizardContext({ [id]: value })}
      value={value || wizardContext[id]}
      required={required}
      selectFirst
      {...props}
    />
  )
}
