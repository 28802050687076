import React from 'react'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { capitalizeString } from 'utils/misc'

interface Props<T extends Record<string, string>> {
  items: T[]
  additionalProperties?: Array<keyof T>
}

export default function KeyValueReview<T extends Record<string, string>>({
  items,
  additionalProperties,
}: Props<T>) {
  if (!items?.length) return null

  const classes = useStyles()
  return (
    <table className={classes.table}>
      {items.map((item, index) => (
        <tr key={`${item.key} - ${item?.value}`}>
          <td className={classes.rowItem}>
            <Text component="span" variant="body2">
              {`${index + 1}.\u00A0\u00A0  Key -\u00A0`}
            </Text>
            <Text component="span" variant="caption1">
              {item?.key}
            </Text>
          </td>
          <td className={classes.rowItem}>
            <Text component="span" variant="body2">{`Value -\u00A0`}</Text>
            <Text component="span" variant="caption1">
              {item?.value}
            </Text>
          </td>
          {additionalProperties?.length &&
            additionalProperties.map((prop: string) => (
              <td key={`${prop} - ${item[prop]}`}>
                <Text component="span" variant="body2">{`${capitalizeString(prop)} -\u00A0`}</Text>
                <Text component="span" variant="caption1">
                  {item[prop]}
                </Text>
              </td>
            ))}
        </tr>
      ))}
    </table>
  )
}
const useStyles = makeStyles<Theme>((theme) => ({
  table: {
    borderSpacing: '8px',
    tableLayout: 'fixed',
    verticalAlign: 'baseline',
    padding: theme.spacing(2, 0),
  },
  rowItem: {
    color: theme.components.typography.default,
    width: 360,
  },
}))
