import React, { useEffect, useMemo } from 'react'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/styles'
import { emptyArr, exists, projectAs } from 'utils/fp'
import MultiDropdown from 'core/elements/dropdown/MultiDropdown'
import useListAction from 'core/hooks/useListAction'
import { listClusters } from '../clusters/newActions'
import { listImportedClusters } from '../importedClusters/new-actions'
import { listCapiClusters } from 'app/plugins/infrastructure/components/clusters/capi/actions'
import { useSelector } from 'react-redux'
import { CombinedClusterSelector } from '../combinedClusters/model'
import { allClustersSelector } from '../combinedClusters/selectors'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { UserPreferences } from 'app/constants'
import Text from 'core/elements/Text'
import DefaultClusterCard from './DefaultClusterCard'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'grid',
    gap: 8,
  },
  clusterDropdown: {
    justifyContent: 'flex-start',
  },
  cardsRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: 8,
  },
  card: {
    background: theme.components.card.background,
    border: theme.components.card.border,
    borderRadius: 4,
    minHeight: 150,
  },
  noCluster: {
    display: 'grid',
    alignContent: 'center',
    height: '100%',
    textAlign: 'center',
  },
}))

const ClusterCards = ({ clusterIds = [] }) => {
  const classes = useStyles()
  const { loading: loadingClusters } = useListAction(listClusters)
  const { loading: loadingImportedClusters } = useListAction(listImportedClusters)
  const { loading: loadingCapiClusters } = useListAction(listCapiClusters)
  const allClusters: CombinedClusterSelector[] = useSelector(allClustersSelector)
  const clusters = useMemo(() => {
    return (
      clusterIds
        .map((clusterId) => {
          return allClusters.find((cluster) => {
            return clusterId === cluster.uuid
          })
        })
        .filter(exists)
        // Limit to 3
        .slice(0, 3)
    )
  }, [clusterIds, allClusters])

  return (
    <div className={classes.cardsRow}>
      {clusters.map((cluster) => (
        <div key={cluster?.uuid} className={classes.card}>
          <DefaultClusterCard cluster={cluster} />
        </div>
      ))}
      {[...Array(3 - clusters.length).keys()].map((num) => (
        <div key={num} className={classes.card}>
          <div className={classes.noCluster}>
            <Text variant="subtitle2">No Default Cluster</Text>
            <Text variant="body1">Select a cluster from the dropdown menu</Text>
          </div>
        </div>
      ))}
    </div>
  )
}

// Limit selection to 3 clusters maximum
const togglableCondition = (option, isSelected, selectedValues) => {
  if (isSelected) {
    return true
  }
  return selectedValues?.length < 3
}

export default function DefaultClusters() {
  const classes = useStyles()
  const { loading: loadingClusters } = useListAction(listClusters)
  const { loading: loadingImportedClusters } = useListAction(listImportedClusters)

  const allClusters: CombinedClusterSelector[] = useSelector(allClustersSelector)

  const options = useMemo(() => {
    return [...projectAs({ label: 'name', value: 'uuid' }, allClusters)]
  }, [allClusters])

  const { prefs, fetchUserDefaults, updateUserDefaults } = useScopedPreferences('defaults')
  const { clusterIds: defaultClusters } = (prefs[UserPreferences.DefaultClusters] || emptyArr) as {
    clusterIds: string[]
  }

  useEffect(() => {
    fetchUserDefaults(UserPreferences.DefaultClusters)
  }, [])

  const onChange = (value) => {
    updateUserDefaults(UserPreferences.DefaultClusters, {
      clusterIds: value,
    })
  }

  return (
    <div className={classes.container}>
      <MultiDropdown
        compact
        items={options}
        onChange={onChange}
        label="Favorite Clusters"
        loading={loadingClusters || loadingImportedClusters}
        value={defaultClusters}
        className={classes.clusterDropdown}
        optionToggleCondition={togglableCondition}
        width={550}
      />
      <ClusterCards clusterIds={defaultClusters} />
    </div>
  )
}
