import React, { useState } from 'react'
import { IClusterAction } from './model'
import SimpleLink from 'core/components/SimpleLink'
import DownloadKubeConfigForm from 'k8s/components/apiAccess/kubeConfig/DownloadKubeConfigForm'
import Modal from 'core/elements/modal'
import { noop, stopPropagation } from 'utils/fp'
import Button from 'core/elements/button'

interface Props {
  cluster: IClusterAction
  className?: string
  icon?: string
  iconPosition?: 'left' | 'right'
}

const DownloadKubeConfigLink = ({ cluster, icon, className, iconPosition = 'right' }: Props) => {
  const [showModal, setModal] = useState(false)

  const handleOpen = () => setModal(true)
  const handleClose = () => setModal(false)

  const renderModalContent = () => (
    <Modal
      open
      onClose={handleClose}
      onClick={stopPropagation}
      title={`Download kubeconfig - ${cluster.name}`}
      footer={
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      }
    >
      <DownloadKubeConfigForm cluster={cluster} onSubmit={noop} previewKubeConfig />
    </Modal>
  )

  return (
    <div>
      {showModal && renderModalContent()}
      <SimpleLink
        src=""
        icon={icon}
        iconPosition={iconPosition}
        className={className}
        onClick={handleOpen}
      >
        Download kubeconfig
      </SimpleLink>
    </div>
  )
}

export default DownloadKubeConfigLink
