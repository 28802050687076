import ModalForm from 'core/elements/modal/ModalForm'
import TextField from 'core/components/validatedForm/TextField'
import Text from 'core/elements/Text'
import React, { useMemo, useEffect } from 'react'
import AwsRegionFlavorPicklist from '../../../aws/AwsRegionFlavorPicklist'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import WorkerNodeInstanceTypeField from '../../../form-components/WorkerNodeInstanceTypeField'
import { amiVersionInfo } from '../../constants'
import ToggleSwitch from 'core/elements/ToggleSwitch'
import useParams from 'core/hooks/useParams'
import KeyValuesField from 'core/components/validatedForm/KeyValuesField'
import TaintEffectsPicklist from '../../../form-components/TaintEffectsPicklist'
import { isNilOrEmpty, keyValueArrToObj, objToKeyValueArr } from 'utils/fp'
import machinePool from '../../../aws/capi/schema/default/machine-pool.json'
import awsManagedMachinePool from '../../../aws/capi/schema//eks/aws-managed-machine-pool.json'
import eksConfig from '../../../aws/capi/schema/eks/eks-config.json'
import YamlTemplateParser from 'utils/YamlTemplateParser'
import Divider from 'core/elements/Divider'
import { makeStyles } from '@material-ui/core/styles'
import Theme from 'core/themes/model'
import useCapiResourceUpdate from '../../../aws/capi/useCapiResourceUpdate'
import CapiAwsManagedMachinePoolNodeUpdateStrategies from '../../../aws/capi/CapiAwsManagedMachinePoolNodeUpdateStrategies'
import CapiAwsAvailabilityZoneField from '../../../aws/capi/capi-vpc/CapiAwsAvailiabilityZoneField'
import DropdownField from 'core/components/validatedForm/DropdownField'
import { amiTypeOptions } from '../../../aws/AwsWorkerNodeGroupFields'
import Dropdown from 'core/elements/dropdown'
import { assoc } from 'ramda'
import { initialEksWorkerNodeGroup } from '../../../aws/capi/CapiWorkerNodeGroupsWizardStep'
import { CapiOperation } from 'app/plugins/infrastructure/components/clusters/aws/capi/model'
import { ClusterCloudPlatforms } from 'app/constants'
import CapiEditModalErrors from '../CapiEditModalErrors'

const parseYamls = (values) => {
  const yamls = [machinePool, awsManagedMachinePool, eksConfig]
  return yamls.map((schema) => {
    const templateParser = new YamlTemplateParser(schema)
    templateParser.setValues(values)
    return templateParser.getJson()
  })
}

const capiResourceUpdateOptions = {
  operation: CapiOperation.PartialUpdate,
}

export default function EditAwsManagedMachinePoolModal({
  nodeGroup,
  onClose,
  cluster,
  refreshData = null,
}) {
  const classes = useStyles()
  const { params, updateParams, getParamsUpdater } = useParams<any>(initialEksWorkerNodeGroup)
  const { execute, errors: allErrors, updating } = useCapiResourceUpdate(capiResourceUpdateOptions)
  const isEksCluster =
    cluster?.infrastructureType && cluster.infrastructureType === ClusterCloudPlatforms.EKS

  const initialValues = useMemo(() => {
    if (isNilOrEmpty(nodeGroup)) return {}
    const awsManagedMachinePool = nodeGroup.awsManagedMachinePool
    return {
      name: nodeGroup.name,
      namespace: nodeGroup.namespace,
      clusterName: nodeGroup.clusterName,
      nodeCount: nodeGroup.replicas,
      config: nodeGroup.config?.kind,
      machinePoolType: nodeGroup.infrastructureRef?.kind,
      wgVersion: nodeGroup.k8sVersion,
      amiType: awsManagedMachinePool?.amiType,
      amiVersion: awsManagedMachinePool?.amiVersion,
      availabilityZones: awsManagedMachinePool.availabilityZones,
      instanceType: awsManagedMachinePool.instanceType,
      capacityType: awsManagedMachinePool.capacityType,
      scaling: awsManagedMachinePool?.scaling || {
        minSize: 1,
        maxSize: 3,
      },
      labels: objToKeyValueArr(awsManagedMachinePool?.spec?.labels),
      taints: awsManagedMachinePool?.spec?.taints,
      updateConfig: awsManagedMachinePool?.updateConfig || {},
      region: cluster?.region,
      cloudProviderId: cluster?.cloudProviderId,
    }
  }, [nodeGroup, cluster])

  useEffect(() => {
    updateParams(initialValues)
  }, [initialValues])

  const handleSubmit = async () => {
    const finalParams = {
      ...params,
      labels: Array.isArray(params.labels) ? keyValueArrToObj(params.labels) : params.labels,
    }
    const resources = parseYamls(finalParams)
    const { errors } = await execute(resources)
    if (isNilOrEmpty(errors)) {
      if (refreshData) {
        refreshData()
      }
      onClose()
    }
  }

  const errorComponent = useMemo(() => <CapiEditModalErrors errors={allErrors} />, [allErrors])

  return (
    <ModalForm
      open
      title="Edit Node Group"
      onClose={onClose}
      onSubmit={handleSubmit}
      submitting={updating}
      customErrorComponent={errorComponent}
    >
      <FormFieldSection title="">
        <TextField
          id="nodeCount"
          label="Desired Replicas"
          type="number"
          min="1"
          value={params.nodeCount}
          onChange={(value) => {
            getParamsUpdater('nodeCount')(value)
            getParamsUpdater('scaling')(assoc('maxSize', value, params.scaling || {}))
          }}
          required
        />
        {/* Commenting out for 5.6  release due to editing these changes not working properly upstream| More info here --> UX-2265*/}
        {/* <WorkerNodeInstanceTypeField
          id="instanceType"
          dropdownComponent={AwsRegionFlavorPicklist}
          label="Instance Type"
          values={params}
          value={params.instanceType}
          onChange={getParamsUpdater('instanceType')}
        />
        <DropdownField
          DropdownComponent={Dropdown}
          id="amiType"
          label="Operating System AMI Type"
          value={params.amiType}
          onChange={getParamsUpdater('amiType')}
          items={amiTypeOptions}
        /> */}
        <TextField
          id="amiVersion"
          label="Operating System AMI Version (Optional)"
          value={params.amiVersion}
          onChange={getParamsUpdater('amiVersion')}
          info={amiVersionInfo}
        />
        {/* Commenting out for 5.6  release due to editing these changes not working properly upstream| More info here --> UX-2265*/}
        {/* <CapiAwsAvailabilityZoneField
          id="availabilityZones"
          wizardContext={params}
          isEksCluster={isEksCluster}
          value={params.availabilityZones}
          onChange={getParamsUpdater('availabilityZones')}
        />
        <CapiAwsManagedMachinePoolNodeUpdateStrategies
          workerNodeGroup={params}
          onChange={getParamsUpdater}
        />
        <ToggleSwitch
          label="Enable Spot Instances"
          active={params?.capacityType === 'spot'}
          onClick={(value) => getParamsUpdater('capacityType')(value ? 'spot' : 'onDemand')}
        /> */}
        <Divider className={classes.divider} />
        <KeyValuesField
          id="labels"
          label="Labels"
          value={params.labels}
          onChange={(value) => getParamsUpdater('labels')(value)}
          addLabel="Add Label"
          allowMultipleValues
        />
        <Divider className={classes.divider} />
        <KeyValuesField
          id="taints"
          label="Taints"
          value={params.taints}
          onChange={getParamsUpdater('taints')}
          addLabel="Add Taint"
          allowMultipleValues
          additionalFields={[
            {
              id: 'effect',
              label: 'Effects',
              Component: TaintEffectsPicklist,
              props: {
                isAwsManagedMachinePool: true,
              },
            },
          ]}
        />
      </FormFieldSection>
    </ModalForm>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  divider: {
    margin: theme.spacing(1, 0, 0, 0),
  },
}))
