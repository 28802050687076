import React, { useCallback, useState } from 'react'
import Text from 'core/elements/Text'
import { Dialog, DialogContent, DialogActions, DialogTitle } from '@material-ui/core'
import { clusterActions } from 'app/plugins/infrastructure/components/clusters/actions'
import TextField from 'core/components/validatedForm/TextField'
import useDataUpdater from 'core/hooks/useDataUpdater'
import DeauthNodeDialog from 'app/plugins/infrastructure/components/nodes/DeauthNodeDialog'
import { ClusterSelector } from './model'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import Alert from 'core/components/Alert'
import { CloudProviders } from 'app/plugins/infrastructure/components/cloudProviders/model'
import { trackEvent } from 'utils/tracking'
import generateTestId from 'utils/test-helpers'
import Button from 'core/elements/button'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { UserPreferences } from 'app/constants'
import { emptyObj, stopPropagation } from 'utils/fp'
import { omit } from 'ramda'

interface IClusterDeleteDialog {
  rows: ClusterSelector[]
  onClose: () => void
}

const isDefaultCluster = (defaultClusterId, clusterId) => defaultClusterId === clusterId

const ClusterDeleteDialog: React.FC<IClusterDeleteDialog> = ({ rows: [cluster], onClose }) => {
  const [showDeauthNodeDialog, setShowDeauthNodeDialog] = useState(false)
  const { prefs, updateUserDefaults, deleteUserDefaults } = useScopedPreferences('defaults')
  const [deleteCluster, deletingCluster] = useDataUpdater(clusterActions.delete, (success) => {
    trackEvent('Delete Cluster', {
      cluster_uuid: cluster.uuid,
      cluster_name: cluster.name,
      cluster_nodes: cluster.nodes.length,
      cloud_provider_type: cluster.cloudProviderType,
    })
    // TODO: If deauth node feature is supported after cluster delete then enable this feature
    // eslint-disable-next-line no-constant-condition
    false ? setShowDeauthNodeDialog(true) : onClose()
  })
  const title = `Permanently delete cluster "${cluster?.name}"?`

  const clusterDefaults = (prefs[UserPreferences.Cluster] || emptyObj) as { uuid: string }
  const defaultClusterId = clusterDefaults?.uuid
  const defaultNamespaces = prefs[UserPreferences.Namespace] || emptyObj
  const hasDefaultNamespace = (clusterId) => !!defaultNamespaces[clusterId]

  const handleDelete = useCallback(async () => {
    await deleteCluster({ clusterId: cluster.uuid })
    if (isDefaultCluster(defaultClusterId, cluster.uuid)) {
      deleteUserDefaults(UserPreferences.Cluster)
      if (hasDefaultNamespace(cluster.uuid)) {
        updateUserDefaults(UserPreferences.Namespace, omit([cluster.uuid], defaultNamespaces), true)
      }
    }
  }, [cluster])

  if (showDeauthNodeDialog) return <DeauthNodeDialog nodeList={cluster.nodes} onClose={onClose} />

  return (
    <Dialog open onClose={onClose} onClick={stopPropagation}>
      <DialogTitle>
        <Text variant="subtitle1">{title}</Text>
      </DialogTitle>
      <ValidatedForm initialValues={cluster} fullWidth onSubmit={handleDelete} elevated={false}>
        {({ values }) => (
          <>
            <DialogContent>
              <Text variant="body1" component="div">
                Please type "<b>{cluster?.name}</b>" to confirm.
              </Text>
              <TextField
                data-testid={generateTestId('clusterId')}
                id="clusterName"
                type="text"
                label="Cluster name"
              />
              {cluster.cloudProviderType === CloudProviders.BareOS && (
                <Alert
                  variant="warning"
                  message="When deleting a BareOS cluster all nodes will remain connected to Platform9"
                />
              )}
            </DialogContent>
            <DialogActions>
              <Button
                data-testid={generateTestId('dont', 'delete', 'btn')}
                variant="secondary"
                type="button"
                onClick={onClose}
                loading={false}
                disabled={deletingCluster}
              >
                Don't Delete
              </Button>
              <Button
                data-testid={generateTestId('delete', 'cluster', 'btn')}
                type="submit"
                disabled={values?.clusterName !== cluster?.name}
                loading={deletingCluster}
              >
                Delete this cluster
              </Button>
            </DialogActions>
          </>
        )}
      </ValidatedForm>
    </Dialog>
  )
}

export default ClusterDeleteDialog
