import React from 'react'
import { getNodeGroupStatuses } from '../capi/helpers'
import Badge, { BadgeVariant } from 'core/elements/badge/Badge'
import { Phase } from '../capi/machine-deployment/model'
import { isNilOrEmpty } from 'utils/fp'

export default function NodeGroupStatusesCell({ item }) {
  const nodeGroups = item?.allNodeGroups
  if (isNilOrEmpty(nodeGroups)) return null
  const statuses = getNodeGroupStatuses(nodeGroups)

  let variant: BadgeVariant = 'success'
  let label = 'Healthy'
  if (statuses[Phase.Failed].count > 0 || statuses[Phase.Unknown].count > 0) {
    variant = 'error'
    label = 'Unhealthy'
  } else if (statuses[Phase.ScalingUp].count > 0 || statuses[Phase.ScalingDown].count > 0) {
    variant = 'warning'
    label = 'Scaling'
  }

  return <Badge variant={variant} text={label} />
}
