import React, { FC, useEffect } from 'react'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import ClusterNameField from '../../form-components/NameField'
import {
  CloudDefaults,
  CloudProviders,
} from 'app/plugins/infrastructure/components/cloudProviders/model'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import { azurePrerequisitesLink } from 'k8s/links'
import Text from 'core/elements/Text'
import ExternalLink from 'core/components/ExternalLink'
import WizardStep from 'core/components/wizard/WizardStep'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import FormReviewTable from 'core/components/validatedForm/review-table'
import { capitalizeString, castBoolToStr } from 'utils/misc'
import { UserPreferences } from 'app/constants'
import CloudProviderField from '../../form-components/CloudProviderField'
import CloudProviderRegionField from '../../form-components/CloudProviderRegionField'
import SshKeyTextField from '../../form-components/SshKeyTextfield'
import KubernetesVersionField from '../../form-components/KubernetesVersionField'
import { azureClusterTracking } from '../../tracking'
import { ClusterCreateTypes } from '../../model'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { emptyObj, isNilOrEmpty } from 'utils/fp'
import { compareVersions } from 'k8s/util/helpers'
import { oneClickEtcdBackupDefaults } from '../../cluster-addons/etcd-backup'

export const initialContext = {
  containersCidr: '10.20.0.0/22',
  servicesCidr: '10.21.0.0/22',
  numMasters: 1,
  numWorkers: 0,
  allowWorkloadsOnMaster: true,
  enableCAS: false,
  runtimeConfigOption: 'default',
  useAllAvailabilityZones: true,
  assignPublicIps: true,
  masterSku: 'Standard_B4MS',
  workerSku: 'Standard_B4MS',
  ami: 'ubuntu',
  networkPlugin: 'flannel',
  etcdBackup: true,
  prometheusMonitoringEnabled: true,
  tags: [],
  networkStack: 'ipv4',
  privileged: true,
  enableProfileAgent: true,
  containerRuntime: 'containerd',
  ...oneClickEtcdBackupDefaults,
}

export const columns = [
  { id: 'numMasters', label: 'Master nodes' },
  { id: 'numWorkers', label: 'Worker nodes' },
  {
    id: 'allowWorkloadsOnMaster',
    label: 'Enable workloads on all master nodes',
    render: (value) => castBoolToStr()(value),
  },
  { id: 'masterSku', label: 'Master Node SKU' },
  { id: 'workerSku', label: 'Worker Node SKU' },
  { id: 'ami', label: 'Operating System', insertDivider: true },
  {
    id: 'networkPlugin',
    label: 'CNI',
    render: (value) => capitalizeString(value),
  },
  { id: 'containerRuntime', label: 'Container Runtime' },
  {
    id: 'etcdBackup',
    label: 'ETCD Backup',
    render: (value) => castBoolToStr()(value),
    insertDivider: true,
  },
  { id: 'etcdStoragePath', label: 'Storage Path' },
  { id: 'etcdBackupTimestamp', label: 'Daily Backup Time' },
  { id: 'maxTimestampBackups', label: 'Max Timestamp Backup Count' },
  {
    id: 'prometheusMonitoringEnabled',
    label: 'Enable monitoring with prometheus',
    render: (value) => castBoolToStr()(value),
  },
  {
    id: 'enableProfileAgent',
    label: 'Enable profile agent',
    render: (value) => castBoolToStr()(value),
  },
]

const trackingFields = {
  platform: CloudProviders.Azure,
  target: ClusterCreateTypes.OneClick,
}

const useStyles = makeStyles<Theme>((theme) => ({
  validatedFormContainer: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
}))

interface Props {
  wizardContext: any
  setWizardContext: any
  onNext: any
}

const OneClickAzureCluster: FC<Props> = ({ wizardContext, setWizardContext, onNext, ...rest }) => {
  const classes = useStyles()
  const { prefs, fetchUserDefaults } = useScopedPreferences('defaults')
  const cloudDefaults =
    prefs?.[UserPreferences.CloudProviders]?.[wizardContext.cloudProviderId] || emptyObj

  useEffect(() => {
    // Load user defaults from the preference store
    fetchUserDefaults(UserPreferences.CloudProviders)
  }, [])

  useEffect(() => {
    if (compareVersions(wizardContext.kubeRoleVersion, '1.20') >= 0) {
      setWizardContext({ enableProfileAgent: true })
    } else {
      setWizardContext({ enableProfileAgent: false })
    }
  }, [wizardContext.kubeRoleVersion])

  useEffect(() => {
    if (isNilOrEmpty(cloudDefaults)) return
    setWizardContext({ ...cloudDefaults, location: cloudDefaults[CloudDefaults.Region] })
  }, [cloudDefaults])

  const handleRegionChange = (regionName) =>
    setWizardContext({ region: regionName, location: regionName })

  return (
    <WizardStep stepId="one-click" onNext={azureClusterTracking.oneClick(trackingFields)} {...rest}>
      <ValidatedForm
        classes={{ root: classes.validatedFormContainer }}
        fullWidth
        initialValues={wizardContext}
        onSubmit={setWizardContext}
        triggerSubmit={onNext}
        elevated={false}
      >
        {({ values }) => (
          <>
            <FormFieldSection
              title="Cluster Configuration"
              link={
                <ExternalLink url={azurePrerequisitesLink}>
                  <Text variant="caption2">Azure Cluster Help</Text>
                </ExternalLink>
              }
            >
              {/* Cluster Name */}
              <ClusterNameField setWizardContext={setWizardContext} />

              {/* Cloud Provider */}
              <CloudProviderField
                cloudProviderType={CloudProviders.Azure}
                wizardContext={wizardContext}
                setWizardContext={setWizardContext}
                onChange={(value) =>
                  setWizardContext({
                    cloudProviderId: value,
                  })
                }
              />

              {/* Cloud Provider Region */}
              <CloudProviderRegionField
                cloudProviderType={CloudProviders.Azure}
                wizardContext={wizardContext}
                values={values}
                onChange={handleRegionChange}
                disabled={!values.cloudProviderId && !values.region}
              />

              {/* SSH Key */}
              <SshKeyTextField wizardContext={wizardContext} setWizardContext={setWizardContext} />

              <KubernetesVersionField
                wizardContext={wizardContext}
                setWizardContext={setWizardContext}
              />
            </FormFieldSection>

            <FormFieldSection title="Default Settings for New Cluster">
              <FormReviewTable data={wizardContext} columns={columns} />
            </FormFieldSection>
          </>
        )}
      </ValidatedForm>
    </WizardStep>
  )
}

export default OneClickAzureCluster
