import { GridCellProps } from 'core/elements/grid/hooks/useGridRows'
import { CombinedClusterSelector } from '../../combinedClusters/model'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { UserPreferences } from 'app/constants'
import { emptyObj } from 'utils/fp'
import React, { useEffect, useMemo } from 'react'
import Tooltip from 'core/elements/tooltip'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import SimpleLink from 'core/components/SimpleLink'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { getClusterDetailsPath } from '../helpers'

export default function ClusterNameCell({ item: cluster }: GridCellProps<CombinedClusterSelector>) {
  const classes = useStyles()
  const { prefs, getUserPrefs } = useScopedPreferences('defaults')
  const clusterDefaults = (prefs[UserPreferences.Cluster] || emptyObj) as { uuid: string }
  const isDefaultCluster = cluster.uuid === clusterDefaults.uuid
  const clusterDetailsUrl = useMemo(() => {
    return getClusterDetailsPath({ clusterId: cluster.uuid, clusterType: cluster.clusterType })
  }, [cluster.clusterType, cluster.uuid])

  useEffect(() => {
    getUserPrefs(UserPreferences.Cluster)
  }, [])

  return (
    <div className={classes.cell}>
      {isDefaultCluster && (
        <Tooltip message="Default Cluster">
          <FontAwesomeIcon className="starIcon">star</FontAwesomeIcon>
        </Tooltip>
      )}
      <SimpleLink src={clusterDetailsUrl}>{cluster.name}</SimpleLink>
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  cell: {
    display: 'inline-grid',
    gridAutoColumns: 'minmax(min-content, max-content)',
    gridAutoFlow: 'column',
    alignItems: 'center',
    gap: 8,
    '& .starIcon': {
      color: theme.palette.yellow.main,
      fontSize: theme.typography.body2.fontSize,
      fontWeight: 400,
    },
  },
}))
