import { Host, RoleStatus } from 'api-client/resmgr.model'
import { Node } from 'api-client/qbert.model'
import { SortConfig } from 'core/helpers/createSorter'

export enum HostTypes {
  Qbert = 'qbert',
  Resmgr = 'resmgr',
  K8s = 'k8s',
}
type HostType = 'qbert' | 'resmgr'
type HostByService = {
  [key in HostType]: key extends 'qbert' ? Node : IResMgrPayloadType['resmgr']
}

export interface IAnnotateResmgrFields extends HostByService {
  id: string
  roles: string[]
  roleStatus: RoleStatus
  roleData: {}
  responding: boolean
  hostname: string
  osInfo: string
  networks: any[]
  vCenterIP: any
  supportRole: boolean
  networkInterfaces: any
  warnings: any
}

export interface IAnnotateUiState extends IAnnotateResmgrFields {
  lastResponse: string
  lastResponseData: string
  hasStats: boolean
  uiState: string
}

export interface IAnnotateCloudStack extends IAnnotateUiState {
  cloudStack: 'openstack' | 'k8s' | 'unknown'
}

export interface ICombinedHost extends IAnnotateCloudStack {
  // @todo fix these typings
  k8s?: any
  localizedRoles?: any
  usage: {
    compute: {
      current: number
      max: number
      units: string
      type: string
    }
    memory: {
      current: number
      max: number
      units: string
      type: string
    }
    disk: {
      current: number
      max: number
      units: string
      type: string
    }
  }
}

export interface INetworkInterface {
  name: string
  mac: string
  ip: string
  netmask: string
  label: string
}

export interface IResMgrPayloadType {
  resmgr: Host & {
    resourceType: HostTypes
    ipPreview: string
    networkInterfaces: INetworkInterface[]
    ovsBridges: any[]
  }
}

export interface ICombinedHostSelector {
  [key: string]: ICombinedHostSelectorItem
}

export interface ICombinedHostSelectorItem extends ICombinedHost, HostByService {}

export interface ClusterParams extends SortConfig {
  clusterId?: string | string[]
  masterNodeClusters?: boolean
  masterlessClusters?: boolean
  hasControlPanel?: boolean
  healthyClusters?: boolean
  prometheusClusters?: boolean
  nonPrometheusClusters?: boolean
  kubevirtClusters?: boolean
  ecoEnabledClusters?: boolean
}
export interface CapiClusterParams extends SortConfig {
  provisionedClusters?: boolean
}
