import ModalForm from 'core/elements/modal/ModalForm'
import TextField from 'core/components/validatedForm/TextField'
import React, { useMemo, useEffect } from 'react'
import AwsRegionFlavorPicklist from '../../../aws/AwsRegionFlavorPicklist'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import WorkerNodeInstanceTypeField from '../../../form-components/WorkerNodeInstanceTypeField'
import AwsAmiField from '../../../form-components/AwsAmiField'
import AwsAmiPicklist, { cloudInit } from '../../../aws/AwsAmiPicklist'
import BooleanPicklistField from '../../../form-components/BooleanPicklistField'
import { makeNodesPublicTooltipDescription } from '../../constants'
import ToggleSwitch from 'core/elements/ToggleSwitch'
import Row from 'core/containers/Row'
import useParams from 'core/hooks/useParams'
import { spotMaxPriceValidator } from 'core/utils/fieldValidators'
import KeyValuesField from 'core/components/validatedForm/KeyValuesField'
import TaintEffectsPicklist from '../../../form-components/TaintEffectsPicklist'
import { isNilOrEmpty, keyValueArrToObj, objToKeyValueArr } from 'utils/fp'
import machineDeployment from '../../../aws/capi/schema/default/machine-deployment.json'
import nodeletConfigTemplate from '../../../aws/capi/schema/default/nodelet-config-template.json'
import awsMachineTemplate from '../../../aws/capi/schema/default/worker-node-aws-machine-template.json'
import eksConfig from '../../../aws/capi//schema/eks/eks-config.json'
import eksConfigTemplate from '../../../aws/capi//schema/eks/eks-config-template.json'
import YamlTemplateParser from 'utils/YamlTemplateParser'
import CapiMachineDeploymentNodeUpdateStrategies from '../../../aws/capi/CapiMachineDeploymentNodeUpdateStrategies'
import Divider from 'core/elements/Divider'
import { makeStyles } from '@material-ui/core/styles'
import Theme from 'core/themes/model'
import useCapiResourceUpdate from '../../../aws/capi/useCapiResourceUpdate'
import { AwsClusterTypes, ConfigTypes } from '../../model'
import {
  CapiOperation,
  CapiResourceKind,
} from 'app/plugins/infrastructure/components/clusters/aws/capi/model'
import { ClusterCloudPlatforms } from 'app/constants'
import CapiEditModalErrors from '../CapiEditModalErrors'
import ApiClient from 'api-client/ApiClient'

const { sunpike } = ApiClient.getInstance()
const { patchMachineDeployment } = sunpike

const getYamlTemplates = (infrastructureType, configKind) => {
  if (infrastructureType === AwsClusterTypes.AWS) {
    return [machineDeployment, awsMachineTemplate, nodeletConfigTemplate]
  }
  if (configKind === ConfigTypes.EksConfig) {
    return [machineDeployment, awsMachineTemplate, eksConfig]
  }
  return [machineDeployment, awsMachineTemplate, eksConfigTemplate]
}

const parseYamls = (values, infrastructureType, configKind) => {
  if (!infrastructureType) return []
  return getYamlTemplates(infrastructureType, configKind).map((schema) => {
    const templateParser = new YamlTemplateParser(schema)
    templateParser.setValues(values)
    return templateParser.getJson()
  })
}

const defaultParams = {
  publicIP: true,
  cas: null,
  instanceType: 't2.medium',
} as any

const capiResourceUpdateOptions = {
  operation: CapiOperation.PartialUpdate,
}

export default function EditMachineDeploymentModal({
  nodeGroup,
  onClose,
  cluster,
  refreshData = null,
}) {
  const classes = useStyles()
  const { params, updateParams, getParamsUpdater } = useParams(defaultParams)
  const { execute, errors: allErrors, updating } = useCapiResourceUpdate(capiResourceUpdateOptions)

  const isEksCluster =
    cluster?.infrastructureType && cluster.infrastructureType === ClusterCloudPlatforms.EKS

  const initialValues = useMemo(() => {
    if (isNilOrEmpty(nodeGroup)) return {}
    const machineTemplate = nodeGroup.machineTemplate
    const config = nodeGroup.config
    return {
      name: nodeGroup.name,
      namespace: nodeGroup.namespace,
      minCasSize: nodeGroup.minCasSize,
      maxCasSize: nodeGroup.maxCasSize,
      clusterName: nodeGroup.clusterName,
      nodeCount: nodeGroup.replicas,
      cas: nodeGroup.autoscalingEnabled,
      config: nodeGroup.configRef?.kind,
      configName: nodeGroup.configRef?.name,
      machineName: nodeGroup.infrastructureRef?.name,
      wgVersion: nodeGroup.k8sVersion,
      rollingUpdate: nodeGroup.spec?.strategy?.rollingUpdate,
      publicIP: machineTemplate?.publicIP,
      amiId: machineTemplate?.amiId,
      instanceType: machineTemplate?.instanceType,
      sshKey: machineTemplate?.sshKeyName,
      spotMarketOptions: machineTemplate?.spotMarketOptions,
      labels: objToKeyValueArr(config?.spec?.labels),
      taints: config?.spec?.taints,
      region: cluster?.region,
      cloudProviderId: cluster?.cloudProviderId,
    }
  }, [nodeGroup, cluster])

  useEffect(() => {
    updateParams(initialValues)
  }, [initialValues])

  const handleSubmit = async (formValues) => {
    const finalParams = {
      ...params,
      ...formValues,
      amiId: params.customAmiId || params.amiId,
      labels: Array.isArray(params.labels) ? keyValueArrToObj(params.labels) : params.labels,
    }
    const resources = parseYamls(
      finalParams,
      cluster?.infrastructureType,
      nodeGroup?.configRef?.kind,
    )
    // For MachineDeployments, configRef.name isn't the name of the NodeletConfigTemplate object, like how it is
    // for MachinePools. NodeletConfigs here in this case have a different name so we must loop through
    // the list of resources for a MachineDeployment and find out the NodeletConfigTemplate's name
    //This seems like a backend issue. Test out and remove out this code, if not needed
    const nodeletConfigTemplateIdx = resources.findIndex(
      (r) => r.kind === CapiResourceKind.NodeletConfigTemplate,
    )
    const existingNodeletConfig = nodeGroup?.resources?.find(
      (r) => r.kind === CapiResourceKind.NodeletConfigTemplate,
    )
    if (
      nodeletConfigTemplateIdx !== -1 &&
      resources?.[nodeletConfigTemplateIdx]?.metadata?.name &&
      !!existingNodeletConfig
    ) {
      resources[nodeletConfigTemplateIdx].metadata.name =
        existingNodeletConfig?.metadata?.name || nodeGroup.configRef?.name
    }
    const { errors } = await execute(resources)
    // only way to remove the annotations for autoscaling
    if (nodeGroup.autoscalingEnabled && !params.cas) {
      const machineDeploymentResource = resources.find(
        (resource) => resource.kind === 'MachineDeployment',
      )
      await patchMachineDeployment(machineDeploymentResource, [
        { op: 'replace', path: '/metadata/annotations', value: {} },
      ])
    }
    if (isNilOrEmpty(errors)) {
      if (refreshData) {
        refreshData(nodeGroup.kind)
      }
      onClose()
    }
  }

  const errorComponent = useMemo(() => <CapiEditModalErrors errors={allErrors} />, [allErrors])

  return (
    <ModalForm
      open
      title="Edit Node Group"
      onClose={onClose}
      onSubmit={handleSubmit}
      submitting={updating}
      customErrorComponent={errorComponent}
    >
      <FormFieldSection title="">
        <TextField
          id="nodeCount"
          label="Desired Replicas"
          type="number"
          min="0"
          value={params.nodeCount}
          onChange={(value) => {
            getParamsUpdater('nodeCount')(value)
            getParamsUpdater('minCasSize')(params?.cas ? value.toString() : '')
          }}
          required
        />
        {/* Commenting out for 5.6  release due to editing these changes not working properly upstream| More info here --> UX-2276*/}
        {/* <WorkerNodeInstanceTypeField
          id="instanceType"
          dropdownComponent={AwsRegionFlavorPicklist}
          label="Instance Type"
          values={params}
          value={params.instanceType}
          onChange={getParamsUpdater('instanceType')}
        />
        {!isEksCluster && (
          <>
            <AwsAmiField
              id="amiId"
              dropdownComponent={AwsAmiPicklist}
              label="Operating System"
              values={params}
              value={params.amiId}
              onChange={(value) => {
                const isCustomValue = value === 'custom'
                updateParams({
                  amiId: value?.id,
                  customAmiId: isCustomValue ? params.amiId : '',
                  cloudInit: value?.secretsManager ? cloudInit : null,
                })
              }}
            />
            <TextField
              id="customAmiId"
              label="Custom AMI ID (optional)"
              placeholder="Enter Value..."
              disabled={params.amiId !== 'custom'}
              value={params.customAmiId}
              onChange={(value) => {
                getParamsUpdater('customAmiId')(value)
                getParamsUpdater('amiId')(value)
                getParamsUpdater('cloudInit')(cloudInit)
              }}
            />
          </>
        )}

        <BooleanPicklistField
          id="publicIP"
          label="Make Nodes Public"
          value={params.publicIP}
          onChange={getParamsUpdater('publicIP')}
          tooltip={makeNodesPublicTooltipDescription}
        /> */}
        <ToggleSwitch
          label="Enable Autoscaling"
          active={!!params.cas}
          onClick={(value) => {
            getParamsUpdater('cas')(value ? {} : null)
            getParamsUpdater('minCasSize')(value ? params?.nodeCount.toString() : '')
            getParamsUpdater('maxCasSize')(value ? '10' : '')
          }}
        />
        {params.cas && (
          <Row minItemWidth="300" gap={24}>
            <TextField
              id="minAutoScalingSize"
              label="Min Number of Workers"
              value={params.minCasSize}
              onChange={(value) => getParamsUpdater('minCasSize')(value.toString())}
              min={params.nodeCount}
              max={params.maxCasSize}
              type="number"
              required
            />
            <TextField
              id="maxAutoScalingSize"
              label="Max Number of Workers"
              value={params.maxCasSize}
              onChange={(value) => getParamsUpdater('maxCasSize')(value.toString())}
              min={params.nodeCount}
              type="number"
              required
            />
          </Row>
        )}
        <CapiMachineDeploymentNodeUpdateStrategies
          workerNodeGroup={params}
          onChange={getParamsUpdater}
        />
        {/* Commenting out for 5.6  release due to editing these changes not working properly upstream| More info here --> UX-2276*/}
        {/* <ToggleSwitch
          label="Enable Spot Instances"
          active={params.spotMarketOptions}
          onClick={(value) => getParamsUpdater('spotMarketOptions')(value ? { maxPrice: '' } : '')}
        />
        {params.spotMarketOptions && (
          <TextField
            id="spotMaxPrice"
            label="Spot Instance Maximum Price"
            onChange={(value) => getParamsUpdater('spotMarketOptions')({ maxPrice: value })}
            placeholder="Empty Means Max Price (Type String)"
            validations={[spotMaxPriceValidator]}
          />
        )} */}
        {cluster?.infrastructureType === AwsClusterTypes.AWS && (
          <>
            <Divider className={classes.divider} />
            <KeyValuesField
              id="labels"
              label="Labels"
              value={params.labels}
              onChange={(value) => getParamsUpdater('labels')(value)}
              addLabel="Add Label"
              allowMultipleValues
            />
            <Divider className={classes.divider} />
            <KeyValuesField
              id="taints"
              label="Taints"
              value={params.taints}
              onChange={getParamsUpdater('taints')}
              addLabel="Add Taint"
              allowMultipleValues
              additionalFields={[
                {
                  id: 'effect',
                  label: 'Effects',
                  Component: TaintEffectsPicklist,
                  props: {
                    isAwsManagedMachinePool: false,
                  },
                },
              ]}
            />
          </>
        )}
      </FormFieldSection>
    </ModalForm>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  divider: {
    margin: theme.spacing(1, 0, 0, 0),
  },
}))
