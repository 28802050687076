import React from 'react'
import ClusterNameField from '../../form-components/NameField'
import CloudProviderRegionField from '../../form-components/CloudProviderRegionField'
import AwsClusterSshKeyPicklist from '../AwsClusterSshKeyPicklist'
import { CloudProviders } from 'app/plugins/infrastructure/components/cloudProviders/model'
import SshKeyPicklist from '../../form-components/SshKeyPicklist'
import { arnAwsValidator, ipv4CidrValidator } from '../../form-components/validators'
import TextField from 'core/components/validatedForm/TextField'
import CapiSpec from './CapiSpec'
import Row from 'core/containers/Row'
import { last } from 'ramda'
import ToggleSwitchField from 'core/components/validatedForm/ToggleSwitchField'
import CapiCloudProviderField from './CapiCloudProviderField'
import { getAccountIdfromAwsArn } from './capiUtils'
import { IdentityRef } from '../create-templates/CapiAwsClusters'
import { AwsClusterTypes } from '../../capi/model'

interface Props {
  wizardContext: any
  setWizardContext: any
  values: any
}

export default function CapiClusterSpec({ wizardContext, setWizardContext, values }: Props) {
  return (
    <CapiSpec title="Cluster Spec">
      <Row minItemWidth="300" gap={24}>
        {/* Cluster Name */}
        <ClusterNameField
          setWizardContext={setWizardContext}
          onChange={(value) =>
            setWizardContext({
              name: value,
              controlPlaneName: `${value}-control-plane`,
              identityRefName: wizardContext?.awsAssumeRole
                ? `${wizardContext?.name}-${getAccountIdfromAwsArn(
                    wizardContext?.assumeRoleTargetArn,
                  )}`
                : wizardContext?.identityRefName,
            })
          }
        />
        {/* Cloud Provider */}
        <CapiCloudProviderField
          id="capiCloudProvider"
          disabled={wizardContext.awsAssumeRole}
          cloudProviderType={CloudProviders.Aws}
          setWizardContext={setWizardContext}
          wizardContext={wizardContext}
          required={!wizardContext?.awsAssumeRole}
        />
      </Row>
      {/* AWS Assume Role */}
      {wizardContext?.type === AwsClusterTypes.AWS && (
        <>
          <Row minItemWidth="300" gap={24}>
            {/* AWS Assume Role Toggle */}
            <ToggleSwitchField
              id="awsAssumeRole"
              label="AWS Assume Role"
              value={wizardContext?.awsAssumeRole}
              onChange={(value) =>
                setWizardContext({
                  cloudProviderId: null,
                  awsAssumeRole: value,
                  identityRefKind: value
                    ? IdentityRef.AWSClusterRoleIdentity
                    : IdentityRef.AWSClusterStaticIdentity,
                })
              }
            />
          </Row>

          <Row minItemWidth="300" gap={24}>
            {/* Source AWS  Provider */}
            <CapiCloudProviderField
              id="capiSourceAwsCloudProvider"
              label="Source AWS Provider"
              disabled={!wizardContext?.awsAssumeRole}
              cloudProviderType={CloudProviders.Aws}
              required={wizardContext?.awsAssumeRole}
              wizardContext={wizardContext}
              setWizardContext={setWizardContext}
            />
            {/* Assume Role Target ARN */}
            <TextField
              id="assumeRoleTargetArn"
              label="Assume Role Target ARN"
              disabled={!wizardContext?.awsAssumeRole}
              required={wizardContext?.awsAssumeRole}
              value={wizardContext?.assumeRoleTargetArn}
              onChange={(value) =>
                setWizardContext({
                  assumeRoleTargetArn: value,
                  awsAccountId: getAccountIdfromAwsArn(value),
                  identityRefName: `${wizardContext?.name}-${getAccountIdfromAwsArn(value)}`,
                })
              }
              validations={wizardContext?.awsAssumeRole ? [arnAwsValidator] : []}
            />
          </Row>
        </>
      )}
      <Row minItemWidth="300" gap={24}>
        {/*Cluster Pod CIDR Range */}
        <TextField
          id="podCidr"
          label="Cluster Pod CIDR Range"
          info="The network ranges from which service Pod networks are allocated"
          required
          value={
            Array.isArray(wizardContext?.clusterPodCidr) ? last(wizardContext?.clusterPodCidr) : ''
          }
          onChange={(value) => setWizardContext({ clusterPodCidr: [value] })}
          validations={[ipv4CidrValidator]}
        />
        {/* Cluster Service CIDR Range */}
        <TextField
          id="serviceCidr"
          label="Cluster Service CIDR Range"
          info="The network ranges from which service Virtual IPs are allocated."
          required
          value={
            Array.isArray(wizardContext?.clusterServiceCidr)
              ? last(wizardContext?.clusterServiceCidr)
              : ''
          }
          onChange={(value) => setWizardContext({ clusterServiceCidr: [value] })}
          validations={[ipv4CidrValidator]}
        />
      </Row>
      <Row minItemWidth="300" gap={24}>
        {/* AWS Region */}
        <CloudProviderRegionField
          cloudProviderType={CloudProviders.Aws}
          values={wizardContext}
          wizardContext={wizardContext}
          onChange={(region) => setWizardContext({ region, azs: [] })}
        />

        {/* SSH Key */}
        {wizardContext.awsAssumeRole ? (
          <TextField
            id="assumeRoleSshKey"
            label="SSH Key Name"
            required
            value={wizardContext.sshKey}
            onChange={(value) => setWizardContext({ sshKey: value })}
          />
        ) : (
          <SshKeyPicklist
            dropdownComponent={AwsClusterSshKeyPicklist}
            values={values}
            wizardContext={wizardContext}
            setWizardContext={setWizardContext}
          />
        )}
      </Row>
    </CapiSpec>
  )
}
