import { GridCellProps } from 'core/elements/grid/hooks/useGridRows'
import { ClusterSelector } from 'app/plugins/infrastructure/components/clusters/model'
import Text from 'core/elements/Text'
import Tooltip from 'core/elements/tooltip'
import SimpleLink from 'core/components/SimpleLink'
import { routes } from 'core/utils/routes'
import { isTransientStatus } from 'app/plugins/infrastructure/components/clusters/ClusterStatusUtils'
import React from 'react'

const upgradeClusterHelpText =
  'An upgrade to newer version of Kubernetes is now available for this cluster. Click here or select the upgrade action for the cluster to see more details.'

export default function K8sVersionCell({ item: cluster }: GridCellProps<ClusterSelector>) {
  const { version, canUpgrade, upgradingTo, taskStatus } = cluster
  return (
    <div>
      <Text variant="body2">{version}</Text>
      {canUpgrade && !upgradingTo && (
        <Tooltip message={upgradeClusterHelpText}>
          <SimpleLink src={routes.cluster.legacy.upgrade.path({ id: cluster.uuid })}>
            Upgrade Available
          </SimpleLink>
        </Tooltip>
      )}
      {!isTransientStatus(taskStatus) && upgradingTo && (
        <Tooltip message={upgradeClusterHelpText}>
          <SimpleLink src={routes.cluster.legacy.upgrade.path({ id: cluster.uuid })}>
            Continue Upgrade
          </SimpleLink>
        </Tooltip>
      )}
    </div>
  )
}
