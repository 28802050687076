import { AppSelector } from 'app/store'
import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import { IMachineDeploymentSelector, MachineDeployment } from './model'
import { NodeGroupTypeLabels } from '../model'
import { machinePoolSelector } from '../machine-pool/selectors'
import { allConfigSelector } from '../configs/selectors'
import { IAwsMachineTemplateSelector } from '../aws-machine-templates/model'
import { assocPath, find } from 'ramda'
import { IConfigSelector } from '../configs/model'
import { awsMachineTemplatesSelector } from '../aws-machine-templates/selectors'
import { emptyObj, isNilOrEmpty } from 'utils/fp'

const minNumWorkersAnnotationLabel = 'cluster.x-k8s.io/cluster-api-autoscaler-node-group-min-size'
const maxNumWorkersAnnotationLabel = 'cluster.x-k8s.io/cluster-api-autoscaler-node-group-max-size'

export const machineDeploymentSelector: AppSelector<IMachineDeploymentSelector[]> = createSharedSelector(
  getDataSelector<DataKeys.MachineDeployments>(DataKeys.MachineDeployments, ['clusterName']),
  awsMachineTemplatesSelector,
  allConfigSelector,
  (machineDeployments, awsMachineTemplates, configs): IMachineDeploymentSelector[] =>
    machineDeployments.map((md: MachineDeployment) => {
      const machineTemplate = find(
        (template: IAwsMachineTemplateSelector) =>
          template.name === md.spec?.template?.spec?.infrastructureRef?.name,
        awsMachineTemplates,
      )

      const config = find(
        (config: IConfigSelector) =>
          config.name === md.spec?.template?.spec?.bootstrap?.configRef?.name,
        configs,
      )

      // Autoscaling fields
      const annotations = md.metadata?.annotations
      const minWorkers = annotations?.[minNumWorkersAnnotationLabel]
      const maxWorkers = annotations?.[maxNumWorkersAnnotationLabel]

      return {
        ...md,
        uuid: md.metadata?.uid,
        name: md.metadata?.name,
        namespace: md.metadata?.namespace,
        clusterName: md.spec?.clusterName,
        type: NodeGroupTypeLabels.MachineDeployment,
        creationTimestamp: md.metadata?.creationTimestamp,
        k8sVersion: md.spec?.template?.spec?.version,
        phase: md.status?.phase,
        replicas: md.spec?.replicas,
        readyReplicas: md.status?.readyReplicas,
        availableReplicas: md.status?.availableReplicas,
        unavailableReplicas: md.status?.unavailableReplicas,
        autoscalingEnabled: !!minWorkers || !!maxWorkers,
        annotations,
        minCasSize: annotations?.[minNumWorkersAnnotationLabel],
        maxCasSize: annotations?.[maxNumWorkersAnnotationLabel],
        rollingUpdate: md.spec?.strategy?.rollingUpdate,
        infrastructureRef: md.spec?.template?.spec?.infrastructureRef,
        configRef: md.spec?.template?.spec?.bootstrap?.configRef,
        machineTemplate: machineTemplate,
        config: config,
        resources: [md, ...(machineTemplate?.resources || []), ...(config?.resources || [])].filter(
          (r) => !isNilOrEmpty(r),
        ),
      }
    }),
)

type MachineDeploymentsByNamespaceAndNameSelectorModel = {
  [namespace: string]: {
    [clusterName: string]: IMachineDeploymentSelector[]
  }
}

export const machineDeploymentsByNamespaceAndClusterSelector = createSharedSelector(
  machineDeploymentSelector,
  (machineDeployments): MachineDeploymentsByNamespaceAndNameSelectorModel =>
    machineDeployments.reduce((accum, md) => {
      const { namespace, clusterName } = md
      const machineDeployments = accum[namespace]?.[clusterName] || []
      machineDeployments.push(md)
      return assocPath([namespace, clusterName], machineDeployments, accum)
    }, emptyObj),
)

export const allNodeGroupsSelector = createSharedSelector(
  machineDeploymentSelector,
  machinePoolSelector,
  (machineDeployments, machinePools) => {
    return [...machineDeployments, ...machinePools]
  },
)
