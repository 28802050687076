import React from 'react'
import { pathStrOr } from 'utils/fp'
import useDataLoader from 'core/hooks/useDataLoader'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'
import { loadCloudProviderRegionDetails } from 'app/plugins/infrastructure/components/cloudProviders/actions'

interface Props {
  cloudProviderId: string
  cloudProviderRegionId: string
}

const blackListedFlavors = ['micro', 'nano', 'small']

const isBlackListedFlavor = (flavor) => !blackListedFlavors.some((name) => flavor.includes(name))

export default function AwsRegionFlavorPicklist({
  cloudProviderId,
  cloudProviderRegionId,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  const [details, loading] = useDataLoader(loadCloudProviderRegionDetails, {
    cloudProviderId,
    cloudProviderRegionId,
  })

  const flavors = pathStrOr([], '0.flavors', details).filter(isBlackListedFlavor)
  const items = flavors.map((x) => ({ label: x, value: x }))

  return <AsyncDropdown loading={loading} items={items} {...rest} />
}
