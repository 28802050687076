import React from 'react'
import TextField from 'core/components/validatedForm/TextField'

export default function CoreDnsAutoscalerParamFields({
  setFieldValue,
  values,
  setWizardContext = undefined,
  wizardContext = undefined,
}) {
  return (
    <>
      <TextField
        key="CoresPerReplica"
        id="CoresPerReplica"
        label="Cores Per Replica"
        initialValue={wizardContext?.CoresPerReplica || values?.CoresPerReplica || '256'}
        onChange={(value) => {
          setFieldValue && setFieldValue('CoresPerReplica')(value)
          setWizardContext && setWizardContext({ CoresPerReplica: value })
        }}
        type="number"
        min="0"
        returnAsString
        required
      />
      <TextField
        key="NodesPerReplica"
        id="NodesPerReplica"
        label="Nodes Per Replica"
        initialValue={wizardContext?.NodesPerReplica || values?.NodesPerReplica || '10'}
        onChange={(value) => {
          setFieldValue && setFieldValue('NodesPerReplica')(value)
          setWizardContext && setWizardContext({ NodesPerReplica: value })
        }}
        type="number"
        min="0"
        returnAsString
        required
      />
      <TextField
        key="MinReplicas"
        id="MinReplicas"
        label="Min Replicas"
        initialValue={wizardContext?.MinReplicas || values?.MinReplicas || '1'}
        onChange={(value) => {
          setFieldValue && setFieldValue('MinReplicas')(value)
          setWizardContext && setWizardContext({ MinReplicas: value })
        }}
        type="number"
        min="0"
        returnAsString
        required
      />
      <TextField
        key="MaxReplicas"
        id="MaxReplicas"
        label="Max Replicas"
        initialValue={wizardContext?.MaxReplicas || values?.MaxReplicas || '10'}
        onChange={(value) => {
          setFieldValue && setFieldValue('MaxReplicas')(value)
          setWizardContext && setWizardContext({ MaxReplicas: value })
        }}
        type="number"
        min="0"
        returnAsString
        required
      />
      <TextField
        key="PollPeriodSecs"
        id="PollPeriodSecs"
        label="Poll Period (seconds)"
        initialValue={wizardContext?.PollPeriodSecs || values?.PollPeriodSecs || '300'}
        onChange={(value) => {
          setFieldValue && setFieldValue('PollPeriodSecs')(value)
          setWizardContext && setWizardContext({ PollPeriodSecs: value })
        }}
        type="number"
        min="0"
        info="How often should the scaler check the size of the cluster to decide if the CoreDNS replica count should be scaled?"
        returnAsString
        required
      />
    </>
  )
}
