import React, { useCallback, useMemo, useState } from 'react'
import Text from 'core/elements/Text'
import TextField from 'core/components/validatedForm/TextField'
import RadioFields from 'core/components/validatedForm/radio-fields'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import { NodeUpdateStrategyTypes } from './CapiMachineDeploymentNodeUpdateStrategies'
import { validators } from 'core/utils/fieldValidators'

const labels = {
  count: 'Max Unavailable Nodes - Count',
  percentage: 'Max Unavailable Nodes - Percentage',
}

const getLabel = (updateStrategyType, value) =>
  `${labels[value]} ${updateStrategyType === value ? '*' : ''}`

export default function CapiAwsManagedMachinePoolNodeUpdateStrategies({
  workerNodeGroup,
  onChange,
}) {
  const classes = useStyles()
  const { id, updateConfig } = workerNodeGroup
  const [updateStrategyType, setUpdateStrategyType] = useState(NodeUpdateStrategyTypes.Count)
  const handleChanges = useCallback(
    (field) => (value) => {
      onChange('updateConfig')({ [field]: value })
    },
    [onChange],
  )

  const strategies = useMemo(
    () => [
      {
        label: getLabel(updateStrategyType, NodeUpdateStrategyTypes.Count),
        value: NodeUpdateStrategyTypes.Count,
        extraContent: (
          <TextField
            id={`${id}-maxUnavailableNodes`}
            placeholder="Count"
            value={updateConfig?.maxUnavailable}
            onChange={(value) => handleChanges('maxUnavailable')(value)}
            type="number"
            disabled={updateStrategyType === NodeUpdateStrategyTypes.Percentage}
            required={updateStrategyType === NodeUpdateStrategyTypes.Count}
            info="Number of nodes that can be updated in parallel"
            min="1"
            max="100"
            validations={[validators.rangeValue(1, 100)]}
          />
        ),
      },
      {
        label: getLabel(updateStrategyType, NodeUpdateStrategyTypes.Percentage),
        value: NodeUpdateStrategyTypes.Percentage,
        extraContent: (
          <TextField
            id={`${id}-maxUnavailablePercentage`}
            placeholder="Percentage"
            value={updateConfig?.maxUnavailablePercentage}
            valida
            onChange={(value) => handleChanges('maxUnavailablePercentage')(value)}
            disabled={updateStrategyType === NodeUpdateStrategyTypes.Count}
            required={updateStrategyType === NodeUpdateStrategyTypes.Percentage}
            type="number"
            info="Percentage of nodes that can be updated in parallel"
            min="1"
            max="100"
            step="10"
            validations={[validators.rangeValue(1, 100)]}
          />
        ),
      },
    ],
    [updateStrategyType, updateConfig],
  )
  return (
    <>
      <Text variant="caption1">Nodes Update Strategy</Text>
      <RadioFields
        id={`${id}-updateStrategyType`}
        className={classes.radioFields}
        value={updateStrategyType}
        options={strategies}
        onChange={(value: NodeUpdateStrategyTypes) => {
          setUpdateStrategyType(value)
        }}
      />
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  radioFields: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    alignItems: 'center',
    gridGap: theme.spacing(3),
  },
}))
