import React from 'react'
import { ICapiClusterSelector } from '../../model'
import CapiClusterOverviewAlarms from './CapiClusterOverviewAlarms'
import CapiClusterOverviewResources from './CapiClusterOverviewResources'
import CapiClusterOverviewSummary from './CapiClusterOverviewSummary'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import CapiClusterOverviewAddons from './CapiClusterOverviewAddons'
import CapiClusterOverviewEvents from './CapiClusterOverviewEvents'
import Progress from 'core/components/progress/Progress'

export default function CapiClusterOverview({
  cluster,
  loading,
}: {
  cluster: ICapiClusterSelector
  loading: boolean
}) {
  const classes = useStyles()
  return (
    <Progress loading={loading}>
      <div className={classes.container}>
        <CapiClusterOverviewSummary cluster={cluster} />
        <CapiClusterOverviewResources cluster={cluster} />
        <CapiClusterOverviewAlarms cluster={cluster} />
        <CapiClusterOverviewEvents clusterName={cluster?.name} namespace={cluster?.namespace} />
        <CapiClusterOverviewAddons cluster={cluster} />
      </div>
    </Progress>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '800px auto auto',
  },
}))
