import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import { nodeHardwareRequirements, ClusterType, HardwareType } from './constants'
import { CloudProviderRequirementTD } from 'k8s/components/common/CloudProviderRequirementTD'

export default function BareOsMinimumRequirements() {
  const classes = useStyles()

  return (
    <>
      <Text variant="subtitle2">Minimum Hardware Requirements</Text>
      <table className={classes.table}>
        <tr>
          <th>
            <Text variant="caption1">Single Master Cluster</Text>
          </th>
          <th>
            <Text variant="caption1" component="span">
              Multi-node Clusters
              <Text variant="body2" component="span">
                (for each)
              </Text>
            </Text>
          </th>
        </tr>
        <tbody>
          {/* CPUs */}
          <tr>
            <CloudProviderRequirementTD
              text={`${
                nodeHardwareRequirements[ClusterType.SingleNodeCluster][HardwareType.CPU]
              } CPUs`}
            />
            <CloudProviderRequirementTD
              text={`${
                nodeHardwareRequirements[ClusterType.MultiNodeCluster][HardwareType.CPU]
              } CPUs`}
            />
          </tr>
          {/* RAM */}
          <tr>
            <CloudProviderRequirementTD
              text={`${
                nodeHardwareRequirements[ClusterType.SingleNodeCluster][HardwareType.RAM]
              } GB RAM`}
            />
            <CloudProviderRequirementTD
              text={`${
                nodeHardwareRequirements[ClusterType.MultiNodeCluster][HardwareType.RAM]
              } GB RAM`}
            />
          </tr>
          {/* HDD */}
          <tr>
            <CloudProviderRequirementTD
              text={`${
                nodeHardwareRequirements[ClusterType.SingleNodeCluster][HardwareType.Disk]
              } GB HDD`}
            />
            <CloudProviderRequirementTD
              text={`${
                nodeHardwareRequirements[ClusterType.MultiNodeCluster][HardwareType.Disk]
              } GB HDD`}
            />
          </tr>
        </tbody>
      </table>
    </>
  )
}
const useStyles = makeStyles<Theme>((theme) => ({
  table: {
    minWidth: '100%',
    tableLayout: 'fixed',
    borderSpacing: 0,
    marginTop: theme.spacing(2),
    border: `1px solid ${theme.components.selectableCard.border}`,
    borderRadius: 4,

    '& tr td, & tr th': {
      padding: theme.spacing(1),
      border: 0,
      borderColor: theme.components.selectableCard.border,
      borderStyle: 'solid',
    },
    '& tr th': {
      textAlign: 'start',
      backgroundColor: theme.components.table.hoverBackground,
      '&:first-child': {
        borderRightWidth: '1px',
      },
    },
    '& tr td': {
      '&:first-child': {
        borderTopWidth: '1px',
        borderRightWidth: '1px',
      },
      '&:last-child': {
        borderTopWidth: '1px',
      },
    },
  },
}))
