import { dissocPath, groupBy, pathSatisfies } from 'ramda'
import { EventsResponse, Subnet } from '../../model'
import { hasPathStr } from 'utils/fp'

export function getPMKMangedSubnetReviewData(subnets: Subnet[]) {
  const reviewData = {
    publicSubnetsCidr: [],
    privateSubnetsCidr: [],
    privateSubnetsCount: 0,
    publicSubnetsCount: 0,
  }

  const reduceFn = (acc, subnet) => {
    const data = acc[subnet?.availabilityZone] ? acc[subnet?.availabilityZone] : { ...reviewData }

    if (subnet.isPublic) {
      data.publicSubnetsCidr = [...data.publicSubnetsCidr, ...[subnet.cidrBlock]]
      data.publicSubnetsCount++
    } else {
      data.privateSubnetsCidr = [...data.privateSubnetsCidr, ...[subnet.cidrBlock]]
      data.privateSubnetsCount++
    }

    acc[subnet?.availabilityZone] = data
    return acc
  }

  return subnets.reduce(reduceFn, {})
}
export function getSelfMangedSubnetReviewData(subnets: Subnet[]) {
  return groupBy((subnet: Subnet) => subnet.availabilityZone)(subnets)
}

export function getAccountIdfromAwsArn(arn: string): string {
  const regex = /^.*:(\d+):(root|(role\/.*))$/i

  //`arn:aws:iam::123456789012:user/Development/product_1234/*`
  const substring = `$1`
  return arn.replace(regex, substring)
}

export function filterCapiEventsByClusterName(data: EventsResponse, clusterName: string) {
  const events = data.items
  const clusterNameMatcher = (name) => name.includes(clusterName)
  return events.filter((item) => pathSatisfies(clusterNameMatcher, ['regarding', 'name'], item))
}

export const cleanResource = (unwantedPaths = ['metadata.managedFields']) => (resource) => {
  return unwantedPaths.reduce((accum, pathStr) => {
    if (hasPathStr(pathStr, accum)) {
      return dissocPath(pathStr.split('.'), accum)
    }
    return accum
  }, resource)
}
