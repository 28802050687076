import React, { useCallback, useState } from 'react'
import ExternalLink from 'core/components/ExternalLink'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import Button from 'core/elements/button'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import Checkbox from 'core/components/validatedForm/CheckboxField'
import Progress from 'core/components/progress/Progress'
import useDataUpdater from 'core/hooks/useDataUpdater'
import { updateRemoteSupport } from 'app/plugins/infrastructure/components/common/actions'
import { remoteMonitoringDocsLink } from 'k8s/links'
import { stopPropagation } from 'utils/fp'
import { GridDialogProps } from 'core/elements/grid/helpers/getGridDialogButton'
import { INodesSelector } from 'app/plugins/infrastructure/components/nodes/model'

export default function RemoteSupportDialog({
  rows: [node],
  onClose,
}: GridDialogProps<INodesSelector>) {
  const [updateNode, updatingNode] = useDataUpdater(updateRemoteSupport, onClose)
  const [enableSupport, setEnableSupport] = useState(node.combined.supportRole)
  const handleSubmit = useCallback(
    async ({ enableSupport }) => {
      // If no change, just close the modal
      if (enableSupport === node.combined.supportRole) {
        return onClose()
      }
      await updateNode({ id: node.uuid, enableSupport })
    },
    [node],
  )

  const initialValues = { enableSupport }
  return (
    <Dialog open onClose={onClose} onClick={stopPropagation}>
      <DialogTitle>Configure Advanced Remote Support</DialogTitle>
      <ValidatedForm
        elevated={false}
        initialValues={initialValues}
        fullWidth
        onSubmit={handleSubmit}
      >
        <Progress loading={updatingNode} renderContentOnMount>
          <DialogContent>
            <p>
              You may enable or disable Advanced Remote Support on this node. Please refer to the
              following <ExternalLink url={remoteMonitoringDocsLink}>article</ExternalLink> for more
              information.
            </p>
            <Checkbox
              id="enableSupport"
              onChange={() => setEnableSupport(!enableSupport)}
              label="Enable Advanced Remote Support"
            />
          </DialogContent>
          <DialogActions>
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit">Update Node</Button>
          </DialogActions>
        </Progress>
      </ValidatedForm>
    </Dialog>
  )
}
