import getDataSelector from 'core/utils/getDataSelector'
import DataKeys from 'k8s/DataKeys'
import { assocPath } from 'ramda'
import { createSharedSelector } from 'core/utils/selectorHelpers'

export const k8sNodesSelector = createSharedSelector(
  getDataSelector<DataKeys.K8sNodes>(DataKeys.K8sNodes),
  (k8sNodes) => {
    return k8sNodes.map((node) => {
      return {
        ...node,
      }
    })
  },
)

export const k8sNodesByClusterIdAandNodeNameSelector = createSharedSelector(
  k8sNodesSelector,
  (k8sNodes) => {
    return k8sNodes.reduce((accum, node) => {
      const { name, clusterId } = node
      return assocPath([clusterId, name], node, accum)
    }, {})
  },
)
