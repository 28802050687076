import AsyncDropdown from 'core/elements/dropdown/AsyncDropdown'
import React, { useState } from 'react'
import { pathStrOr, projectAs } from 'utils/fp'
import { groupBy, prop, propEq } from 'ramda'
import useDataLoader from 'core/hooks/useDataLoader'
import DropdownField from 'core/components/validatedForm/DropdownField'
import { loadCloudProviderRegionDetails } from 'app/plugins/infrastructure/components/cloudProviders/actions'

// @todo fix these typings
interface Props {
  azs: any[]
  type: 'public' | 'private'
  cloudProviderId: string
  cloudProviderRegionId: string
  vpcId: string
  onChange: (value: unknown) => void
  disabled?: boolean
}

/* Displays multiple picklists to allow the user to specify a subnet for each AZ in the VPC */
export default function AwsZoneVpcMappings({
  azs = [],
  type,
  cloudProviderId,
  cloudProviderRegionId,
  vpcId,
  onChange,
  disabled,
}: Props) {
  if (!vpcId) return null
  const [details] = useDataLoader(loadCloudProviderRegionDetails, {
    cloudProviderId,
    cloudProviderRegionId,
  })
  const [subnetMappings, setSubnetMappings] = useState({})

  const vpcs = pathStrOr([], '0.vpcs', details)
  const vpc = vpcs.find(propEq('VpcId', vpcId))

  if (!vpc) return null

  const isPublic = type === 'public'

  const subnets = pathStrOr([], 'Subnets', vpc)
  const options = subnets.filter(
    (x) => x.MapPublicIpOnLaunch === isPublic && azs.includes(x.AvailabilityZone),
  )
  const subnetsByAz = groupBy(prop('AvailabilityZone'), options)

  const handleChange = (az) => (subnetId) => {
    const mappings = { ...subnetMappings, [az]: subnetId }
    setSubnetMappings(mappings)
    onChange && onChange(Object.values(mappings))
  }

  return (
    <>
      {azs.map((az) => (
        <DropdownField
          DropdownComponent={AsyncDropdown}
          disabled={disabled}
          label={`Availability Zone (${type}): ${az}`}
          key={`az-subnet-${type}-${az}-${az.CidrBlock}`}
          id={`az-subnet-${type}-${az}`}
          items={
            subnetsByAz?.[az]
              ? projectAs({ label: 'CidrBlock', value: 'SubnetId' }, subnetsByAz[az])
              : []
          }
          onChange={handleChange(az)}
          required
        />
      ))}
    </>
  )
}
