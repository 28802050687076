import { toPairs } from 'ramda'
import { combineHost } from 'app/plugins/infrastructure/components/common/combineHosts'
import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import {
  HostTypes,
  ICombinedHostSelector,
} from 'app/plugins/infrastructure/components/common/model'
import { ExtensionsClass, Host } from 'api-client/resmgr.model'
import { emptyArr } from 'utils/fp'
import { k8sNodesByClusterIdAandNodeNameSelector } from 'app/plugins/infrastructure/components/nodes/k8sNodes-selectors'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import { clientStoreKey } from 'core/client/clientReducers'

export const getIpPreview = (ips: string[] = emptyArr as any) => {
  if (!Array.isArray(ips)) {
    return ''
  }
  // Get first IP that does not start with 192
  return ips.find((ip) => ip.substring(0, 3) !== '192') || ips[0]
}

export const getNetworkInterfaces = (node: Host) => {
  const extensions = node?.extensions
  const ifaceMap = extensions?.interfaces?.data?.iface_info
  // Pair the interface name (obj key) to the data (obj value)
  const pairedIfaces: any = toPairs(ifaceMap)
  const ifaceList = pairedIfaces.map((pair) => {
    // Some interfaces have multiple IP addresses
    const ifaces = pair[1]?.ifaces
    return ifaces.map((iface) => ({
      name: pair[0],
      mac: pair[1]?.mac,
      ip: iface.addr,
      netmask: iface.netmask,
      label: `${pair[0]}: ${iface.addr}`,
    }))
  })
  return ifaceList.flat() // [[interface, ip], [interface2, ip2], ...]
}

export const resMgrHostsSelector = createSharedSelector(
  getDataSelector<DataKeys.ResMgrHosts>(DataKeys.ResMgrHosts),
  (hosts) => {
    return hosts.map((item) => {
      const extensions = item?.extensions as ExtensionsClass
      return {
        ...item,
        resourceType: HostTypes.Resmgr,
        ipPreview: getIpPreview(extensions?.ip_address?.data),
        networkInterfaces: getNetworkInterfaces(item),
        ovsBridges: extensions?.interfaces?.data.ovs_bridges || (emptyArr as any),
      }
    })
  },
)

export const resMgrCombinedHostsSelector = createSharedSelector(resMgrHostsSelector, (hosts) => {
  const hostsById = {}
  hosts.forEach((item) => {
    const host = {
      [HostTypes.Resmgr]: item,
    }
    // all of the combined host annotation is based on resmgr
    // so we can do the combineHost call here
    const combined = combineHost(host)
    hostsById[item.id] = { ...host, ...combined }
  })
  return hostsById
})

export const combinedHostsSelector = createSharedSelector(
  getDataSelector<DataKeys.Nodes>(DataKeys.Nodes),
  resMgrCombinedHostsSelector,
  k8sNodesByClusterIdAandNodeNameSelector,
  (rawNodes, resMgrHostsById, k8sNodesById) => {
    const hostsById: ICombinedHostSelector = {}
    for (const hostId in resMgrHostsById) {
      const host = resMgrHostsById[hostId]
      const k8sNode =
        k8sNodesById[host?.qbert?.clusterUuid]?.[host?.hostname] ||
        k8sNodesById[host?.qbert?.clusterUuid]?.[host?.primaryIp]
      hostsById[hostId] = { ...host, [HostTypes.K8s]: k8sNode }
    }

    rawNodes.forEach((node) => {
      hostsById[node.uuid] = hostsById[node.uuid] || ({} as any)
      hostsById[node.uuid][HostTypes.Qbert] = node
      hostsById[node.uuid][HostTypes.K8s] =
        k8sNodesById[node.clusterUuid]?.[node.name] ||
        k8sNodesById[node.clusterUuid]?.[node.primaryIp]
    })

    // Convert it back to array form
    return hostsById
  },
)
export const qbertEndpointSelector = createSharedSelector(
  (state) => state?.[clientStoreKey]?.endpoints?.qbert,
  (qbertEndpoint) => qbertEndpoint,
)
