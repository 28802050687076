import React from 'react'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import { luigiInfo } from 'app/plugins/infrastructure/components/clusters/constants'

const NetworkPluginOperator = ({ wizardContext, setWizardContext, disabled = false }) => (
  <CheckboxField
    id="deployLuigiOperator"
    label="Network Plugin Operator"
    info={luigiInfo}
    onChange={(value) => setWizardContext({ deployLuigiOperator: value })}
    value={wizardContext.deployKubevirt ? true : !!wizardContext.deployLuigiOperator}
    disabled={disabled || wizardContext.deployKubevirt}
  />
)

export default NetworkPluginOperator
