import ApiClient from 'api-client/ApiClient'
import Bugsnag from 'utils/bugsnag'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import UpdateAction from 'core/actions/UpdateAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import DeleteAction from 'core/actions/DeleteAction'

const { sunpike } = ApiClient.getInstance()

export const machinePoolActions = ActionsSet.make<DataKeys.MachinePools>({
  uniqueIdentifier: 'metadata.uid',
  entityName: entityNamesByKey[DataKeys.MachinePools],
  cacheKey: DataKeys.MachinePools,
  cache: false,
})

export const listMachinePools = machinePoolActions.add(
  new ListAction<DataKeys.MachinePools>(async ({ clusterName }: { clusterName: string }) => {
    Bugsnag.leaveBreadcrumb('Attempting to get Machine Pools', { clusterName })
    return sunpike.getMachinePools(clusterName)
  }),
)

export const updateMachinePool = machinePoolActions.add(
  new UpdateAction<DataKeys.MachinePools, { namespace: string; name: string; body: unknown }>(
    async ({ namespace, name, body }) => {
      Bugsnag.leaveBreadcrumb('Attempting to update Machine Pools', { namespace, name, body })
      return sunpike.updateMachinePool(namespace, name, body)
    },
  ),
)

export const deleteMachinePool = machinePoolActions.add(
  new DeleteAction<DataKeys.MachinePools, { namespace: string; name: string }>(
    async ({ namespace, name }) => {
      Bugsnag.leaveBreadcrumb('Attempting to delete Machine Pool', { namespace, name })
      await sunpike.deleteMachinePool(namespace, name)
    },
  ),
)
