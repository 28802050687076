import React, { useMemo } from 'react'
import { Dialog } from '@material-ui/core'
import Button from 'core/elements/button'
import Text from 'core/elements/Text'
import Card from 'core/elements/card'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/styles'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { deleteClusterAddon } from '../new-actions'
import { updateCluster } from '../../newActions'
import { ClusterAddonType } from '../model'

export default function DisableAddonDialog({ addon, open, onClose, cluster }) {
  const classes = useStyles()
  const { update: deleteAddon, updating: deletingAddon } = useUpdateAction(deleteClusterAddon)
  const { update: updateClusterObj, updating: updatingCluster } = useUpdateAction(updateCluster)

  const disableAddon = async () => {
    if (addon?.type === ClusterAddonType.EtcdBackup) {
      await updateClusterObj({
        uuid: addon?.clusterId,
      })
    } else {
      await deleteAddon(addon)
    }
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <Card
        title="Disable this add-on?"
        footer={
          <div className={classes.actions}>
            <Button onClick={onClose} variant="secondary">
              Cancel
            </Button>
            <Button
              onClick={disableAddon}
              variant="tertiary"
              loading={deletingAddon || updatingCluster}
            >
              Yes, disable
            </Button>
          </div>
        }
      >
        <Text className={classes.cardText} variant="body2">
          You will not be able to recover it.
        </Text>
      </Card>
    </Dialog>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: 16,
  },
  cardText: {
    padding: theme.spacing(1, 0, 3, 0),
  },
}))
