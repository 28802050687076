import React, { useCallback, useEffect, useMemo } from 'react'
import useReactRouter from 'use-react-router'
import { pipe, when, always, isNil, omit, mergeRight } from 'ramda'
import { emptyObj, objToKeyValueArr, keyValueArrToObj } from 'utils/fp'
import useDataUpdater from 'core/hooks/useDataUpdater'
import FormWrapper from 'core/components/FormWrapper'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import { clusterActions } from './actions'
import Name from './form-components/NameField'
import TagsField from './form-components/TagsField'
import DocumentMeta from 'core/components/DocumentMeta'
import { makeStyles } from '@material-ui/styles'
import { FormFieldCard } from 'core/components/validatedForm/FormFieldCard'
import { Divider } from '@material-ui/core'
import Text from 'core/elements/Text'
import KubernetesVersionField from './form-components/KubernetesVersionField'
import PrivilegedContainers from './form-components/PrivilegedContainersField'
import AllowWorkloadsOnMasterField from './form-components/AllowWorkloadsOnMasterField'
import NetworkStack from './form-components/NetworkStackField'
import { CloudProviders } from 'app/plugins/infrastructure/components/cloudProviders/model'
import { NetworkStackTypes } from './constants'
import Theme from 'core/themes/model'
import { routes } from 'core/utils/routes'
import SubmitButton from 'core/components/SubmitButton'
import useParams from 'core/hooks/useParams'
import ContainerRuntimePicklist from './form-components/ContainerRuntimePicklist'
import DropdownField from 'core/components/validatedForm/DropdownField'
import { monitoringFieldId } from './cluster-addons/monitoring'

// Hide pf9-system:monitoring tag from the display
// bc that tag should be handled completely by appbert.
// If the tag exists, we do not want to remove it
// so just hide it from view.
const tagsToOmit = ['pf9-system:monitoring']

export default function GeneralEditClusterPage({ cluster, loading }) {
  const classes = useStyles()
  const { history } = useReactRouter()
  const { params, updateParams } = useParams<
    Partial<typeof initialValues & { containerRuntime: unknown }>
  >({})
  const [update, updatingCluster] = useDataUpdater(clusterActions.update)

  const initialValues = useMemo(
    () =>
      pipe(when(isNil, always(emptyObj)), ({ tags = {}, ...values }) => ({
        ...values,
        clusterId: values.uuid,
        name: values.name,
        tags: objToKeyValueArr(tags),
        networkStack:
          values.calicoIPv4 === 'autodetect' ? NetworkStackTypes.IPv4 : NetworkStackTypes.IPv6,
        region: values.cloudProperties?.region,
      }))(cluster),
    [cluster],
  )

  useEffect(() => {
    updateParams(initialValues)
  }, [initialValues])

  const handleSubmit = useCallback(
    async ({ tags, ...values }) => {
      await update({
        ...omit(['numMinWorkers', 'numMaxWorkers'], values),
        // The cluster ID is not present in the form as a field so it won't be passed as
        // a value to the submit function
        clusterId: initialValues.clusterId,
        tags: keyValueArrToObj(tags),
      })

      history.push(routes.cluster.legacy.list.path())
    },
    [update, initialValues],
  )

  return (
    <div>
      <DocumentMeta title="Edit Cluster" bodyClasses={['form-view']} />
      <FormWrapper
        loading={loading || updatingCluster}
        message={loading ? 'Loading cluster ...' : 'Submitting form...'}
        backUrl={routes.cluster.legacy.list.path()}
        renderContentOnMount={!loading}
      >
        <ValidatedForm
          title="Basic Details"
          classes={{ root: classes.validatedFormContainer }}
          formActions={<SubmitButton>Update Cluster</SubmitButton>}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          elevated={false}
          withAddonManager
        >
          <>
            <FormFieldCard title="Cluster Name">
              <Name setWizardContext={updateParams} disabled />
            </FormFieldCard>
            <FormFieldCard title="Cluster Settings">
              <KubernetesVersionField
                wizardContext={params}
                setWizardContext={updateParams}
                disabled
              />
              <DropdownField
                DropdownComponent={ContainerRuntimePicklist}
                id="containerRuntime"
                label="Container Runtime"
                tooltip="The container runtime for the cluster"
                value={params.containerRuntime}
                required
                disabled
              />
              {params.containerRuntime === 'docker' && (
                <Text variant="body2" className={classes.errorText}>
                  As of Kubernetes 1.23, Docker Container Runtime will be deprecated. You can
                  migrate to containerd during your next cluster upgrade.
                </Text>
              )}
              {cluster?.cloudProviderType === CloudProviders.BareOS && (
                <>
                  <Divider className={classes.divider} />
                  <NetworkStack wizardContext={params} setWizardContext={updateParams} disabled />
                </>
              )}
              <Divider className={classes.divider} />
              <Text variant="caption1">Application & Container Settings</Text>
              <PrivilegedContainers
                wizardContext={params}
                setWizardContext={updateParams}
                disabled
              />
              <AllowWorkloadsOnMasterField setWizardContext={updateParams} disabled />
            </FormFieldCard>
            <FormFieldCard title="Advanced Configuration">
              <TagsField info="Edit tag metadata on this cluster" blacklistedTags={tagsToOmit} />
            </FormFieldCard>
          </>
        </ValidatedForm>
      </FormWrapper>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  validatedFormContainer: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(3, 0),
  },
  errorText: {
    color: theme.palette.red[500],
  },
}))
