import { AppSelector } from 'app/store'
import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import { IAwsManagedMachinePoolSelector, AwsManagedMachinePool } from './model'
import { NodeGroupTypeLabels } from '../model'
import { Phase } from '../machine-deployment/model'

export const awsManagedMachinePoolSelector: AppSelector<IAwsManagedMachinePoolSelector[]> = createSharedSelector(
  getDataSelector<DataKeys.AwsManagedMachinePools>(DataKeys.AwsManagedMachinePools),
  (awsManagedMachinePools): IAwsManagedMachinePoolSelector[] =>
    awsManagedMachinePools.map((mp: AwsManagedMachinePool) => {
      return {
        ...mp,
        uuid: mp.metadata?.uid,
        name: mp.metadata?.name,
        namespace: mp.metadata?.namespace,
        type: NodeGroupTypeLabels.AWSManagedMachinePool,
        creationTimestamp: mp.metadata?.creationTimestamp,
        availabilityZones: mp.spec?.availabilityZones,
        scaling: mp.spec?.scaling,
        instanceType: mp.spec?.instanceType,
        updateConfig: mp.spec?.updateConfig,
        capacityType: mp.spec?.capacityType,
        amiType: mp.spec?.amiType,
        amiVersion: mp.spec?.amiVersion,
        ready: mp.status?.ready,
        failureMessage: mp.status?.failureMessage,
        phase: mp.status?.ready ? Phase.Running : Phase.Failed,
        resources: [mp],
      }
    }),
)
