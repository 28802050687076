import React, { useMemo, useState } from 'react'
import { pathStrOr, projectAs } from 'utils/fp'
import { propEq } from 'ramda'
import MultiDropdown from 'core/elements/dropdown/MultiDropdown'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { listCloudProviderRegionDetails } from 'app/plugins/infrastructure/components/cloudProviders/new-actions'
import { cloudProviderRegionDetailsSelector } from 'app/plugins/infrastructure/components/cloudProviders/selectors'
import DropdownField from 'core/components/validatedForm/DropdownField'

// @todo fix these typings
interface Props {
  label: string
  az: string
  type: string
  cloudProviderId: string
  cloudProviderRegionId: string
  vpcId: string
  onChange: (value: string[]) => void
}

/* Displays multiple picklists to allow the user to specify a subnet for each AZ in the VPC */
export default function CapiAwsSubnetsMultiDropdown({
  az,
  label,
  type,
  cloudProviderId,
  cloudProviderRegionId,
  vpcId,
  onChange,
  ...rest
}: Props) {
  if (!vpcId) return null
  const { loading: loadingCloudProviderRegionDetails } = useListAction(
    listCloudProviderRegionDetails,
    {
      params: { cloudProviderId, cloudProviderRegionId },
    },
  )

  const regionDetails = useSelectorWithParams(cloudProviderRegionDetailsSelector, {
    cloudProviderId,
    cloudProviderRegionId,
  })

  const subnets = useMemo(() => {
    const vpcs = pathStrOr([], '0.vpcs', regionDetails)
    const vpc = vpcs.find(propEq('VpcId', vpcId))

    if (!vpc) return []
    const isPublic = type === 'public'
    const subnets = pathStrOr([], 'Subnets', vpc)
    const options = subnets.filter(
      (x) => x.MapPublicIpOnLaunch === isPublic && az === x.AvailabilityZone,
    )
    return projectAs({ label: 'SubnetId', value: 'SubnetId' }, options)
  }, [regionDetails, vpcId])

  return (
    <>
      <DropdownField
        DropdownComponent={MultiDropdown}
        name={`Availability Zone (${type}): ${az}`}
        key={`subnet-${type}-${az}-${vpcId}`}
        id={`subnet-${type}-${az}-${vpcId}`}
        label={label}
        items={subnets}
        onChange={onChange}
        loading={loadingCloudProviderRegionDetails}
        enableSearch
        {...rest}
      />
    </>
  )
}
