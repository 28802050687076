import React from 'react'
import { pathStrOr } from 'utils/fp'
import useDataLoader from 'core/hooks/useDataLoader'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'
import { loadCloudProviderRegionDetails } from 'app/plugins/infrastructure/components/cloudProviders/actions'

// @todo fix these typings
interface Props {
  azs: unknown[]
  cloudProviderId: string
  cloudProviderRegionId: string
}

export default function AwsClusterVpcPicklist({
  azs,
  cloudProviderId,
  cloudProviderRegionId,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  const [details, loading] = useDataLoader(loadCloudProviderRegionDetails, {
    cloudProviderId,
    cloudProviderRegionId,
  })

  const vpcs = pathStrOr([], '0.vpcs', details)

  // Filter out any VPC's that don't have valid subnets for all the AZ's the user
  // previously selected
  const hasAllAzs = (vpc) => {
    const azsInVpc = pathStrOr([], 'Subnets', vpc).map((x) => x.AvailabilityZone)
    return azs.every((az) => azsInVpc.includes(az))
  }

  const toOption = (vpc) => ({ label: `${vpc.VpcName}-${vpc.CidrBlock}`, value: vpc.VpcId })

  const options = vpcs.filter(hasAllAzs).map(toOption)

  return <AsyncDropdown {...rest} loading={loading} items={options} />
}
