import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { ApiServerLoadBalancerTypes, SchemeTypes } from '../../../model'
import CapiSpec from '../CapiSpec'
import DropdownField from 'core/components/validatedForm/DropdownField'
import Dropdown from 'core/elements/dropdown'
import BooleanPicklistField from '../../../form-components/BooleanPicklistField'
import Row from 'core/containers/Row'
import { merge } from 'ramda'

interface Props {
  wizardContext: any
  setWizardContext: any
  values: any
}

export default function CapiAwsLoadBalancerConfig({
  wizardContext,
  setWizardContext,
  values,
}: Props) {
  const classes = useStyles()

  const apiServerLoadBalancerTypeOptions = [
    {
      label: 'AWS Classic Load Balancer',
      value: ApiServerLoadBalancerTypes.AwsClassicLoadBalancer,
    },
    // {
    //   label: 'AWS Network Load Balancer',
    //   value: ApiServerLoadBalancerTypes.AwsNetworkLoadBalancer, // not supported yet
    // },
  ]

  const schemeOptions = [
    {
      label: 'Internet Facing',
      value: SchemeTypes.InternetFacing,
    },
    {
      label: 'Internal',
      value: SchemeTypes.Internal,
    },
  ]

  const crossZoneLoadBalancingInfo =
    'CroossZoneLoadBalancing enables the classic ELB cross availability zone balancing. With cross-zone load balancing, each load balancer node for your Classic Load Balancer distributes Availability Zone requests evenly across the registered instances in all enabled Avalability Zones. If cross-zone load balancing is disabled, each load balancer node distributes requests evenly across the registered instances in it Avalability Zone only. Defaults to false.'
  const schemeInfo =
    'Internet Facing load balancers can route requests from clients over the internet. The nodes of an internal load balancer have only private IP adresses. Whereas, internal load balancers can only route requests from clients with access to the VPC of the load balancer.'

  return (
    <CapiSpec title="AWS Load Balancer Configuration">
      <Row minItemWidth="300">
        {/* AWS Load Balancer Configuration */}
        <DropdownField
          DropdownComponent={Dropdown}
          id="apiServerLoadBalancerType"
          value={wizardContext?.controlPlaneLoadBalancer?.apiServerLoadBalancerType}
          onChange={(value) =>
            setWizardContext({
              controlPlaneLoadBalancer: merge(wizardContext?.controlPlaneLoadBalancer)({
                apiServerLoadBalancerType: value,
              }),
            })
          }
          label="API Server Load Balancer Type"
          items={apiServerLoadBalancerTypeOptions}
          required
        />
      </Row>
      <Row minItemWidth="300" gap={24}>
        <DropdownField
          DropdownComponent={Dropdown}
          id="scheme"
          value={wizardContext.controlPlaneLoadBalancer?.scheme}
          onChange={(value) =>
            setWizardContext({
              controlPlaneLoadBalancer: merge(wizardContext?.controlPlaneLoadBalancer)({
                scheme: value,
              }),
            })
          }
          label="Scheme"
          items={schemeOptions}
          tooltip={schemeInfo}
          required
        />
        <BooleanPicklistField
          id="crossZoneLoadBalancing"
          label="Cross Zone Load Balancing"
          value={wizardContext.controlPlaneLoadBalancer?.crossZoneLoadBalancing}
          onChange={(value) =>
            setWizardContext({
              controlPlaneLoadBalancer: merge(wizardContext?.controlPlaneLoadBalancer)({
                crossZoneLoadBalancing: value,
              }),
            })
          }
          tooltip={crossZoneLoadBalancingInfo}
        />
      </Row>
    </CapiSpec>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({}))
