import React, { useMemo } from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'
import { compareVersions } from 'k8s/util/helpers'

const items = [
  { label: 'Docker', value: 'docker' },
  { label: 'Containerd', value: 'containerd' },
]

const disabledItems = [{ label: 'Docker', value: 'docker' }]

interface Props {
  kubeRoleVersion?: string
}

export default function ContainerRuntimePicklist({
  onChange,
  name = 'containerRuntime',
  label = 'Container Runtime',
  kubeRoleVersion,
  disabled,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  const isNewK8sVersion = useMemo(() => {
    if (!kubeRoleVersion) {
      return true
    }
    if (compareVersions(kubeRoleVersion, '1.21') < 0) {
      onChange('docker')
      return false
    }
    return true
  }, [kubeRoleVersion])

  return (
    <AsyncDropdown
      name={name}
      label={label}
      onChange={onChange}
      items={isNewK8sVersion ? items : disabledItems}
      showAll={false}
      disabled={!isNewK8sVersion || disabled}
      {...rest}
    />
  )
}
