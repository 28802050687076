import { GridCellProps } from 'core/elements/grid/hooks/useGridRows'
import { ClusterSelector } from 'app/plugins/infrastructure/components/clusters/model'
import Text from 'core/elements/Text'
import React from 'react'

export default function CloudProviderCell({
  item: { cloudProviderType, cloudProviderName },
}: GridCellProps<ClusterSelector>) {
  return <Text variant="body2">{cloudProviderType === 'local' ? '' : cloudProviderName}</Text>
}
