import DropdownField from 'core/components/validatedForm/DropdownField'
import { filter } from 'ramda'
import React, { useCallback, useState } from 'react'
import { Subnet } from '../../../model'
import CapiAwsAvailiabilityZoneChooser from './CapiAwsAvailabilityZoneChooser'

const minEksAwsAzsError = 'Minimum (2) Availability Zones are required'

const CapiAwsAvailabilityZoneField = ({
  id = 'azs',
  wizardContext,
  setWizardContext = undefined,
  value = undefined,
  onChange = undefined,
  isEksCluster,
  ...rest
}) => {
  const [error, setError] = useState('')
  const onAzsChange = useCallback(
    (azs) => {
      // keeping only subnets for selected azs
      const subnets = filter(
        (subnet: Subnet) => azs.includes(subnet.availabilityZone),
        wizardContext?.subnets,
      )
      isEksCluster && azs.length < 2 ? setError(minEksAwsAzsError) : setError('')
      setWizardContext({ [id]: azs, subnets })
    },
    [wizardContext, setWizardContext, id],
  )
  return (
    <DropdownField
      DropdownComponent={CapiAwsAvailiabilityZoneChooser}
      id={id}
      label="Availabilty Zones"
      cloudProviderId={wizardContext.cloudProviderId}
      cloudProviderRegionId={wizardContext.region}
      onChange={onChange || onAzsChange}
      values={value || wizardContext[id]}
      error={error}
      required
      {...rest}
    />
  )
}

export default CapiAwsAvailabilityZoneField
