import React from 'react'
import DropdownField from 'core/components/validatedForm/DropdownField'
import TextField from 'core/components/validatedForm/TextField'
import AsyncDropdown from 'core/elements/dropdown/AsyncDropdown'

const runtimeConfigOptions = [
  { label: 'Default API groups and versions', value: 'default' },
  { label: 'All API groups and versions', value: 'all' },
  { label: 'Custom', value: 'custom' },
]

const AdvancedApiConfigFields = ({ options = runtimeConfigOptions, values, setWizardContext }) => (
  <>
    <DropdownField
      DropdownComponent={AsyncDropdown}
      id="runtimeConfigOption"
      label="Advanced API Configuration"
      items={options}
      tooltip="Make sure you are familiar with the Kubernetes API configuration documentation before enabling this option."
      onChange={(value) => setWizardContext({ runtimeConfigOption: value })}
      required
    />

    {values.runtimeConfigOption === 'custom' && (
      <TextField
        id="customRuntimeConfig"
        label="Custom API Configuration"
        info=""
        onChange={(value) => setWizardContext({ customRuntimeConfig: value })}
      />
    )}
  </>
)

export default AdvancedApiConfigFields
