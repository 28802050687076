import React, { FC, useEffect } from 'react'
import { UserPreferences, ClusterCloudPlatforms } from 'app/constants'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { emptyObj, isNilOrEmpty } from 'utils/fp'
import CapiInfrastructureWizardStep from '../capi/CapiInfrastructureWizardStep'
import { ApiServerLoadBalancerTypes, CapiVpcTypes, SchemeTypes } from '../../model'
import { compareVersions } from 'k8s/util/helpers'
import { etcdBackupDefaults } from '../../cluster-addons/etcd-backup'
import CapiAddonsWizardStep from '../capi/CapiAddonsWizardStep'
import CapiControlPlaneWizardStep from '../capi/CapiControlPlaneWizardStep'
import CapiWorkerNodeGroupsWizardStep from '../capi/CapiWorkerNodeGroupsWizardStep'
import CapiFinalReviewWizardStep from '../capi/CapiFinalReviewWizardStep'
import { NetworkBackendTypes } from 'k8s/components/infrastructure/clusters/aws/capi/CapiAwsNetworkBackendField'
import { AwsClusterTypes } from '../../capi/model'
import { dnsAutoscalerDefaults } from '../../cluster-addons/CoreDns'

export enum IdentityRef {
  AWSClusterStaticIdentity = 'AWSClusterStaticIdentity',
  AWSClusterRoleIdentity = 'AWSClusterRoleIdentity',
}

export const initialContext = {
  type: AwsClusterTypes.AWS,
  azs: [],
  subnets: [],
  controlPlaneLoadBalancer: {
    apiServerLoadBalancerType: ApiServerLoadBalancerTypes.AwsClassicLoadBalancer,
    crossZoneLoadBalancing: false,
    healthCheckProtocol: 'TCP',
    scheme: SchemeTypes.InternetFacing,
  },
  identityRefKind: IdentityRef.AWSClusterStaticIdentity,
  vpcType: CapiVpcTypes.PmkManaged,
  clusterPodCidr: ['10.20.0.0/16'],
  clusterServiceCidr: ['10.21.0.0/16'],
  vpc: {
    cidrBlock: '10.0.0.0/16',
  },
  etcdBackup: null,
  replicas: 3,
  instanceType: 't2.medium',
  enableCAS: false,
  usePf9Domain: false,
  network: 'newPublic',
  networkPlugin: NetworkBackendTypes.Calico,
  calico: {
    IPIPMode: 'Always',
    IPv4BlkSize: 26,
    IPv4DetectionMethod: 'first-found',
    MTUSize: 1440,
    natOutgoing: true,
  },
  cniIngressRules: [
    {
      description: 'bgp (calico)',
      protocol: 'tcp',
      fromPort: 179,
      toPort: 179,
    },
    {
      description: 'IP-in-IP (calico)',
      protocol: '4',
      fromPort: -1,
      toPort: 65535,
    },
    {
      description: 'calico typha (pf9)',
      protocol: 'tcp',
      fromPort: 5473,
      toPort: 5473,
    },
  ],
  rootVolume: {
    size: 50,
    type: 'gp3',
  },
  nonRootVolumes: [
    {
      size: 100,
      type: 'gp3',
      deviceName: '/dev/xvdb',
    },
  ],
  apiserver: { apiGroupsAndVersions: '', privileged: true, extraArgs: {} },
  runtimeConfig: '',
  prometheusMonitoringEnabled: true,
  profileAgent: false,
  route53: null,
  publicIP: false,
  networkStack: 'ipv4',
  privileged: true,
  allowWorkloadsOnMaster: false,
  useRoute53: false,
  domainId: '',
  enableProfileAgent: false,
  enableCoreDns: true,
  // For all of the cluster resources
  namespace: 'default',
  // For the future when clusterNamespace is selectable (for capi autoscaler addon)
  clusterNamespace: 'default',
  retentionTime: 7,
  dnsMemoryLimit: 170,
  ...etcdBackupDefaults,
  ...dnsAutoscalerDefaults,
}

interface Props {
  wizardContext: any
  setWizardContext: any
  onNext: any
}

const CapiAwsClusters: FC<Props> = ({ wizardContext, setWizardContext, onNext, ...rest }) => {
  const { prefs, fetchUserDefaults } = useScopedPreferences('defaults')
  const cloudDefaults =
    prefs?.[UserPreferences.CloudProviders]?.[wizardContext.cloudProviderId] || emptyObj

  useEffect(() => {
    // Load user defaults from the preference store
    fetchUserDefaults(UserPreferences.CloudProviders)
  }, [])

  useEffect(() => {
    if (isNilOrEmpty(cloudDefaults)) return
    setWizardContext({ ...cloudDefaults })
  }, [cloudDefaults])

  useEffect(() => {
    if (compareVersions(wizardContext.kubeRoleVersion, '1.20') >= 0) {
      setWizardContext({ enableProfileAgent: true })
    } else {
      setWizardContext({ enableProfileAgent: false })
    }
  }, [wizardContext.kubeRoleVersion])

  return (
    <>
      <CapiInfrastructureWizardStep
        onNext={onNext}
        wizardContext={wizardContext}
        setWizardContext={setWizardContext}
        {...rest}
      />
      <CapiControlPlaneWizardStep
        wizardContext={wizardContext}
        setWizardContext={setWizardContext}
        onNext={onNext}
      />
      <CapiWorkerNodeGroupsWizardStep
        wizardContext={wizardContext}
        setWizardContext={setWizardContext}
        onNext={onNext}
      />
      <CapiAddonsWizardStep
        wizardContext={wizardContext}
        setWizardContext={setWizardContext}
        onNext={onNext}
      />
      <CapiFinalReviewWizardStep wizardContext={wizardContext} />
    </>
  )
}

export default CapiAwsClusters
