import { DateAndTime } from 'core/components/listTable/cells/DateCell'
import { routes } from 'core/utils/routes'
import DataKeys from 'k8s/DataKeys'
import { both, pick } from 'ramda'
import React from 'react'
import { clockDriftDetectedInNodes } from 'app/plugins/infrastructure/components/nodes/helpers'
import { UserPreferences, listTablePrefs, TablePrefsParams } from 'app/constants'
import { isDeccoEnv } from 'core/utils/helpers'
import DocumentMeta from 'core/components/DocumentMeta'
import ListContainer from 'core/containers/ListContainer'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { ArrayElement } from 'core/actions/Action'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { GridBatchActionSpec } from 'core/elements/grid/hooks/useGridSelectableRows'
import getGridRedirectButton from 'core/elements/grid/helpers/getGridRedirectButton'
import getGridDialogButton from 'core/elements/grid/helpers/getGridDialogButton'
import ClusterDeleteDialog from './ClusterDeleteDialog'
import {
  canDeleteCluster,
  canScaleMasters,
  canScaleWorkers,
  canUpgradeCluster,
  isAzureAutoscalingCluster,
  canForceScaleWorkers,
  canForceScaleMasters,
  getClusterStatus,
} from './helpers'
import SetAsDefaultDialog from './SetAsDefaultDialog'
import ForceScaleClusterDialog from './ForceScaleClusterDialog'
import { listClusters, deleteCluster } from './newActions'
import { clustersSelector } from './selectors'
import UUIDCell from 'app/plugins/infrastructure/components/common/cells/UUIDCell'
import ClusterLinkCell from './cluster-cells/ClusterLinkCell'
import HealthStatusCell from './cluster-cells/HealthStatusCell'
import StatsCell from 'app/plugins/infrastructure/components/common/cells/StatsCell'
import K8sVersionCell from './cluster-cells/K8sVersionCell'
import ClusterNameCell from './cluster-cells/ClusterNameCell'
import CloudProviderCell from './cluster-cells/CloudProviderCell'
import getClusterBooleanCell from './cluster-cells/getClusterBooleanCell'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { createGridLinkCell } from 'core/elements/grid/cells/GridLinkCell'
import { createGridStatusCell } from 'core/elements/grid/cells/GridStatusCell'
import { isAdmin } from 'app/plugins/infrastructure/components/common/helpers'
import AddClusterButton from 'app/plugins/infrastructure/components/clusters/AddClusterButton'
import InferActionParams from 'core/actions/InferActionParams'
import { createCidrRangesCell } from './cluster-cells/CidrRangesCell'
import InfrastructureTypeCell from './cluster-cells/InfrastructureTypeCell'
import PollingData from 'core/components/PollingData'

type ModelDataKey = DataKeys.Clusters
type SelectorModel = ArrayElement<ReturnType<typeof clustersSelector>>
type ActionParams = InferActionParams<typeof listClusters>
// @fixme using a type here because of https://github.com/microsoft/TypeScript/issues/15300
type Params = ActionParams & {
  masterNodeClusters?: boolean
  masterlessClusters?: boolean
  hasControlPanel?: boolean
  healthyClusters?: boolean
  prometheusClusters?: boolean
}

const columns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'uuid',
    label: 'UUID',
    CellComponent: UUIDCell,
    display: false,
  } as GridViewColumn<SelectorModel, 'uuid'>,
  // Disable cell memoization since it prevents the hook within the cell to fetch the updated user prefs
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
    CellComponent: ClusterNameCell,
    memoizeCell: false,
  },
  {
    key: 'healthStatus',
    label: 'Health Status',
    CellComponent: HealthStatusCell,
    tooltip: 'Whether the cluster is connected to the PMK management plane',
  },
  {
    key: 'k8sNodeStatus',
    accessor: (cluster) => cluster.nodes || [],
    label: 'K8s Node Status',
    CellComponent: createGridStatusCell({
      dataFn: getClusterStatus,
    }),
  },
  {
    key: 'links',
    label: 'Links',
    CellComponent: ClusterLinkCell,
  },
  {
    key: 'cloudProviderType',
    label: 'Infrastructure Type',
    CellComponent: InfrastructureTypeCell,
  } as GridViewColumn<SelectorModel, 'cloudProviderType'>,
  { key: 'usage', label: 'Resource Utilization', CellComponent: StatsCell } as GridViewColumn<
    SelectorModel,
    'usage'
  >,
  { key: 'version', label: 'K8s Version', CellComponent: K8sVersionCell },
  { key: 'containerRuntime', label: 'Container Runtime', display: false },
  {
    key: 'created_at',
    label: 'Created',
    CellComponent: DateAndTime,
  },
  {
    key: 'lastOp',
    label: 'Updated',
    CellComponent: DateAndTime,
    display: false,
  },
  {
    key: 'nodes',
    label: 'Nodes',
    display: false,
    formatFn: (nodes) => `${String(nodes?.length || 0)} Nodes`,
    CellComponent: createGridLinkCell({
      routeToFn: ({ uuid, nodes }) =>
        nodes?.length ? routes.cluster.legacy.detail.path({ id: uuid }) : null,
    }),
  } as GridViewColumn<SelectorModel, 'nodes'>,
  { key: 'networkPlugin', display: false, label: 'Network Backend' },
  {
    key: 'cidrRanges',
    label: 'CIDR Ranges',
    width: 'medium',
    CellComponent: createCidrRangesCell({
      dataFn: ({ containersCidr, servicesCidr }) => {
        return [
          { label: 'Containers', value: containersCidr },
          { label: 'Services', value: servicesCidr },
        ]
      },
    }),
    display: false,
  },
  { key: 'endpoint', display: false, label: 'API Endpoint' },
  {
    key: 'cloudProviderName',
    label: 'Cloud Provider',
    display: false,
    CellComponent: CloudProviderCell,
  },
  {
    key: 'allowWorkloadsOnMaster',
    label: 'Master Workloads',
    display: false,
    CellComponent: getClusterBooleanCell({ key: 'allowWorkloadsOnMaster' }),
    tooltip: 'Whether masters are enabled to run workloads',
  },
  {
    key: 'privileged',
    label: 'Privileged',
    display: false,
    CellComponent: getClusterBooleanCell({ key: 'privileged' }),
    tooltip: 'Whether any container on the cluster can enable privileged mode',
  },
  {
    key: 'hasVpn',
    label: 'VPN',
    display: false,
    CellComponent: getClusterBooleanCell({ key: 'hasVpn' }),
  },
  {
    key: 'hasLoadBalancer',
    label: 'Load Balancer',
    display: false,
    CellComponent: getClusterBooleanCell({ key: 'hasLoadBalancer' }),
  },
  {
    key: 'etcdBackupEnabled',
    label: 'etcd Backup',
    display: false,
    CellComponent: getClusterBooleanCell({ key: 'etcdBackupEnabled' }),
  },
  {
    key: 'externalDnsName',
    label: 'Control Plane Endpoint',
    display: false,
    width: 'medium',
  },
]

const searchTargets = ['name', 'uuid']

const batchActions: GridBatchActionSpec<SelectorModel>[] = [
  {
    icon: 'edit',
    label: 'Edit',
    BatchActionButton: getGridRedirectButton<SelectorModel>(({ uuid }) =>
      routes.cluster.legacy.edit.path({ id: uuid }),
    ),
  },
  {
    cond: both(isAdmin, canUpgradeCluster),
    icon: 'level-up',
    label: 'Upgrade Clusters',
    BatchActionButton: getGridRedirectButton<SelectorModel>(
      ({ uuid }) => routes.cluster.legacy.upgrade.path({ id: uuid }),
      ([cluster], isDisabled) => ({
        tooltip: isDisabled
          ? cluster && clockDriftDetectedInNodes(cluster?.nodes)
            ? 'Cannot upgrade cluster: clock drift detected in at least one node'
            : 'Cannot upgrade cluster'
          : null,
      }),
    ),
  },
  {
    cond: both(isAdmin, canScaleMasters),
    icon: 'expand-alt',
    label: 'Scale Masters',
    BatchActionButton: getGridDialogButton(ForceScaleClusterDialog, {
      dialogRoute: (cluster) => routes.cluster.legacy.scaleMasters.path({ id: cluster.uuid }),
      canForceScale: both(isAdmin, canForceScaleMasters),
    }),
  },
  {
    cond: both(isAdmin, canScaleWorkers),
    icon: 'expand-alt',
    label: 'Scale Workers',
    BatchActionButton: getGridDialogButton(
      ForceScaleClusterDialog,
      {
        dialogRoute: (cluster) => routes.cluster.legacy.scaleWorkers.path({ id: cluster.uuid }),
        canForceScale: both(isAdmin, canForceScaleWorkers),
      },
      ([cluster], isDisabled) => ({
        tooltip: isDisabled
          ? cluster && isAzureAutoscalingCluster(cluster)
            ? 'Scaling Azure autoscaling clusters is not yet supported'
            : 'Cannot scale workers: cluster is busy'
          : null,
      }),
    ),
  },
  {
    cond: isDeccoEnv,
    icon: 'star',
    label: 'Set as Default',
    BatchActionButton: getGridDialogButton(SetAsDefaultDialog, {
      defaultsKey: UserPreferences.Cluster,
      type: 'cluster',
    }),
  },
]
const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>('Clusters', listTablePrefs)

const oneSecond = 1000

const defaultParams: Params = {}
export default function ClustersListPage() {
  const { params, getParamsUpdater } = usePrefParams(defaultParams)
  const { message, loading, reload } = useListAction(listClusters, {
    params,
  })
  const data = useSelectorWithParams(clustersSelector, params)

  return (
    <>
      <DocumentMeta title="Legacy Clusters" />
      <PollingData hidden loading={loading} onReload={reload} refreshDuration={oneSecond * 30} />
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.Clusters}
        searchTargets={searchTargets}
        uniqueIdentifier="uuid"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        data={data}
        columns={columns}
        addUrl={routes.cluster.add.root.path()}
        addCond={isAdmin}
        addText="Add Cluster"
        AddButtonComponent={AddClusterButton}
        getParamsUpdater={getParamsUpdater}
        deleteAction={deleteCluster}
        batchActions={batchActions}
        deleteCond={both(isAdmin, canDeleteCluster)}
        DeleteDialogComponent={ClusterDeleteDialog}
        label="Legacy Clusters"
        showItemsCountInLabel
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
