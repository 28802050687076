import React, { useCallback, useEffect } from 'react'
import ConfirmationDialog from 'core/components/ConfirmationDialog'
import Text from 'core/elements/Text'
import useReactRouter from 'use-react-router'
import { GridDialogProps } from 'core/elements/grid/helpers/getGridDialogButton'
import { ClusterSelector } from 'app/plugins/infrastructure/components/clusters/model'

export interface ForceClusterDialogProps extends GridDialogProps<ClusterSelector> {
  dialogRoute: (cluster: ClusterSelector) => string
  canForceScale: (clusters: ClusterSelector[]) => boolean
}

export default function ForceScaleClusterDialog({
  rows,
  onClose,
  dialogRoute,
  canForceScale,
}: ForceClusterDialogProps) {
  const [cluster] = rows
  const { history } = useReactRouter()
  const redirectToClusterScaleRoute = useCallback(() => history.push(dialogRoute(cluster)), [
    history,
  ])
  useEffect(() => {
    if (!canForceScale(rows)) {
      redirectToClusterScaleRoute()
    }
  }, [])
  if (!canForceScale(rows)) {
    return null
  }
  return (
    <ConfirmationDialog
      title={`Warning: Scale Operations Should Not be Performed`}
      text={
        <>
          <Text variant="body1">
            Cluster {cluster.name} is in an unhealthy state. Performing a cluster scale operation
            while the cluster is in an unhealthy state may cause the cluster to fail.
          </Text>
        </>
      }
      confirmText={'Continue'}
      open
      onCancel={onClose}
      onConfirm={redirectToClusterScaleRoute}
    />
  )
}
