import React from 'react'
import { pathStrOr } from 'utils/fp'
import { identity, intersection } from 'ramda'
import useDataLoader from 'core/hooks/useDataLoader'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'
import { loadCloudProviderRegionDetails } from 'app/plugins/infrastructure/components/cloudProviders/actions'
import { compareArrByValue } from 'utils/misc'

interface Props {
  cloudProviderId: string
  cloudProviderRegionId: string
  filterByZones?: boolean
  selectedZones: string[]
}

export default function AzureSkuPicklist({
  cloudProviderId,
  cloudProviderRegionId,
  selectedZones,
  filterByZones,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  const [details, loading] = useDataLoader(loadCloudProviderRegionDetails, {
    cloudProviderId,
    cloudProviderRegionId,
  })

  const skus = pathStrOr([], '0.skus', details)
  const zonesFilter = (sku) =>
    intersection(pathStrOr([], 'locationInfo.0.zones', sku), selectedZones).length > 0
  const items = skus
    .filter(filterByZones ? zonesFilter : identity)
    .map((x) => ({ label: x.name, value: x.name }))
    .sort(compareArrByValue('value'))

  return <AsyncDropdown loading={loading} items={items} {...rest} />
}
