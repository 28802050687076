import ApiClient from 'api-client/ApiClient'
import { ICapiResource } from '../../capi/model'
import {
  CapiResourceKind,
  CapiOperation,
} from 'app/plugins/infrastructure/components/clusters/aws/capi/model'

const { sunpike, qbert } = ApiClient.getInstance()
const {
  createCapiCluster,
  createCapiAwsCluster,
  createAwsClusterRoleIdentity,
  createNodeletControlPlane,
  createAwsMachineTemplate,
  createMachineDeployment,
  createMachinePool,
  createAwsMachinePool,
  createAwsManagedMachinePool,
  createNodeletConfig,
  createNodeletConfigTemplate,
  createEksConfig,
  createEksConfigTemplate,
  createAwsManagedControlPlane,
  updateAwsMachineTemplate,
  patchMachineDeployment,
  patchNodeletConfig,
  patchNodeletConfigTemplate,
  patchAwsMachinePool,
  patchMachinePool,
  patchAwsManagedMachinePool,
  patchEksConfig,
  patchEksConfigTemplate,
} = sunpike

const { addClusterAddon } = qbert

type ResourceOperationsMap = {
  [key in CapiResourceKind]: { [key in CapiOperation]: (schema: ICapiResource) => any }
}

export const resourceUpdateFunctions: ResourceOperationsMap = {
  [CapiResourceKind.Cluster]: {
    [CapiOperation.Create]: createCapiCluster,
    [CapiOperation.Update]: null,
    [CapiOperation.PartialUpdate]: null,
  },
  [CapiResourceKind.AWSCluster]: {
    [CapiOperation.Create]: createCapiAwsCluster,
    [CapiOperation.Update]: null,
    [CapiOperation.PartialUpdate]: null,
  },
  [CapiResourceKind.AWSClusterRoleIdentity]: {
    [CapiOperation.Create]: createAwsClusterRoleIdentity,
    [CapiOperation.Update]: null,
    [CapiOperation.PartialUpdate]: null,
  },
  [CapiResourceKind.NodeletControlPlane]: {
    [CapiOperation.Create]: createNodeletControlPlane,
    [CapiOperation.Update]: null,
    [CapiOperation.PartialUpdate]: null,
  },
  [CapiResourceKind.AWSMachineTemplate]: {
    [CapiOperation.Create]: createAwsMachineTemplate,
    [CapiOperation.Update]: updateAwsMachineTemplate,
    [CapiOperation.PartialUpdate]: updateAwsMachineTemplate,
  },
  [CapiResourceKind.MachineDeployment]: {
    [CapiOperation.Create]: createMachineDeployment,
    [CapiOperation.Update]: null,
    // Todo: Update the params for updateMachineDeployment fn so that it only accepts the resource
    // as the only param
    // [CapiOperation.Update]: updateMachineDeployment,
    [CapiOperation.PartialUpdate]: patchMachineDeployment,
  },
  [CapiResourceKind.MachinePool]: {
    [CapiOperation.Create]: createMachinePool,
    [CapiOperation.Update]: null,
    [CapiOperation.PartialUpdate]: patchMachinePool,
  },
  [CapiResourceKind.AWSMachinePool]: {
    [CapiOperation.Create]: createAwsMachinePool,
    [CapiOperation.Update]: null,
    [CapiOperation.PartialUpdate]: patchAwsMachinePool,
  },
  [CapiResourceKind.AWSManagedMachinePool]: {
    [CapiOperation.Create]: createAwsManagedMachinePool,
    [CapiOperation.Update]: null,
    [CapiOperation.PartialUpdate]: patchAwsManagedMachinePool,
  },
  [CapiResourceKind.NodeletConfig]: {
    [CapiOperation.Create]: createNodeletConfig,
    [CapiOperation.Update]: null,
    // [CapiOperation.Update]: updateNodeletConfig,
    [CapiOperation.PartialUpdate]: patchNodeletConfig,
  },
  [CapiResourceKind.NodeletConfigTemplate]: {
    [CapiOperation.Create]: createNodeletConfigTemplate,
    [CapiOperation.Update]: null,
    [CapiOperation.PartialUpdate]: patchNodeletConfigTemplate,
  },
  [CapiResourceKind.EksConfig]: {
    [CapiOperation.Create]: createEksConfig,
    [CapiOperation.Update]: null,
    [CapiOperation.PartialUpdate]: patchEksConfig,
  },
  [CapiResourceKind.EksConfigTemplate]: {
    [CapiOperation.Create]: createEksConfigTemplate,
    [CapiOperation.Update]: null,
    [CapiOperation.PartialUpdate]: patchEksConfigTemplate,
  },
  [CapiResourceKind.AWSManagedControlPlane]: {
    [CapiOperation.Create]: createAwsManagedControlPlane,
    [CapiOperation.Update]: null,
    [CapiOperation.PartialUpdate]: null,
  },
  [CapiResourceKind.ClusterAddon]: {
    [CapiOperation.Create]: addClusterAddon,
    [CapiOperation.Update]: null,
    [CapiOperation.PartialUpdate]: null,
  },
}
