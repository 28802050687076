import { filter, pluck, values } from 'ramda'
import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { ExtensionsClass, Host as ResMgrHost } from 'api-client/resmgr.model'
import { emptyArr } from 'utils/fp'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import { ICapiHostsSelector, Host } from './model'
import { calculateNodeUsages } from 'app/plugins/infrastructure/components/common/helpers'
import {
  getIpPreview,
  getNetworkInterfaces,
} from 'app/plugins/infrastructure/components/common/selectors'
import { combineHost } from 'app/plugins/infrastructure/components/common/combineHosts'
import { HostTypes } from 'app/plugins/infrastructure/components/common/model'

export const capiHostsSelector = createSharedSelector(
  getDataSelector<DataKeys.CapiHosts>(DataKeys.CapiHosts),
  (rawCapiHosts): ICapiHostsSelector[] => {
    return rawCapiHosts.map((host: Host) => {
      return {
        ...host,
        hostId: host?.metadata?.name,
        clusterName: host?.spec?.pf9?.clusterName,
      }
    })
  },
)
export const hostSelectorForCapiCluster = createSharedSelector(
  capiHostsSelector,
  getDataSelector<DataKeys.ResMgrHosts>(DataKeys.ResMgrHosts),
  (capiHosts: ICapiHostsSelector[], resMgrHosts: ResMgrHost[]) => {
    const hostIds = pluck('hostId', capiHosts)
    const equalsAnyHostId = (host: ResMgrHost) => hostIds.includes(host.id)
    return filter(equalsAnyHostId, resMgrHosts).map((item) => {
      const extensions = item?.extensions as ExtensionsClass
      return {
        ...item,
        resourceType: HostTypes.Resmgr,
        ipPreview: getIpPreview(extensions?.ip_address?.data),
        networkInterfaces: getNetworkInterfaces(item),
        ovsBridges: extensions?.interfaces?.data.ovs_bridges || (emptyArr as any),
      }
    })
  },
)

export const hostUsageSelectorForCapiCluster = createSharedSelector(
  hostSelectorForCapiCluster,
  (hosts) => {
    const combinedHosts = hosts.map((item) => {
      const host = {
        [HostTypes.Resmgr]: item,
      }
      const combined = combineHost(host)
      return { ...host, ...combined }
    })
    return calculateNodeUsages(combinedHosts)
  },
)

// export const combinedHostsSelector = createSharedSelector(
//   getDataSelector<DataKeys.Nodes>(DataKeys.Nodes),
//   resMgrCombinedHostsSelector,
//   k8sNodesByClusterIdAandNodeNameSelector,
//   (rawNodes, resMgrHostsById, k8sNodesById) => {
//     const hostsById: ICombinedHostSelector = {}
//     for (const hostId in resMgrHostsById) {
//       const host = resMgrHostsById[hostId]
//       const k8sNode =
//         k8sNodesById[host?.qbert?.clusterUuid]?.[host?.hostname] ||
//         k8sNodesById[host?.qbert?.clusterUuid]?.[host?.primaryIp]
//       hostsById[hostId] = { ...host, [HostTypes.K8s]: k8sNode }
//     }

//     rawNodes.forEach((node) => {
//       hostsById[node.uuid] = hostsById[node.uuid] || ({} as any)
//       hostsById[node.uuid][HostTypes.Qbert] = node
//       hostsById[node.uuid][HostTypes.K8s] =
//         k8sNodesById[node.clusterUuid]?.[node.name] ||
//         k8sNodesById[node.clusterUuid]?.[node.primaryIp]
//     })

//     // Convert it back to array form
//     return hostsById
//   },
// )
