import { CombinedClusterSelector } from './model'
import { AppSelector } from 'app/store'
import { createSelector } from '@reduxjs/toolkit'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import {
  clustersSelector,
  makeParamsClustersSelector,
} from 'app/plugins/infrastructure/components/clusters/selectors'
import {
  makeParamsImportedClustersSelector,
  importedClustersSelector,
} from 'app/plugins/infrastructure/components/importedClusters/selectors'
import { ClusterParams } from 'app/plugins/infrastructure/components/common/model'
import { capiClustersSelector } from 'app/plugins/infrastructure/components/clusters/capi/selectors'

export const allClustersSelector = createSharedSelector(
  capiClustersSelector,
  clustersSelector,
  importedClustersSelector,
  (capiClusters, clusters, importedClusters): CombinedClusterSelector[] => {
    return [...capiClusters, ...clusters, ...importedClusters]
  },
)

const allClustersSelectorDefaultParams: ClusterParams = Object.freeze({
  orderBy: 'name',
  orderDirection: 'asc',
  healthyClusters: true,
  ecoEnabledClusters: true,
})

export const makeParamsAllClustersSelector = (
  defaultParams = allClustersSelectorDefaultParams,
): AppSelector<CombinedClusterSelector[]> => {
  const selectClusters = makeParamsClustersSelector(defaultParams)
  const selectImportedClusters = makeParamsImportedClustersSelector(defaultParams)
  return createSelector(
    selectClusters,
    selectImportedClusters,
    capiClustersSelector,
    (clusters, importedClusters, capiClusters): CombinedClusterSelector[] => {
      return [...clusters, ...importedClusters, ...capiClusters]
    },
  )
}
