import React from 'react'
import Text from 'core/elements/Text'
import TextField from 'core/components/validatedForm/TextField'
import DropdownField from 'core/components/validatedForm/DropdownField'
import Dropdown from 'core/elements/dropdown'
import Row from 'core/containers/Row'
import { validators } from 'core/utils/fieldValidators'

export default function CapiMachinePoolNodeUpdateStrategies({
  workerNodeGroup = undefined,
  context = undefined,
  onChange,
}) {
  const data = workerNodeGroup ? workerNodeGroup : context
  return (
    <>
      <Text variant="caption1">Nodes Update Strategy</Text>
      <Row minItemWidth="300" gap={24}>
        <DropdownField
          DropdownComponent={Dropdown}
          id="nodesUpdateStrategy"
          label="Strategy"
          value="rollingUpdate"
          disabled
          items={[{ label: 'Rolling Update', value: 'rollingUpdate' }]}
        />
      </Row>
      <Row minItemWidth="300" gap={24}>
        <DropdownField
          DropdownComponent={Dropdown}
          id="minHealthyNodeType"
          label="Minimum Healthy Nodes - Type"
          disabled
          value="percentage"
          items={[{ label: 'Percentage', value: 'percentage' }]}
        />
        <TextField
          id="minHealthyNodeValue"
          type="number"
          label="Minimum Healthy Nodes Percentage- Value"
          validations={[validators.rangeValue(1, 100)]}
          icon="percent"
          step="10"
          min="1"
          max="100"
          value={data?.refreshPreferences?.minHealthyPercentage}
          onChange={(value) => {
            onChange('refreshPreferences')({ minHealthyPercentage: value })
          }}
          required
        />
      </Row>
    </>
  )
}
