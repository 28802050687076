import { IAwsMachineTemplateSelector } from '../aws-machine-templates/model'
import { IConfigSelector } from '../configs/model'
import { NodeGroupTypeLabels } from '../model'

export interface IMachineDeploymentSelector extends MachineDeployment {
  uuid: MachineDeployment['metadata']['uid']
  name: MachineDeployment['metadata']['name']
  type: NodeGroupTypeLabels
  clusterName: MachineDeployment['spec']['clusterName']
  namespace: MachineDeployment['metadata']['namespace']
  creationTimestamp: MachineDeployment['metadata']['creationTimestamp']
  k8sVersion: MachineDeployment['spec']['template']['spec']['version']
  phase: MachineDeployment['status']['phase']
  replicas: MachineDeployment['status']['replicas']
  readyReplicas: MachineDeployment['status']['readyReplicas']
  availableReplicas: MachineDeployment['status']['availableReplicas']
  unavailableReplicas: MachineDeployment['status']['unavailableReplicas']
  autoscalingEnabled: boolean
  annotations: MachineDeployment['metadata']['annotations']
  minCasSize: MachineDeployment['metadata']['annotations']['cluster.x-k8s.io/cluster-api-autoscaler-node-group-min-size']
  maxCasSize: MachineDeployment['metadata']['annotations']['cluster.x-k8s.io/cluster-api-autoscaler-node-group-max-size']
  rollingUpdate: MachineDeployment['spec']['strategy']['rollingUpdate']
  infrastructureRef: MachineDeployment['spec']['template']['spec']['infrastructureRef']
  configRef: MachineDeployment['spec']['template']['spec']['bootstrap']['configRef']
  machineTemplate: IAwsMachineTemplateSelector
  config: IConfigSelector
  resources: any[]
}

export enum Phase {
  ScalingUp = 'ScalingUp',
  ScalingDown = 'ScalingDown',
  Running = 'Running',
  Failed = 'Failed',
  Deleting = 'Deleting',
  Unknown = 'Unknown',
}

export interface MachineDeploymentList {
  apiVersion: string
  items: MachineDeployment[]
  kind: 'MachineDeploymentList'
}

export interface MachineDeployment {
  apiVersion: string
  kind: 'MachineDeployment'
  metadata: Metadata
  spec: Spec
  status: Status
}

export interface Metadata {
  annotations: Annotations
  creationTimestamp: string
  generation: number
  labels: Record<string, string>
  name: string
  namespace: string
  ownerReferences: Reference[]
  resourceVersion: string
  uid: string
  deletionTimestamp: string
}

interface Annotations {
  'kubectl.kubernetes.io/last-applied-configuration': string
  'machinedeployment.clusters.x-k8s.io/revision': string
  'cluster.x-k8s.io/cluster-api-autoscaler-node-group-min-size': string
  'cluster.x-k8s.io/cluster-api-autoscaler-node-group-max-size': string
}

interface Reference {
  apiVersion: string
  kind: string
  name: string
  uid?: string
}

export interface Spec {
  clusterName: string
  minReadySeconds: number
  progressDeadlineSeconds: number
  replicas: number
  revisionHistoryLimit: number
  selector: Selector
  cas: {}
  template: Template
  strategy: Strategy
}

interface Selector {
  matchLabels: MatchLabels
}

interface MatchLabels {
  'cluster.x-k8s.io/cluster-name': string
  machineDeployment: string
}

export interface Strategy {
  rollingUpdate: RollingUpdate
  type: 'RollingUpdate' | 'OnDelete'
}

export interface RollingUpdate {
  maxSurge: number
  maxUnavailable: number
}

export interface Template {
  metadata: TemplateMetadata
  spec: TemplateSpec
}

export interface TemplateMetadata {
  labels: MatchLabels
}

export interface TemplateSpec {
  bootstrap: Bootstrap
  clusterName: string
  infrastructureRef: Reference
  version: string
}

interface Bootstrap {
  configRef: Reference
}

export interface Status {
  availableReplicas: number
  conditions: Condition[]
  observedGeneration: number
  phase: Phase
  readyReplicas: number
  replicas: number
  selector: string
  unavailableReplicas: number
  updatedReplicas: number
}

interface Condition {
  lastTransitionTime: string
  status: string
  type: string
}
