import { createSelector } from '@reduxjs/toolkit'
import {
  combinedHostsSelector,
  qbertEndpointSelector,
} from 'app/plugins/infrastructure/components/common/selectors'
import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { calculateNodeUsages } from 'app/plugins/infrastructure/components/common/helpers'
import { getScopedQbertEndpoint } from 'app/plugins/infrastructure/components/clusters/action-helpers'
import { arrayIfEmpty } from 'utils/fp'
import { getNetworkingStackFromIp } from 'app/plugins/infrastructure/components/nodes/helpers'
import { selectParamsFromProps, createSharedSelector } from 'core/utils/selectorHelpers'
import { INodesSelector, NodeState } from 'app/plugins/infrastructure/components/nodes/model'
import { castFuzzyBool } from 'utils/misc'
import { allKey, noneKey } from 'app/constants'

export const nodesSelector = createSharedSelector(
  getDataSelector<DataKeys.Nodes>(DataKeys.Nodes),
  combinedHostsSelector,
  qbertEndpointSelector,
  (rawNodes, combinedHostsObj, qbertEndpoint): INodesSelector[] => {
    // associate nodes with the combinedHost entry
    return arrayIfEmpty(
      rawNodes
        .map((node) => {
          const usage = calculateNodeUsages([combinedHostsObj[node.uuid]]) // expects array
          const combined = combinedHostsObj[node.uuid]
          const k8s = combinedHostsObj[node.uuid]?.k8s
          const conditions = k8s?.status?.conditions
          return {
            ...node,
            // if hostagent is not responding, then the nodes info is outdated
            // set the status to disconnected manually
            status: combined.responding ? node.status : 'disconnected',
            combined,
            // qbert v3 link fails authorization so we have to use v1 link for logs
            logs: `${getScopedQbertEndpoint(qbertEndpoint)}/logs/${node.uuid}`,
            usage,
            roles: combined?.roles,
            operatingSystem: combined?.resmgr?.info?.os_info || combined?.osInfo,
            primaryNetwork: combined?.qbert?.primaryIp,
            networkStack: getNetworkingStackFromIp(combined?.qbert?.primaryIp),
            networkInterfaces: combined?.networkInterfaces || {},
            cpuArchitecture: combined?.resmgr?.info?.arch,
            message: combined?.resmgr?.message,
            conditions: k8s?.status?.conditions,
            ready: conditions?.find((cond) => cond.type === 'Ready')?.status,
            unschedulable: k8s?.spec?.unschedulable,
            // resourceUtilization:
            isSpotInstance: castFuzzyBool(
              combined?.resmgr?.extensions?.node_metadata?.data?.isSpotInstance,
            ),
            assignedRoles: combined?.localizedRoles || [],
          }
        })
        .filter((node) => node?.combined?.cloudStack !== 'openstack'),
    )
  },
)

export const makeParamsNodesSelector = (
  defaultParams = {
    clusterId: null,
    state: null,
    role: null,
  },
) => {
  const selectParams = selectParamsFromProps(defaultParams)
  return createSelector(nodesSelector, selectParams, (nodes, params) => {
    const { clusterId, state, role } = params

    const filterByCluster = (node) => {
      if (clusterId === allKey) {
        return true
      } else if (clusterId === noneKey) {
        return !node.clusterUuid
      }
      return node.clusterUuid === clusterId
    }

    const filterByNodeState = (node) => {
      return (
        state === allKey ||
        (state === NodeState.Authorized ? node.isAuthorized : !node.isAuthorized)
      )
    }

    const filterByNodeRole = (node) => {
      if (role === allKey) {
        return true
      } else if (node.clusterUuid == null) {
        return false
      }
      return role === 'master' ? node.isMaster : !node.isMaster
    }

    return nodes.filter((node) => {
      return filterByCluster(node) && filterByNodeState(node) && filterByNodeRole(node)
    })
  })
}
