import React, { useCallback } from 'react'
import { deAuthNode } from 'app/plugins/infrastructure/components/nodes/actions'
import useDataUpdater from 'core/hooks/useDataUpdater'
import ConfirmationDialog from 'core/components/ConfirmationDialog'
import { GridDialogProps } from 'core/elements/grid/helpers/getGridDialogButton'
import { INodesSelector } from 'app/plugins/infrastructure/components/nodes/model'

export default function NodeForceRemoveDialog({
  rows: [node],
  onClose,
}: GridDialogProps<INodesSelector>) {
  const [deauth] = useDataUpdater(deAuthNode, onClose)
  // when refactoring to new actions, use params in commented line
  // const handleSubmit = useCallback(() => deauth({ node }), [deauth])
  const handleSubmit = useCallback(() => deauth(node), [deauth])

  return (
    <ConfirmationDialog
      title="Node Force Remove"
      text={
        <>
          You are about to de-authorize the node {node?.name} ({node?.primaryIp}).
          <br />
          <br />
          {node.clusterUuid && (
            <>
              Force Removal of a Node will detach the node form its associated cluster{' '}
              <b>({node.clusterName})</b> and deauthorize it from Platform9. Are you sure you want
              to force remove this node?
            </>
          )}
        </>
      }
      confirmText="Remove Node"
      open
      onCancel={onClose}
      onConfirm={handleSubmit}
    />
  )
}
