import React from 'react'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import DropdownField from 'core/components/validatedForm/DropdownField'
import TextField from 'core/components/validatedForm/TextField'
import { ipValidators } from './validators'
import { NetworkStackTypes } from '../constants'
import Dropdown from 'core/elements/dropdown'
import Row from 'core/containers/Row'
import ToggleSwitchField from 'core/components/validatedForm/ToggleSwitchField'
import { mergeRight } from 'ramda'
import { CalicoCapiCnikeys } from 'k8s/components/infrastructure/clusters/aws/capi/CapiAwsNetworkBackendField'

const calicoIpIPHelpText = {
  Always: 'Encapsulates POD traffic in IP-in-IP between nodes.',
  CrossSubnet: `Encapsulation when nodes span subnets and cross routers that may drop native POD 
     traffic, this is not required between nodes with L2 connectivity.`,
  Never: 'Disables IP in IP Encapsulation.',
}
const calicoBlockSizeTooltip = {
  [NetworkStackTypes.IPv6]: `Block size to use for the IPv6 POOL created at startup. Block size for 
    IPv6 should be in the range 116-128`,
  [NetworkStackTypes.IPv4]: `Block size determines how many Pod's can run per node vs total number
    of nodes per cluster. Example /22 enables 1024 IPs per node, and a maximum of 64 nodes. Block
    size must be greater than 20 and less than 32 and not conflict with the Contain CIDR`,
}

const calicoIpIpOptions = [
  { label: 'Always', value: 'Always' },
  { label: 'Cross Subnet', value: 'CrossSubnet' },
  { label: 'Never', value: 'Never' },
]
export enum CalicoDetectionTypes {
  FirstFound = 'first-found',
  CanReach = 'can-reach',
  Interface = 'interface',
  SkipInterface = 'skip-interface',
}

const calicoDetectionOptions = [
  { label: 'First Found', value: CalicoDetectionTypes.FirstFound },
  { label: 'can-reach=<IP OR DOMAIN NAME>', value: CalicoDetectionTypes.CanReach },
  { label: 'interface=<IFACE NAME REGEX LIST>', value: CalicoDetectionTypes.Interface },
  { label: 'skip-interface=<IFACE NAME REGEX LIST>', value: CalicoDetectionTypes.SkipInterface },
]

const detectionMethodLabels = {
  'can-reach': 'IP or Domain Name',
  interface: 'IFace Name Regex List',
  'skip-interface': 'IFace Name Regex List',
}

export const calicoDefaults = {
  IPv4DetectionMethod: CalicoDetectionTypes.FirstFound,
  IPIPMode: 'Always',
  natOutgoing: true,
  IPv4BlkSize: 26,
  MTUSize: 1440,
}

const CalicoNetworkFields = ({
  values,
  setValues,
  useToggleSwitch = false,
  isCapiCluster = false,
}) => {
  const minItemWidth = isCapiCluster ? '300' : '400'

  const ipIpModeFieldId = isCapiCluster ? CalicoCapiCnikeys.IPIPMode : 'calicoIpIpMode'
  const natOutgoingFieldId = isCapiCluster ? CalicoCapiCnikeys.natOutgoing : 'calicoNatOutgoing'

  const detectionMethodFieldId = isCapiCluster
    ? CalicoCapiCnikeys.IPv4DetectionMethod
    : 'calicoDetectionMethod'
  const detectionMethodValueId = 'calicoDetectionMethodValue'
  const blockSizeFieldId = isCapiCluster ? CalicoCapiCnikeys.IPv4BlkSize : 'calicoBlockSize'
  const mtuSizeFieldId = isCapiCluster ? CalicoCapiCnikeys.MTUSize : 'mtuSize'

  const ipIpModeTooltipInfo = isCapiCluster
    ? calicoIpIPHelpText[values?.calico?.[ipIpModeFieldId]]
    : calicoIpIPHelpText[values[ipIpModeFieldId]]

  const detectionMethodLabel = isCapiCluster
    ? detectionMethodLabels[values?.calico?.[detectionMethodFieldId]]
    : detectionMethodLabels[values[detectionMethodFieldId]]

  const showDetectionMethodValueField = isCapiCluster
    ? values?.calico?.[detectionMethodFieldId] !== CalicoDetectionTypes.FirstFound
    : values[detectionMethodFieldId] !== CalicoDetectionTypes.FirstFound

  const handleChange = (property) => (value) => {
    if (isCapiCluster) {
      return setValues({ calico: mergeRight(values.calico || {}, { [property]: value }) })
    }
    setValues({ [property]: value })
  }

  return (
    <>
      <Row minItemWidth={minItemWidth} gap={24}>
        <DropdownField
          DropdownComponent={Dropdown}
          id={ipIpModeFieldId}
          value={isCapiCluster ? values?.calico?.[ipIpModeFieldId] : values[ipIpModeFieldId]}
          onChange={handleChange(ipIpModeFieldId)}
          label="IP in IP Encapsulation Mode"
          items={calicoIpIpOptions}
          tooltip={ipIpModeTooltipInfo || ''}
          required
        />
        <DropdownField
          DropdownComponent={Dropdown}
          id={detectionMethodFieldId}
          value={
            isCapiCluster
              ? values?.calico?.[detectionMethodFieldId]
              : values[detectionMethodFieldId]
          }
          onChange={handleChange(detectionMethodFieldId)}
          label="Interface Detection Method"
          items={calicoDetectionOptions}
          required
        />
        {showDetectionMethodValueField && (
          // TODO:CAPI Needs fix for other options than 'first-found' in 'Interface Detection Method' selection
          // needs to update  IPv4DetectionMethod: "first-found" #other options can-reach=www.google.com, interface=eth.*, skip-interface=eth.*,wlp2s0
          <TextField
            id={detectionMethodValueId}
            value={
              isCapiCluster
                ? values?.calico?.[detectionMethodValueId]
                : values[detectionMethodValueId]
            }
            onChange={handleChange(detectionMethodValueId)}
            info={`Use the first valid IP address on the first enumerated interface (same logic as 
              first-found above) that does NOT match with any of the specified interface name 
              regexes. Regexes are separated by commas (e.g. eth.,enp0s.).`}
            label={detectionMethodLabel || ''}
            required
          />
        )}
      </Row>
      {useToggleSwitch ? (
        <ToggleSwitchField
          id={natOutgoingFieldId}
          value={isCapiCluster ? values?.calico?.[natOutgoingFieldId] : values[natOutgoingFieldId]}
          onChange={handleChange(natOutgoingFieldId)}
          label="NAT Outgoing"
          info="Packets destined outside the POD network will be SNAT'd using the node's IP."
        />
      ) : (
        <CheckboxField
          id={natOutgoingFieldId}
          value={isCapiCluster ? values?.calico?.[natOutgoingFieldId] : values[natOutgoingFieldId]}
          onChange={handleChange(natOutgoingFieldId)}
          label="NAT Outgoing"
          info="Packets destined outside the POD network will be SNAT'd using the node's IP."
        />
      )}
      <Row minItemWidth={minItemWidth} gap={24}>
        <TextField
          id={blockSizeFieldId}
          value={isCapiCluster ? values?.calico?.[blockSizeFieldId] : values[blockSizeFieldId]}
          onChange={handleChange(blockSizeFieldId)}
          label="Block Size"
          info={calicoBlockSizeTooltip?.[values.networkStack]}
          required
          type="number"
          validations={[
            ipValidators?.[values.networkStack]?.blockSizeValidator,
            ipValidators?.[values.networkStack]?.calicoBlockSizeCIDRValidator,
          ]}
        />
        <TextField
          id={mtuSizeFieldId}
          value={isCapiCluster ? values?.calico?.[mtuSizeFieldId] : values[mtuSizeFieldId]}
          onChange={handleChange(mtuSizeFieldId)}
          label="MTU Size"
          type="number"
          info="Maximum Transmission Unit (MTU) for the interface (in bytes)"
          required
        />
      </Row>
    </>
  )
}

export default CalicoNetworkFields
