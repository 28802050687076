import React from 'react'
import TextField from 'core/components/validatedForm/TextField'
import { customValidator, requiredValidator } from 'core/utils/fieldValidators'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import PicklistField from 'core/components/validatedForm/DropdownField'
import IntervalBackupUnitPicklist from '../IntervalBackupUnitPicklist'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import Row from 'core/containers/Row'
import ToggleSwitchField from 'core/components/validatedForm/ToggleSwitchField'

const useStyles = makeStyles((theme: Theme) => ({
  paramFields: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
}))

export const timestampValidations = [
  requiredValidator,
  customValidator((timestamp) => {
    if (!timestamp || !timestamp.includes(':')) {
      return false
    }
    const [hours, minutes] = timestamp.split(':')
    return hours && minutes && hours >= '00' && hours <= '23' && minutes >= '00' && minutes <= '59'
  }, 'Timestamp must be between 00:00 and 23:59'),
]

export const backupCountValidation = customValidator(
  (count) => count > 0,
  'Backup count must be at least 1',
)

export const intervalHoursValidation = customValidator(
  (hours) => hours > 0 && hours < 24,
  'Hours must be between 1 and 23',
)

export const intervalMinutesValidation = customValidator(
  (minutes) => minutes > 9 && minutes < 60,
  'Minutes must be between 10 and 59',
)

export default function EtcdBackupParamFields({ values, setFieldValue, useToggleSwitch = false }) {
  const classes = useStyles()
  return (
    <div className={classes.paramFields}>
      <TextField
        id="etcdStoragePath"
        label="Storage Path"
        info="This is the disk path where the etcd backup data will be stored on each master node of this cluster"
        onChange={setFieldValue('etcdStoragePath')}
        required
      />

      {useToggleSwitch ? (
        <ToggleSwitchField
          id="useTimestampBackup"
          label="Use Timestamp Backups"
          info="Enable automated etcd backups on this cluster daily at a specified time"
        />
      ) : (
        <CheckboxField
          id="useTimestampBackup"
          label="Use Timestamp Backups"
          info="Enable automated etcd backups on this cluster daily at a specified time"
        />
      )}

      {values.useTimestampBackup && (
        <Row>
          <TextField
            id="etcdBackupTimestamp"
            label="Daily Backup Time (00:00-23:59)"
            maxLength="5"
            placeholder="22:00"
            validations={timestampValidations}
            info="The time that a backup will be saved each day"
            required
          />
          <TextField
            id="maxTimestampBackups"
            label="Max Timestamp Backup Count"
            type="number"
            step="1"
            min="1"
            info="Maximum number of timestamp-based backups that will be saved"
            validations={[backupCountValidation]}
            required
          />
        </Row>
      )}

      {useToggleSwitch ? (
        <ToggleSwitchField
          id="useIntervalBackup"
          label="Use Interval Backups"
          info="Enable automated etcd backups on this cluster based on time intervals"
        />
      ) : (
        <CheckboxField
          id="useIntervalBackup"
          label="Use Interval Backups"
          info="Enable automated etcd backups on this cluster based on time intervals"
        />
      )}

      {/* https://stackoverflow.com/questions/47798104/set-min-max-on-textfield-type-number */}
      {values.useIntervalBackup && (
        <>
          <PicklistField
            DropdownComponent={IntervalBackupUnitPicklist}
            id="intervalBackupUnit"
            label="Interval Backup Unit"
            required
          />
          <Row>
            {values.intervalBackupUnit === 'minutes' && (
              <TextField
                id="etcdBackupIntervalMinutes"
                label="Backup Interval in Minutes (10-59)"
                type="number"
                step="1"
                min="1"
                max="59"
                info="A backup will be taken every specified number of minutes."
                validations={[intervalMinutesValidation]}
                required
              />
            )}
            {values.intervalBackupUnit === 'hours' && (
              <TextField
                id="etcdBackupIntervalHours"
                label="Backup Interval in Hours (1-23)"
                type="number"
                step="1"
                min="1"
                max="23"
                info="A backup will be taken every specified number of hours."
                validations={[intervalHoursValidation]}
                required
              />
            )}
            <TextField
              id="maxIntervalBackups"
              label="Max Interval Backup Count"
              type="number"
              step="1"
              min="1"
              info="Maximum number of interval-based backups that will be saved"
              validations={[backupCountValidation]}
              required
            />
          </Row>
        </>
      )}
    </div>
  )
}
