import TooltipListBody from 'core/elements/tooltip/TooltipListBody'
import React from 'react'
import ResourceUsageTables from '../common/ResourceUsageTables'
import Text from 'core/elements/Text'
import Tooltip from 'core/elements/tooltip'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'

const DeployedCapacityCell = ({ item }) => {
  if (!item) {
    return null
  }
  const { deployedCapacity } = item
  return <ResourceUsageTables usage={deployedCapacity} />
}

const getCountCell = (iconLinkComponent) => ({ value: items }) => {
  if (!items || !items.length) {
    return <Text variant="body2">0</Text>
  }
  return (
    <Tooltip
      customBody={<TooltipListBody items={items} nameKey="name" renderIcon={iconLinkComponent} />}
    >
      <Text variant="body2">{items.length}</Text>
    </Tooltip>
  )
}

const renderIconLink = (routeToFn) => ({ uuid, className }) => {
  const { history } = useReactRouter()
  return (
    <FontAwesomeIcon className={className} onClick={() => history.push(routeToFn({ id: uuid }))}>
      link
    </FontAwesomeIcon>
  )
}

export const cloudProvidersListTableColumns = [
  { key: 'name', label: 'Name' },
  { key: 'descriptiveType', label: 'Type' },
  { key: 'deployedCapacity', label: 'Deployed Capacity', CellComponent: DeployedCapacityCell },
  {
    key: 'clusters',
    label: 'Clusters',
    CellComponent: getCountCell(renderIconLink(routes.cluster.legacy.detail.path)),
  },
  {
    key: 'nodes',
    label: 'Nodes',
    CellComponent: getCountCell(renderIconLink(routes.nodes.detail.path)),
  },
  {
    key: 'importedClusters',
    label: 'Imported Clusters',
    CellComponent: getCountCell(renderIconLink(routes.cluster.imported.details.path)),
  },
  { key: 'uuid', label: 'Unique ID' },
]

export const deleteCloudProviderTooltipText =
  'Please remove all imported clusters associated with this cloud provider before deleting'
