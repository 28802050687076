import { listTablePrefs, allKey, TablePrefsParams } from 'app/constants'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import EventsTypePicklist from 'app/plugins/infrastructure/components/clusters/EventsTypePicklist'
import React, { useMemo } from 'react'
import { calculateAge } from 'utils/misc'
import { isNilOrEmpty, switchCase } from 'utils/fp'
import ListContainer from 'core/containers/ListContainer'
import { pick } from 'ramda'
import DataKeys from 'k8s/DataKeys'
import useListAction from 'core/hooks/useListAction'
import { ArrayElement } from 'core/actions/Action'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { GridFilterSpec } from 'core/elements/grid/hooks/useGridFiltering'
import { createGridStatusCell } from 'core/elements/grid/cells/GridStatusCell'
import InferActionParams from 'core/actions/InferActionParams'
import { listCapiClusterEvents } from './events/actions'
import { capiClusterEventsSelector } from './events/selectors'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

type ModelDataKey = DataKeys.CapiEvents
type SelectorModel = ArrayElement<ReturnType<typeof capiClusterEventsSelector>>
type ActionParams = InferActionParams<typeof listCapiClusterEvents>

type Params = ActionParams & {
  type: string
}

const requiredParams: Array<keyof ActionParams> = ['clusterName']
const defaultParams: Params = {
  clusterName: null,
  namespace: null,
  type: allKey,
}

export const getEventTypeStatus = (status) => {
  const variant = switchCase(
    {
      Normal: 'success',
      Warning: 'danger',
    },
    'unknown',
  )(status)
  return { variant, label: status || 'Unknown' }
}

const renderAge = (value, entity = {}) => {
  const { deprecatedLastTimestamp } = entity as any
  return calculateAge(value || deprecatedLastTimestamp)
}
const searchTargets = ['type', 'regarding.uid']
const columns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'metadata.creationTimestamp',
    label: 'Age',
    formatFn: (value, entity) => renderAge(value, entity),
  },
  {
    key: 'type',
    label: 'Type',
    CellComponent: createGridStatusCell({
      dataFn: getEventTypeStatus,
    }),
  },
  { key: 'reason', label: 'Reason' },
  { key: 'message', label: 'Message' },
  { key: 'involvedObject', label: 'From', formatFn: ({ name, kind }) => `${name} (${kind})` },
]

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>('Events', listTablePrefs)

export default function CapiClusterOverviewEvents({ clusterName, namespace }) {
  const { params, getParamsUpdater } = usePrefParams(defaultParams)
  const { message, loading: loadingEvents, reload } = useListAction(listCapiClusterEvents, {
    params: { clusterName, namespace },
    requiredParams,
  })
  const events = useSelector(capiClusterEventsSelector)

  const filters = useMemo(
    () => [
      {
        columnKey: 'type',
        FilterComponent: EventsTypePicklist,
        onChange: getParamsUpdater('type'),
      } as GridFilterSpec<SelectorModel, Params, 'type'>,
    ],
    [],
  )
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <ListContainer<ModelDataKey, SelectorModel>
        showBreadcrumbs={false}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loadingEvents}
        loadingMessage={message}
        onRefresh={reload}
        data={events}
        label="Events"
        columns={columns}
        getParamsUpdater={getParamsUpdater}
        filters={filters}
        {...pick(listTablePrefs, params)}
      />
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    gridRowStart: 3,
    gridColumnStart: 1,
    gridColumnEnd: 3,
    margin: theme.spacing(1, 0, 0, 0),
    maxHeight: 700,
    overflow: 'auto',
  },
}))
