import ApiClient from 'api-client/ApiClient'
import { trackEvent } from 'utils/tracking'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import { INodesSelector } from './model'
import { Node } from 'api-client/qbert.model'
import { isUnauthorizedHost } from './helpers'
import Bugsnag from 'utils/bugsnag'
import { someAsync } from 'utils/async'
import { flatten, pluck } from 'ramda'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import CustomAction from 'core/actions/CustomAction'
import { clustersSelector } from 'app/plugins/infrastructure/components/clusters/selectors'
import store from 'app/store'
import { CombinedClusterSelector } from 'app/plugins/infrastructure/components/combinedClusters/model'

const { qbert, resMgr } = ApiClient.getInstance()

export const k8sNodeActions = ActionsSet.make<DataKeys.K8sNodes>({
  uniqueIdentifier: 'metadata.uid',
  entityName: entityNamesByKey.K8sNodes,
  cacheKey: DataKeys.K8sNodes,
})

export const listK8sNodes = k8sNodeActions.add(
  new ListAction<DataKeys.K8sNodes>(async () => {
    const state = store.getState()
    const clusters = clustersSelector(state)
    return someAsync(
      pluck<'uuid', CombinedClusterSelector>('uuid', clusters).map(qbert.getK8sNodes),
    ).then(flatten)
  }),
)

export const nodeActions = ActionsSet.make<DataKeys.Nodes>({
  uniqueIdentifier: 'uuid',
  entityName: entityNamesByKey.Nodes,
  cacheKey: DataKeys.Nodes,
})

export const listNodes = nodeActions.add(
  new ListAction<DataKeys.Nodes>(async () => {
    const [rawNodes, hosts] = await Promise.all([qbert.getNodes(), resMgr.getHosts()])

    // Find the unauthorized nodes from Resmgr
    // TODO: maybe instead we should always define this model in resmgr
    const unauthorizedNodes: Node[] = hosts.filter(isUnauthorizedHost).map((host) => {
      return {
        name: host.info.hostname,
        uuid: host.id,
        isAuthorized: false,
      }
    })
    const authorizedNodes: Node[] = rawNodes.map((node) => {
      return {
        ...node,
        isAuthorized: true, // all nodes that are obtained from Qbert are authorized
      }
    })

    return [...authorizedNodes, ...unauthorizedNodes]
  }).addDependency(DataKeys.K8sNodes), // add loadServiceCatalog dependency as well
)

export const authNode = nodeActions.add(
  new CustomAction<DataKeys.Nodes, { node: INodesSelector }>('authNode', async ({ node }) => {
    Bugsnag.leaveBreadcrumb('Attempting to authorize node', { node })
    await resMgr.addRole(node.uuid, 'pf9-kube', {})
    trackEvent('Authorize Node', {
      node_name: node.name,
    })
    // Todo: Once integrated, check if below is necessary or if the table already does it
    listNodes.call({})
  }),
)

export const deauthNode = nodeActions.add(
  new CustomAction<DataKeys.Nodes, { node: INodesSelector }>('deAuthNode', async ({ node }) => {
    Bugsnag.leaveBreadcrumb('Attempting to unauthorize node', { node })
    await resMgr.unauthorizeHost(node.uuid)
    trackEvent('Deauthorize Node', {
      node_name: node.name,
    })
    // Todo: Once integrated, check if below is necessary or if the table already does it
    listNodes.call({})
  }),
)
