import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { clientActions } from 'core/client/clientReducers'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import useReactRouter from 'use-react-router'
import DocumentMeta from 'core/components/DocumentMeta'
import { routes } from 'core/utils/routes'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import { listCapiClusters } from '../actions'
import { capiClustersSelector } from '../selectors'
import { CapiDetailsPageTabs, ICapiClusterSelector } from '../model'
import CapiDetailsPageHeader from './CapiDetailsPageHeader'
import CapiClusterOverview from './overview/CapiClusterOverview'
import CapiInfrastructurePage from './infrastructure/CapiInfrastructurePage'
import Button from 'core/elements/button'
import makeStyles from '@material-ui/styles/makeStyles'
import Theme from 'core/themes/model'
import {
  downloadAllYamlsZipFile,
  getInfrastructureResourceKinds,
  convertResourcesToYamls,
} from './helpers'
import CapiControlPlanePage from './control-plane/CapiControlPlanePage'
import CapiClustersNodeGroupsOverview from './node-groups/CapiClustersNodeGroupsOverview'
import { isNilOrEmpty } from 'utils/fp'
import { partition } from 'ramda'
import CapiClusterAddonsPage from './addons/CapiClusterAddonsPage'
import CapiKubeconfigDownloadModal from './overview/CapiKubeconfigDownloadModal'
import ExternalLink from 'core/components/ExternalLink'
import { listClusterAddons } from 'app/plugins/infrastructure/components/clusters/cluster-addons/new-actions'
import { clusterAddonsSelector } from 'app/plugins/infrastructure/components/clusters/cluster-addons/selectors'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import useParams from 'core/hooks/useParams'
import PollingData from 'core/components/PollingData'
import CapiClusterDeleteDialog from '../CapiClusterDeleteDialog'

const getNodeGroupYamls = (allNodeGroups) => {
  const yamls = {}
  allNodeGroups.forEach(
    (nodeGroup) => (yamls[nodeGroup.name] = convertResourcesToYamls(nodeGroup.resources)),
  )
  return yamls
}

const defaultCluster = {
  resources: [],
} as ICapiClusterSelector

const oneSecond = 1000

export default function CapiClusterDetailsPage() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { params, setParams } = useParams({})
  const { match } = useReactRouter()
  const { id, tab } = match.params
  const { loading: loadingCluster, reload: reloadCluster } = useListAction(listCapiClusters)
  const { history } = useReactRouter()

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const clusters = useSelector(capiClustersSelector)
  const cluster = useMemo(() => clusters.find((cluster) => cluster.uuid === id) || defaultCluster, [
    id,
    clusters,
  ])

  // Load addons to get dashboard & grafana links
  const { loading: loadingClusterAddons } = useListAction(listClusterAddons, {
    params: {
      clusterId: cluster?.uuid,
      clusterName: cluster?.name,
      clusterNamespace: cluster?.namespace,
    },
  })
  const addons = useSelectorWithParams(clusterAddonsSelector, { clusterId: cluster?.uuid })

  const [infraResources, controlPlaneResources] = useMemo(() => {
    if (!cluster?.infrastructureType || isNilOrEmpty(cluster?.resources)) return []
    const isInfraResource = (r) =>
      getInfrastructureResourceKinds(cluster.infrastructureType).has(r.kind)
    return partition(isInfraResource, cluster.resources)
  }, [cluster?.infrastructureType, cluster?.resources])

  const [showDownloadKubeconfig, setShowDownloadKubeconfig] = useState(false)

  useEffect(() => {
    if (!cluster?.name) return
    setParams({ clusterName: cluster?.name })
  }, [cluster?.name])

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        id: cluster?.name || id,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [cluster?.name, id])

  const clusterYamls = useMemo(() => convertResourcesToYamls(cluster?.resources), [
    cluster?.resources,
  ])

  const nodeGroupYamls = useMemo(() => getNodeGroupYamls(cluster?.allNodeGroups || []), [
    cluster?.allNodeGroups,
  ])

  const downloadAllYamls = useCallback(() => {
    downloadAllYamlsZipFile(clusterYamls, nodeGroupYamls, cluster?.name)
  }, [clusterYamls, nodeGroupYamls, cluster?.name])

  const refresh = useCallback(async () => {
    reloadCluster(true)
  }, [reloadCluster])

  const onClose = () => setShowDeleteDialog(false)
  const props = { rows: [cluster], onClose }

  return (
    <>
      <DocumentMeta title="CAPI Overview" breadcrumbs />
      <div className={classes.actionsBar}>
        <div className={classes.actions}>
          {/* Todo: Add in functionality for these buttons */}
          {cluster?.links?.dashboard && (
            <ExternalLink url={cluster?.links?.dashboard}>
              <Button icon="external-link" variant="secondary">
                Kubernetes Dashboard
              </Button>
            </ExternalLink>
          )}
          {cluster?.usage?.grafanaLink && (
            <ExternalLink url={cluster?.usage?.grafanaLink}>
              <Button icon="external-link" variant="secondary">
                Grafana
              </Button>
            </ExternalLink>
          )}
          <Button
            icon="arrow-circle-down"
            variant="secondary"
            onClick={() => setShowDownloadKubeconfig(true)}
          >
            Download Kubeconfig
          </Button>
        </div>
        <CapiKubeconfigDownloadModal
          open={showDownloadKubeconfig}
          cluster={cluster}
          onClose={() => setShowDownloadKubeconfig(false)}
        />
        <div className={classes.actions}>
          {tab !== 'addOns' && (
            <PollingData
              loading={loadingCluster}
              onReload={refresh}
              refreshDuration={oneSecond * 30}
            />
          )}
          <Button
            icon="download"
            variant="secondary"
            onClick={downloadAllYamls}
            disabled={loadingCluster}
          >
            Download Cluster YAMLS
          </Button>
          <Button icon="trash" variant="secondary" onClick={() => setShowDeleteDialog(true)}>
            Delete Cluster
          </Button>
        </div>
      </div>
      <CapiDetailsPageHeader
        cluster={cluster}
        nodeGroups={cluster?.allNodeGroups}
        addons={addons}
      />
      <Tabs route={routes.cluster.capi.details}>
        <Tab label="Overview" value={CapiDetailsPageTabs.Overview}>
          <CapiClusterOverview cluster={cluster} loading={loadingCluster} />
        </Tab>
        <Tab label="Infrastructure" value={CapiDetailsPageTabs.Infrastructure}>
          <CapiInfrastructurePage
            cluster={cluster}
            loading={loadingCluster}
            resources={infraResources}
          />
        </Tab>
        <Tab label="Control Plane" value={CapiDetailsPageTabs.ControlPlane}>
          <CapiControlPlanePage
            cluster={cluster}
            resources={controlPlaneResources}
            loading={loadingCluster}
          />
        </Tab>
        <Tab label="Node Groups" value={CapiDetailsPageTabs.NodeGroups}>
          <CapiClustersNodeGroupsOverview
            cluster={cluster}
            loading={loadingCluster}
            reloadNodeGroups={refresh}
          />
        </Tab>
        <Tab label="Add-ons" value={CapiDetailsPageTabs.Addons}>
          <CapiClusterAddonsPage cluster={cluster} addons={addons} />
        </Tab>
      </Tabs>
      {showDeleteDialog && <CapiClusterDeleteDialog {...props} />}
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  actionsBar: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  actions: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, max-content)',
    gridGap: theme.spacing(1),
  },
}))
